import React from 'react';

const refs = {};

class FormSelect extends React.Component {
  render() {
    const { className, defaultValue, header, onChange, options, placeholder, disableForm } =
      this.props;

    const hint = (
      <option key={-1} value={-1} disabled>
        {placeholder || 'Select Option'}
      </option>
    );

    return (
      <div
        className={
          className ? `form-input-frame ${className}` : 'form-input-frame'
        }
      >
        <div className="form-input-header">{header}</div>

        <select
          className={`form-menu select ${defaultValue ? 'black' : 'hint'}`}
          defaultValue={defaultValue || -1}
          disabled={disableForm || false}
          onChange={(e) => {
            refs.select.classList.remove('hint');
            refs.select.classList.add('hint');

            onChange(e);
          }}
          ref={(ref) => (refs.select = ref)}
        >
          {[
            hint,
            ...options.map((o, i) => (
              <option key={i} value={o.value}>
                {o.name}
              </option>
            )),
          ]}
        </select>

        <div className="down-arrow-icon">
          <img
            src={require('../../../theme/images/down-arrow-2x-copy.png')}
            alt="down-arrow-icon"
          />
        </div>
      </div>
    );
  }
}

export default FormSelect;
