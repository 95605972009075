import React from 'react';

import moment from 'moment';
import logo from '../../theme/images/hire-logo-2x.png'

const statuses = {
  finished: 'Completed',
};

const RosterCourse = (props) => {
  const { back, state, user, orgId } = props;
  const { attachment, firstName, lastName, specialties, courseTaken } = state;
  const { answers, course, endedAt, startedAt, timeSpentSecs, passed, score } =
    courseTaken;

  const org = orgId;
  const role = user.roles.find((r) => r.org.id === org);
  const isCorporate = role ? role.name === 'corporate' : false;

  const specialty = specialties.find((s) => s.isPrimary);
  const arr = answers
    .map((a, i) => ({ ...a, rank: i }))
    .filter((a) => !a.isCorrect);

  return (
    <div className="roster-test-view full-screen-view">
      <div className="toolbar">
        <button className={user ? 'cancel-button' : 'hide'} onClick={back}>
          Back
        </button>

        <div className="print-button" onClick={() => window.print()}>
          <img
            src={require('../../theme/images/print-icon-2x.png')}
            alt="print-icon"
          />
        </div>
      </div>

      <div className="content-frame scroll">
        <div className="centered-frame">
          <div className="logo-frame">
            <div className="company-logo">
              {attachment ? (
                <img src={attachment.url} alt="company-logo" />
              ) : null}
            </div>

            <div className="una-logo">
              <img
                src={logo}
                alt="una-logo"
              />
            </div>
          </div>

          <div className="profile-frame">
            <div className="section-frame">
              <div className="section-header">PROFESSIONAL</div>

              <div className="section-value">{`${firstName} ${lastName}`}</div>

              <div className={specialty ? 'section-subvalue' : 'hide'}>
                {specialty ? specialty.name : ''}
              </div>
            </div>

            <div className="section-frame">
              <div className="section-header">MANDATORY</div>

              <div className="section-value">{course.title}</div>
              {!isCorporate && (
                <div className={endedAt ? 'section-subvalue' : 'hide'}>{`
                  Completed
                - ${moment(endedAt).format('MMM DD, YYYY')}`}</div>
              )}
              {isCorporate && (
                <>
                  <div className={startedAt ? 'section-subvalue' : 'hide'}>{`
                  Started
                 - ${moment
                      .utc(startedAt)
                      .local()
                      .format('MMM DD, YYYY HH:mm:ss')}`}</div>
                  <div className={endedAt ? 'section-subvalue' : 'hide'}>{`
                  Completed
                 - ${moment
                      .utc(endedAt)
                      .local()
                      .format('MMM DD, YYYY HH:mm:ss')}`}</div>
                  <div
                    className={timeSpentSecs ? 'section-subvalue' : 'hide'}
                  >{`
                  Duration
                 - ${moment
                      .utc(Math.round(timeSpentSecs) * 1000)
                      .format('H:mm:ss')}`}</div>
                </>
              )}
            </div>

            <div className="section-frame">
              <div className="section-header">OVERALL SCORE</div>

              <div className="section-value">{`${score}%`}</div>

              <div
                className={`section-subvalue ${passed ? 'green' : 'orange'}`}
              >
                {passed ? 'Pass' : 'Did Not Pass'}
              </div>
            </div>
          </div>

          {score < 60 && !isCorporate ? (
            <div className="empty-frame">
              <div className="layout-center">
                <div className="empty-label hint">
                  {
                    'We are unable to share the incorrectly answered questions for any mandatory results under 60% in an effort to preserve the security and integrity of our mandatories.'
                  }
                </div>
              </div>
            </div>
          ) : arr.length > 0 ? (
            <div className="questions-frame">
              <div className="header">{`incorrectly answered questions (${arr.length})`}</div>

              <ul className="questions-list-view">
                {arr.map((answer) => {
                  const { id, question, rank, value } = answer;

                  return (
                    <li className="question-cell" key={id}>
                      <div className="question-label bold">{`Q${rank + 1
                        }.`}</div>

                      <div className="flex-frame horizontal">
                        <div className="question-label title">
                          {question.title}
                        </div>
                        <div className="question-label answer red">{value}</div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default RosterCourse;
