import { gql } from 'apollo-boost';

const FeedbackRequestCreateMutation = gql`
mutation FeedbackRequestMutation($input: FeedbackRequestCreateCreateInput) {
  feedbackRequestCreate(input: $input) {
    success
    result {
      ulid
      email
    }
    errors {
        message
    }
  }
}`;


export default FeedbackRequestCreateMutation;
