import React from 'react';

import ModalPane from '../../Shared/ModalPane';

const DashboardDeletePane = (props) => {
  const { onHide, message, save, show } = props;

  return (
    <ModalPane
      className="dashboard-delete-pane"
      content={(hide) => (
        <div className="model-content-frame">
          <div className="modal-message">{message}</div>

          <div className="button-frame">
            <button
              className="modal-button teal"
              onClick={() => {
                hide();

                setTimeout(() => onHide(), 300);
              }}
            >
              Cancel
            </button>
            <button className="modal-button red" onClick={() => save(hide)}>
              Submit
            </button>
          </div>
        </div>
      )}
      hide={onHide}
      show={show}
    />
  );
};

export default DashboardDeletePane;
