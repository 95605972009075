import gql from 'graphql-tag';

const TestSummaryQuery = gql`
  query TestSummaryQuer($status: [String!]) {
    tests(order: "title", status: $status) {
      nodes {
        id
        kind
        org {
          id
          title
        }
        title
      }
    }
  }
`;

export default TestSummaryQuery;
