import { gql } from 'apollo-boost';

const FeedbackRequestQuery = gql`
query FeedbackRequestQuery($id: ID) {
  feedbackRequest(id: $id) {
    id
    ulid
    fname
    lname
    email
    company
    ofactory
    ftype
    parentid
    childlist
    datetime
    requestingUser{
      id
      email
      firstName
      lastName
      feedbackSeekerDetails
    }
  }
}
`;

export default FeedbackRequestQuery;
