import { gql } from 'apollo-boost';

const ChecklistQuestionRemoveMutation = gql`
  mutation ChecklistQuestionRemoveMutation($input: IDInput) {
    checklistQuestionRemove(input: $input) {
      errors {
        message
      }
      result {
        id
        rank
        title
        updatedAt
      }
    }
  }
`;

export default ChecklistQuestionRemoveMutation;
