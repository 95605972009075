import React from 'react';

import _ from 'lodash';

import ColorPicker from '../../Shared/ColorPicker';
import Checkbox from '../../Shared/Checkbox';
import FormInput from '../../Shared/Form/Input';
import FormSelect from '../../Shared/Form/Select';

import { states } from '../../../utils/Constants';

const kinds = ['nurse_staffing', 'allied_health', 'non_clinical', 'hospital'];
const modules = ['tests', 'checklists', 'mandatories', 'credentials'];
const annualReminders = ['tests', 'checklists', 'mandatories', 'documents'];
const expirationNotificationDays = ['30', '15', '7', '1'];

const OrgInfo = (props) => {
  const { handleChange, handleCheckboxChange, state } = props;

  return (
    <div className="centered-frame">
      <div className="section-header">Client Details</div>

      <div className="form-frame">
        <div className="kind-header">Type of Entity</div>

        <ul className="kinds-list-view">
          {kinds.map((kind, i) => {
            return (
              <li
                className="kind-cell"
                key={i}
                onClick={() => handleChange('kind', kind)}
              >
                <Checkbox checked={state.kind === kind} readOnly={true} />

                <div className="kind-label">{_.startCase(kind)}</div>
              </li>
            );
          })}
        </ul>

        <div className="kind-header">Module Access</div>

        <ul className="kinds-list-view">
          {modules.map((module, i) => {
            const checked = state.modules.includes(module);

            return (
              <li
                className="kind-cell"
                key={i}
                onClick={() => handleCheckboxChange(module, !checked, 'modules')}
              >
                <Checkbox checked={checked} readOnly={true} />

                <div className="kind-label">{_.startCase(module)}</div>
              </li>
            );
          })}
        </ul>

        <div className="kind-header">
          Annual Reminders
        </div>
        <ul className="kinds-list-view">
          {annualReminders.map((expiration, i) => {
            const checked = state.annualReminders.includes(expiration);

            return (
              <li
                className="kind-cell"
                key={i}
                onClick={() => handleCheckboxChange(expiration, !checked, 'annualReminders')}
              >
                <Checkbox checked={checked} readOnly={true} />
                <div className="kind-label">{_.startCase(expiration)}</div>
              </li>
            );
          })}
        </ul>

        <div className="kind-header">
          Document Expiration Notification (# of Days)
        </div>
        <ul className="kinds-list-view">
          {expirationNotificationDays.map((expiration, i) => {
            const checked = state.expirationNotificationDays.includes(expiration);

            return (
              <li
                className="kind-cell"
                key={i}
                onClick={() => handleCheckboxChange(expiration, !checked, 'expirationNotificationDays')}
              >
                <Checkbox checked={checked} readOnly={true} />
                <div className="kind-label">{expiration}</div>
              </li>
            );
          })}
        </ul>

        <FormInput
          header="Company Name"
          defaultValue={state.title}
          onChange={(e) => handleChange('title', e.target.value)}
          placeholder="Enter company name"
        />

        <div className="form-input-frame middle">
          <div className="flex-frame">
            <div className="form-input-header">
              Company Logo (296px by 72px)
            </div>

            <a
              className={
                state.attachment && !state.file ? 'preview-button blue' : 'hide'
              }
              href={state.attachment ? state.attachment.url : ''}
              rel="noopener noreferrer"
              target="_blank"
            >
              Preview
            </a>
          </div>

          <div className="form-input file">
            <input
              className="file-input"
              onChange={(e) => {
                const file = e.target.files[0];
                const allowed = ['image/png', 'image/svg+xml', 'image/jpeg'];

                if (allowed.includes(file.type)) {
                  handleChange('file', file);
                } else {
                  window.alert(`${file.name} is not a supported file type.`);
                }
              }}
              type="file"
            />

            {state.file || state.attachment ? (
              <div className="file-label">
                {state.file ? state.file.name : state.attachment.url}
              </div>
            ) : (
              <div className="file-label hint">No file selected</div>
            )}

            <button className="choose-file-button">Choose File</button>
          </div>
        </div>

        <div className="form-input-frame middle">
          <div className="form-input-header">Brand Color</div>

          <ColorPicker
            displayHex={true}
            onChange={(color) => handleChange('brandColor', color)}
            value={state.brandColor}
          />
        </div>

        <div className="form-input-frame">
          <div className="form-input-header">Secondary Brand Color</div>

          <ColorPicker
            displayHex={true}
            onChange={(color) => handleChange('secondaryColor', color)}
            value={state.secondaryColor}
          />
        </div>
      </div>

      <div className="form-frame">
        <FormInput
          header="Street"
          defaultValue={state.streetAddress}
          onChange={(e) => handleChange('streetAddress', e.target.value)}
          placeholder="Enter street address"
        />

        <FormInput
          header="City"
          defaultValue={state.city}
          onChange={(e) => handleChange('city', e.target.value)}
          placeholder="Enter city"
        />

        <FormSelect
          header="State"
          defaultValue={state.state}
          options={states.map((s) => ({ value: s.abbreviation, name: s.name }))}
          onChange={(e) => handleChange('state', e.target.value)}
          placeholder="Enter state"
        />

        <FormInput
          header="Zip Code"
          defaultValue={state.zip}
          onChange={(e) => handleChange('zip', e.target.value)}
          placeholder="Enter zip code"
        />

        <div className="form-input-frame desc">
          <div className="form-input-header">Company Description</div>

          <textarea
            className="form-input"
            defaultValue={state.desc}
            onChange={(e) => handleChange('desc', e.target.value)}
            placeholder={'Enter company description'}
          />
        </div>
      </div>
    </div>
  );
};

export default OrgInfo;
