import React from 'react';

const FormInput = (props) => {
  const { className, defaultValue, header, onChange, placeholder, disableForm } = props;

  return (
    <div
      className={
        className ? `form-input-frame ${className}` : 'form-input-frame'
      }
    >
      <div className="form-input-header">{header}</div>

      <input
        className="form-input"
        defaultValue={defaultValue}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disableForm || false}
      />
    </div>
  );
};

export default FormInput;
