import { gql } from 'apollo-boost';

const UserQuery = gql`
  query UserQuery($id: ID) {
    user(id: $id) {
      id
      attachment(label: "avatar") {
        id
        label
        url
      }
      bio
      email
      firstName
      fullName
      lastName
      homeStreet1
      homeStreet2
      homeCity
      homeState
      homeZip
      phoneNumber
      roles {
        id
        name
        org {
          id
          attachments {
            id
          }
          attachment(label: "pdf_logo") {
            id
            url
          }
          brandColor
          title
        }
      }
    }
  }
`;

export default UserQuery;
