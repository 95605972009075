import { gql } from 'apollo-boost';

const OrgRemoveMutation = gql`
  mutation OrgRemoveMutation($input: IDInput) {
    orgRemove(input: $input) {
      errors {
        code
        message
      }
      success
    }
  }
`;

export default OrgRemoveMutation;
