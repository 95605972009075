import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import LoadingPane from '../Shared/LoadingPane';
import SelectMenu from '../Shared/SelectMenu';
import Toolbar from '../Shared/Toolbar';

import DragList from '../../containers/Shared/DragList';

const Course = (props) => {
  const {
    addChapter,
    back,
    cancelChapter,
    deleteChapter,
    goToChapterRoute,
    handleChange,
    save,
    saveChapter,
    state,
  } = props;
  const {
    active,
    admin,
    artwork,
    artworkUrl,
    artworkThumbnailUrl,
    chapters,
    chapter,
    desc,
    loading,
    id,
    kind,
    passingScore,
    preview,
    scores,
    title,
    mappingOptions,
    courseType
  } = state;

  return (
    <div className="course-view full-screen-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      <Toolbar
        active={active}
        cancel={back}
        handleChange={handleChange}
        isToggle={true}
        save={save}
        title={id ? 'Edit Course' : 'Add New Course'}
      />

      <div className="content-frame">
        <div className="centered-frame">
          <div className="section-frame">
            <div className="section-header-frame">
              <div className="section-header">Course Details</div>
            </div>

            <div className="form-frame">
              <div className="form-input-frame">
                <div className="input-title">Title</div>

                <input
                  className="form-input"
                  defaultValue={title}
                  onChange={(e) => handleChange('title', e.target.value)}
                  placeholder="Enter Title of Test"
                />
              </div>

              {/* <div className='form-input-frame middle'>
                                <div className='input-title'>Duration</div>

                                <SelectMenu
                                    className='form-menu'
                                    defaultValue={durationMins}
                                    isClearable={true}
                                    onChange={e => handleChange("durationMins", e.value)}
                                    options={days.map(d => ({ value: d, label: `${d} ${d > 1 ? 'minutes' : 'minute'}` }))}
                                    placeholder='Select Duration'
                                />
                            </div> */}

              <div className="form-input-frame middle">
                <div className="input-title">Passing Score</div>

                <SelectMenu
                  className="form-menu"
                  isClearable={true}
                  onChange={(e) => {
                    if (e) handleChange('passingScore', e.value);
                  }}
                  options={scores.map((s) => ({ value: s, label: `${s}%` }))}
                  placeholder="Select Passing Score"
                  value={passingScore}
                />
              </div>

              <div className="form-input-frame">
                <div className="input-title">Course Artwork</div>

                <div className="form-file">
                  <div className="file-frame">
                    <div
                      className={`file-name ${artworkUrl || artwork ? 'black' : 'hint'
                        }`}
                    >
                      {artwork
                        ? artwork.name
                        : artworkUrl
                          ? artworkUrl
                          : 'ex: thumbnail-img.png'}
                    </div>

                    <button className="choose-file-button">Choose File</button>
                  </div>

                  <button
                    className={artworkUrl ? 'view-preview-button' : 'hide'}
                    onClick={() => handleChange('preview', !preview)}
                  >
                    View Preview
                  </button>

                  <input
                    className="file-input"
                    onChange={(e) => {
                      handleChange('artworkUrl', null);
                      handleChange('artwork', e.target.files[0]);
                    }}
                    type="file"
                  />
                </div>
              </div>


              <div className="form-input-frame">
                <div className="input-title">Course Type</div>

                <SelectMenu
                  className="form-menu"
                  isClearable={true}
                  onChange={(e) => {
                    console.log("Selected Option:", e);
                    if (e) handleChange('courseType', e.value);
                  }}
                  options={mappingOptions}
                  placeholder="Select Test Type"
                  value={courseType}
                />

              </div>


              {admin ? (
                <div className="form-input-frame">
                  <div className="input-title">Kind</div>

                  <input
                    className="form-input"
                    defaultValue={kind}
                    onChange={(e) => handleChange('kind', e.target.value)}
                    placeholder="Enter kind"
                  />
                </div>
              ) : null}

              <div
                className={
                  preview ? 'artwork-preview-frame' : 'artwork-preview-frame sm'
                }
              >
                <div className="list-artwork">
                  <div className="course-toolbar" />

                  <div className="course-content-frame">
                    <div className="course-label">Course Library</div>

                    <div className="course-cell">
                      <div className="artwork">
                        <img src={artworkThumbnailUrl} alt="course-thumbnail" />
                      </div>

                      <div className="course-details">
                        <div className="course-title">Course Title</div>
                        <div className="course-desc">
                          Course description discussing aspects...
                        </div>
                      </div>
                    </div>

                    <div className="course-cell">
                      <div className="una-logo">UNA</div>

                      <div className="course-details">
                        <div className="course-title">Course Title</div>
                        <div className="course-desc">
                          Course description discussing aspects...
                        </div>
                      </div>
                    </div>

                    <div className="course-cell">
                      <div className="una-logo">UNA</div>

                      <div className="course-details">
                        <div className="course-title">Course Title</div>
                        <div className="course-desc">
                          Course description discussing aspects...
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="course-artwork">
                  <img
                    className="artwork lg"
                    src={artworkUrl}
                    alt="course-artwork"
                  />

                  <div className="section-header">description</div>

                  <div className="section-label">
                    Course description discussing aspects of different entities.
                  </div>
                </div>
              </div>

              <div
                className="form-input-frame"
                style={{ width: '100%', margin: 0 }}
              >
                <div className="input-title">Description</div>

                <textarea
                  className="form-area"
                  defaultValue={desc}
                  placeholder="Enter description for test..."
                  onChange={(e) => handleChange('desc', e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className={id ? 'section-frame' : 'hide'}>
            <div className="section-header-frame">
              <div className="section-header">{`Chapters (${chapters.length})`}</div>

              <button className="section-button" onClick={addChapter}>
                + Add Chapter
              </button>
            </div>

            {chapters.length === 0 ? (
              <div className="empty-hint hint">
                No chapters have been added.
              </div>
            ) : (
              <div className="chapters-table-view">
                <div className="table-headers">
                  <div className="table-header title">Title</div>
                </div>

                <DragList
                  onChange={(arr) => handleChange('chapters', arr)}
                  data={chapters.map((obj, i) => {
                    const { id, title } = obj;
                    const last = i === chapters.length - 1;

                    return {
                      ...obj,
                      content:
                        chapter.id === id ? (
                          <div
                            className="chapter-cell edit"
                            key={i}
                            style={{ borderWidth: last ? 0 : 1 }}
                          >
                            <input
                              autoFocus
                              className="chapter-title"
                              defaultValue={title}
                              onChange={(e) =>
                                handleChange('chapter.title', e.target.value)
                              }
                              placeholder="Enter chapter title"
                            />

                            <button
                              className="chapter-button red"
                              onClick={cancelChapter}
                            >
                              Cancel
                            </button>
                            <button
                              className="chapter-button teal"
                              onClick={() => saveChapter(i)}
                            >
                              Save
                            </button>
                          </div>
                        ) : (
                          <div
                            className="chapter-cell"
                            style={{ borderWidth: last ? 0 : 1 }}
                          >
                            <div className="cell-content-frame">
                              <div className="drag-icon">
                                <img
                                  draggable={false}
                                  src={require('../../theme/images/hashes.png')}
                                  alt="hashes-icon"
                                />
                              </div>

                              <div className="cell-label title">{title}</div>
                            </div>

                            <OverlayTrigger
                              trigger="click"
                              placement="bottom"
                              rootClose
                              overlay={
                                <Popover id="menu-pane" className="menu-pane">
                                  <div
                                    className="menu-content-view"
                                    style={{ padding: '5px 0px' }}
                                  >
                                    <button
                                      className="menu-button teal"
                                      onClick={() => goToChapterRoute(id)}
                                    >
                                      View Chapter Details
                                    </button>
                                    <button
                                      className="menu-button teal"
                                      onClick={() =>
                                        handleChange('chapter', { ...obj })
                                      }
                                    >
                                      Edit Title
                                    </button>
                                    {/* <button className='menu-button teal' onClick={() => duplicateChapter(obj)}>Duplicate</button> */}
                                    <button
                                      className="menu-button red"
                                      onClick={() => deleteChapter(id, i)}
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </Popover>
                              }
                            >
                              <div className="expander-icon">
                                <img
                                  src={require('../../theme/images/expander-icon-grey-2x.png')}
                                  alt="expander-icon"
                                />
                              </div>
                            </OverlayTrigger>
                          </div>
                        ),
                    };
                  })}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Course;
