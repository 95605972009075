import { gql } from 'apollo-boost';

const ChecklistsQuery = gql`
  query ChecklistsQuery($first: Int, $after: String, $status: [String!]) {
    checklists(first: $first, after: $after, status: $status, order: "title") {
      nodes {
        id
        kind
        org {
          id
          title
        }
        status
        title
        checklistType
      }
    }
  }
`;

export default ChecklistsQuery;
