import { gql } from 'apollo-boost';

const CourseDeactivateMutation = gql`
  mutation CourseDeactivateMutation($input: IDInput) {
    courseDeactivate(input: $input) {
      errors {
        message
      }
      result {
        id
        
      }
    }
  }
`;

export default CourseDeactivateMutation;
