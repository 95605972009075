import React from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';

import _ from 'lodash';

import ChecklistQuery from '../../graphql/queries/Checklist';

import ChecklistCategoryCreateMutation from '../../graphql/mutations/Checklists/Category/Create';
import ChecklistCategoryRemoveMutation from '../../graphql/mutations/Checklists/Category/Remove';
import ChecklistCategoryUpdateMutation from '../../graphql/mutations/Checklists/Category/Update';
import ChecklistQuestionCreateMutation from '../../graphql/mutations/Checklists/Question/Create';
import ChecklistSectionCreateMutation from '../../graphql/mutations/Checklists/Sections/Create';
import ChecklistSummaryActivateMutation from '../../graphql/mutations/Checklists/Summary/Activate';
import ChecklistSummaryDeactivateMutation from '../../graphql/mutations/Checklists/Summary/Deactivate';
import ChecklistSummaryCreateMutation from '../../graphql/mutations/Checklists/Summary/Create';
import ChecklistSummaryUpdateMutation from '../../graphql/mutations/Checklists/Summary/Update';

import Checklist from '../../components/Checklist';

import LoadingPane from '../../components/Shared/LoadingPane';
import { withRouter } from '../withRouter';

class ChecklistContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      category: {},
      categories: [],
      loading: false,
      onload: true,
      mappingOptions: [
        { value: 'normal', label: 'Normal' },
        { value: 'ecp_job', label: 'ECP Jobs Assessment ' },

      ],
    };
  }

  componentDidMount() {
    const {
      checklistQuery: { loading, refetch },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      refetch().then(() => this.setup());
    } else {
      this.setup();
    }
  }

  componentDidUpdate() {
    this.setup();
  }

  setup = () => {
    const {
      checklistQuery: { loading, checklist },
      location,
      navigate,
      context,
    } = this.props;
    const { user } = context;
    const { onload } = this.state;

    const org = location.state ? location.state.org : null;
    const role = user.roles.find((r) => r.org.id === org);
    const admin = role ? role.name === 'corporate' : false;

    if (onload && !loading) {
      if (checklist && checklist.kind === 'global' && !admin) {
        navigate(`/error${org ? `?org=${org}` : ''}`);
      } else {
        this.setState({
          ...checklist,
          active: checklist ? checklist.status === 'active' : false,
          admin,
          categories: checklist ? _.sortBy(checklist.categories, ['rank']) : [],
          onload: false,
        });
      }
    }
  };

  addCategory = () => {
    const categories = this.state.categories;
    const index = categories.length + 1;
    const category = {
      id: `create-${index}`,
      questions: [],
    };

    categories.push(category);

    this.handleChange('category', category);
    this.handleChange('categories', categories);
  };

  cancelCategory = () => {
    const {
      categories,
      category: { id },
    } = this.state;

    const i = categories.findIndex((c) => c.id === id);
    this.setState({
      category: {},
      categories: [
        ...categories.slice(0, i),
        ...categories.slice(i + 1, categories.length),
      ],
    });
  };

  checkStatus = (checklist) => {
    const {
      checklistSummaryActivateMutation,
      checklistSummaryDeactivateMutation,
    } = this.props;
    const { active, categories } = this.state;

    let mutation;
    const status = checklist.status;

    if (active && status === 'inactive') {
      mutation = checklistSummaryActivateMutation;
    } else if (!active && status === 'active') {
      mutation = checklistSummaryDeactivateMutation;
    }

    if (mutation) {
      mutation({
        variables: {
          input: {
            id: checklist.id,
          },
        },
      }).then((response) => {
        const key = Object.keys(response.data);
        const errors = response.data[key].errors;

        if (errors) {
          this.handleChange('loading', false);

          window.alert(errors[0].message);
        } else {
          this.updateCategories(categories, 0);
        }
      });
    } else {
      this.updateCategories(categories, 0);
    }
  };

  deleteCategory = (id, i) => {
    const { checklistCategoryRemoveMutation } = this.props;

    this.setState(
      {
        loading: true,
      },
      () => {
        checklistCategoryRemoveMutation({
          variables: {
            input: {
              id,
            },
          },
        }).then(() => {
          const arr = this.state.categories;

          this.setState({
            loading: false,
            categories: [...arr.slice(0, i), ...arr.slice(i + 1, arr.length)],
          });
        });
      },
    );
  };

  duplicateCategory = (category) => {
    const { checklistCategoryCreateMutation } = this.props;
    const { sections, title } = category;

    document.body.click();

    this.setState(
      {
        loading: true,
      },
      () => {
        checklistCategoryCreateMutation({
          variables: {
            input: {
              checklistId: this.props.params.id,
              title: `${title} Copy`,
              rank: this.state.categories.length + 1,
            },
          },
        }).then((response) => {
          const {
            data: {
              checklistCategoryCreate: { errors, result },
            },
          } = response;

          if (errors) {
            this.handleChange('loading', false);

            window.alert(errors[0].message);
          } else if (sections.length > 0) {
            this.duplicateSections(sections, 0, result);
          } else {
            this.setState({
              categories: [...this.state.categories, result],
              loading: false,
            });
          }
        });
      },
    );
  };

  duplicateQuestions = (arr, i, section, sectionArr, sectionI, category) => {
    const { checklistQuestionCreateMutation } = this.props;

    if (i === arr.length) {
      this.duplicateSections(sectionArr, sectionI + 1, category);
    } else {
      checklistQuestionCreateMutation({
        variables: {
          input: {
            sectionId: section.id,
            rank: arr[i].rank,
            title: arr[i].title,
          },
        },
      }).then(() => {
        this.duplicateQuestions(
          arr,
          i + 1,
          section,
          sectionArr,
          sectionI,
          category,
        );
      });
    }
  };

  duplicateSections = (arr, i, category) => {
    const { checklistSectionCreateMutation, checklistQuery } = this.props;

    if (i === arr.length) {
      checklistQuery.refetch().then((response) => {
        const arr = response.data.checklist.categories;

        this.setState({
          loading: false,
          categories: _.sortBy(arr, ['rank']),
        });
      });
    } else {
      const section = arr[i];

      checklistSectionCreateMutation({
        variables: {
          input: {
            categoryId: category.id,
            rank: arr[i].rank,
            title: arr[i].title,
          },
        },
      }).then((response) => {
        if (section.questions.length > 0) {
          const {
            data: {
              checklistSectionCreate: { result },
            },
          } = response;
          this.duplicateQuestions(
            section.questions,
            0,
            result,
            arr,
            i,
            category,
          );
        } else {
          this.duplicateSections(arr, i + 1, category);
        }
      });
    }
  };

  goToCategoryRoute = (id) => {
    const { location, navigate, params } = this.props;
    const org = location.state.org;

    navigate(`/checklists/${params.id}/categories/${id}`, { state: { org } });
  };

  goToChecklistsRoute = () => {
    const { location, navigate, params } = this.props;

    if (location.state) {
      navigate(location.state.previous);
    } else {
      navigate(`/organizations/${params.id}/checklists`);
    }
  };

  handleChange = (key, value) => {
    this.setState(_.set(this.state, key, value));
  };

  saveCategory = (i) => {
    const {
      checklistCategoryCreateMutation,
      checklistCategoryUpdateMutation,
      params,
    } = this.props;
    const {
      category: { id, title },
      categories,
    } = this.state;

    const arr = categories;
    const isCreate = id.includes('create');
    const mutation = isCreate
      ? checklistCategoryCreateMutation
      : checklistCategoryUpdateMutation;
    let data = {
      title,
      rank: categories.length,
    };

    if (isCreate) {
      data.checklistId = params.id;
    } else {
      data = {
        ...data,
        id,
        scoreValues: [1, 2, 3, 4],
        scoreLabels: global.scoreValues,
      };
    }

    if (title) {
      mutation({
        variables: {
          input: data,
        },
      }).then((response) => {
        const key = Object.keys(response.data)[0];
        const res = response.data[key];

        if (res.errors) {
          window.alert(res.errors[0].message);
        } else if (isCreate) {
          this.setState(
            {
              category: res.result,
              categories: [
                ...arr.slice(0, i),
                res.result,
                ...arr.slice(i + 1, arr.length),
              ],
            },
            () => this.saveCategory(i),
          );
        } else {
          this.setState({
            category: {},
            categories: [
              ...arr.slice(0, i),
              res.result,
              ...arr.slice(i + 1, arr.length),
            ],
          });
        }
      });
    } else {
      window.alert('Category requires title.');
    }
  };

  save = () => {
    const {
      checklistSummaryCreateMutation,
      checklistSummaryUpdateMutation,
      location,
    } = this.props;
    const { color, desc, durationMins, kind, id, title, checklistType } = this.state;

    if (title) {
      this.setState(
        {
          loading: true,
        },
        () => {
          const mutation = id
            ? checklistSummaryUpdateMutation
            : checklistSummaryCreateMutation;
          const data = {
            checklistType,
            desc,
            durationMins,
            title,
            color,
            kind,
            orgId: location.state.org,
          };

          if (id) data.id = id;

          mutation({
            variables: {
              input: data,
            },
          }).then((response) => {
            const key = Object.keys(response.data)[0];
            const res = response.data[key];

            if (res.errors) {
              this.handleChange('loading', false);

              window.alert(res.errors[0].message);
            } else {
              this.checkStatus(res.result);
            }
          });
        },
      );
    } else {
      window.alert('Title is required.');
    }
  };

  updateCategories = (arr, i) => {
    if (i === arr.length) {
      this.handleChange('loading', false);
      this.goToChecklistsRoute();
    } else {
      const {
        checklistCategoryCreateMutation,
        checklistCategoryUpdateMutation,
        params,
      } = this.props;

      const obj = arr[i];
      const mutation = obj.id.includes('create')
        ? checklistCategoryCreateMutation
        : checklistCategoryUpdateMutation;
      const data = obj.id.includes('create')
        ? {
          checklistId: params.id,
          title: obj.title,
          rank: i + 1,
        }
        : {
          id: obj.id,
          rank: i + 1,
          scoreLabels: global.scoreValues,
          scoreValues: [1, 2, 3, 4],
        };

      mutation({
        variables: {
          input: data,
        },
      }).then(() => {
        this.updateCategories(arr, i + 1);
      });
    }
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <Checklist
        {...this.props}
        addCategory={this.addCategory}
        cancelCategory={this.cancelCategory}
        deleteCategory={this.deleteCategory}
        duplicateCategory={this.duplicateCategory}
        goToCategoryRoute={this.goToCategoryRoute}
        goToChecklistsRoute={this.goToChecklistsRoute}
        handleChange={this.handleChange}
        save={this.save}
        saveCategory={this.saveCategory}
        state={this.state}
      />
    );
  }
}

export default compose(
  withRouter,
  graphql(ChecklistQuery, {
    name: 'checklistQuery',
    options: (props) => ({
      variables: {
        id: props.params.id,
      },
    }),
  }),
  graphql(ChecklistCategoryCreateMutation, {
    name: 'checklistCategoryCreateMutation',
  }),
  graphql(ChecklistCategoryRemoveMutation, {
    name: 'checklistCategoryRemoveMutation',
  }),
  graphql(ChecklistCategoryUpdateMutation, {
    name: 'checklistCategoryUpdateMutation',
  }),
  graphql(ChecklistQuestionCreateMutation, {
    name: 'checklistQuestionCreateMutation',
  }),
  graphql(ChecklistSectionCreateMutation, {
    name: 'checklistSectionCreateMutation',
  }),
  graphql(ChecklistSummaryActivateMutation, {
    name: 'checklistSummaryActivateMutation',
  }),
  graphql(ChecklistSummaryDeactivateMutation, {
    name: 'checklistSummaryDeactivateMutation',
  }),
  graphql(ChecklistSummaryCreateMutation, {
    name: 'checklistSummaryCreateMutation',
  }),
  graphql(ChecklistSummaryUpdateMutation, {
    name: 'checklistSummaryUpdateMutation',
  }),
)(ChecklistContainer);
