import { gql } from 'apollo-boost';

const FeedbackSeekerDetails = gql`
  mutation UserFindOrCreateMutation($input: UserFindOrCreateInput) {
    userFindOrCreate(input: $input) {
      errors {
        code
        message
        __typename
      }
      result {
        id
        firstName
        fullName
        lastName
        roles {
          id
          name
          org {
            id
            __typename
          }
          __typename
        }
        __typename
      }
      success
      __typename
    }
  }
`;

const UpdateFeedbackSeekerRole = gql`
  mutation UserUpdateMutation($input: UserUpdateUpdateInput) {
    userUpdate(input: $input) {
      errors {
        code
        message
        __typename
      }
      success
      result {
        id
        roles {
          id
          name
          org {
            id
            title
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export { UpdateFeedbackSeekerRole };
export default FeedbackSeekerDetails;