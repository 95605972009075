import { gql } from 'apollo-boost';

const CoursesQuery = gql`
  query CoursesQuery($first: Int, $after: String, $status: [String!]) {
    courses(first: $first, after: $after, order: "title", status: $status) {
      nodes {
        id
        kind
        org {
          id
          title
        }
        status
        title
        courseType
      }
    }
  }
`;

export default CoursesQuery;
