import { gql } from 'apollo-boost';

const RosterInviteQuery = gql`
  query RosterInviteQuery($id: ID!, $user: ID, $order: String) {
    org(id: $id) {
      id
      attachment(label: "pdf_logo") {
        id
        url
      }
      brandColor
      bundles {
        id
        checklists {
          id
        }
        courses {
          id
        }
        tests {
          id
        }
        title
      }
      courses(order: $order, status: ["active"]) {
        nodes {
          id
          kind
          title
        }
      }
      checklists(order: $order, status: ["active"]) {
        nodes {
          id
          kind
          title
        }
      }
      tests(order: $order, status: ["active"]) {
        nodes {
          id
          kind
          title
        }
      }
      signatureDocuments(order: $order, status: ["active"]) {
        nodes {
          id
          title
        }
      }
      title
    }
    user(id: $user) {
      id
      attachment(label: "avatar") {
        id
        url
      }
      coursesTaken {
        id
      }
      checklistsTaken {
        id
      }
      testsTaken {
        id
      }
      email
      firstName
      lastName
      userSubscription {
        status
        availedCredit
        subscriptionDate
        expiryDate
        usedCredit
        __typename
      }
    }
  }
`;

export default RosterInviteQuery;
