import { gql } from 'apollo-boost';

const CourseChapterRemoveMutation = gql`
  mutation CourseChapterRemoveMutation($input: IDInput) {
    courseChapterRemove(input: $input) {
      errors {
        message
      }
      result {
        id
       
      }
    }
  }
`;

export default CourseChapterRemoveMutation;
