import { gql } from 'apollo-boost';

const UserUpdateMutation = gql`
  mutation UserUpdateMutation($input: UserUpdateUpdateInput) {
    userUpdate(input: $input) {
      errors {
        code
        message
      }
      success
      result {
        id
        roles {
          id
          name
          org {
            id
            title
          }
        }
      }
    }
  }
`;

export default UserUpdateMutation;
