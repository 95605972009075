import { gql } from "apollo-boost";

const SubscriptionsList = gql`
  query Subscriptions($status: [String!]) {
    subscriptions(status: $status) {
      edges {
        node {
          id
          title
          status
          amount
          creditEarn
          details
          validity
          validityInMonths
        }
      }
    }
  }
`;

export default SubscriptionsList;
