import { gql } from 'apollo-boost';

const SessionLoginMutation = gql`
  mutation SessionLoginMutation($input: SessionLoginLoginInput) {
    sessionLogin(input: $input) {
      errors {
        message
      }
      success
      token
      user {
        id
        attachment(label: "avatar") {
          id
          label
          url
        }
        bio
        email
        firstName
        fullName
        lastName
        homeStreet1
        homeStreet2
        homeCity
        homeState
        homeZip
        phoneNumber
        roles {
          id
          name
          org {
            id
            status
            attachments {
              id
            }
            attachment(label: "pdf_logo") {
              id
              url
            }
            brandColor
            modules
            title
          }
        }
      }
    }
  }
`;

export default SessionLoginMutation;
