import { gql } from 'apollo-boost';

const JobPostsQuery = gql`
query OrgJobPostQuery($id: ID!, $first: Int, $status: [String!], $search: String) {
  org(id: $id) {
    id
    jobPosts(first: $first, status: $status, search: $search) {
      nodes {
        id
        ecpJobId
        status
        title
        experience
        specialty
        jobExpire
        jobStatus
        jobDescription
        locationType
        locationName
        locationCity
        locationState
        locationCountry
        locationPostalCode
        locationAddress
        salaryType
        salaryTypeMinimum
        salaryTypeMaximum
      }
    }
  }
}
`;

export default JobPostsQuery;
