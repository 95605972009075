import React, { useEffect, useState } from 'react';
import LoadingPane from '../Shared/LoadingPane';
import Toolbar from '../Shared/Toolbar';
import { useQuery } from 'react-apollo';
import OrgQuery from '../../graphql/queries/Org/Users';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';

const ViewRosterList = (props) => {
  const [Alert, setAlert] = useState('');
  const [loadedData, setloadedData] = useState();
  const { id } = useParams();
  const [sortKey, setSortKey] = useState(['first_name']);
  const [sortIndex, setSortIndex] = useState(['asc']);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const navigate = useNavigate();

  const onCloseAlert = () => {
    setAlert('');
  };

  let userArray = [];
  let brandColor = null;
  let secondaryColor = null;
  let orgName = '';
  let orgActiveUsers = 0;
  let orgPendingUsers = 0;
  let orgInactiveUsers = 0;
  let orgLogo = null;

  const [variable, setVariable] = useState({
    id: id,
    roles: ['super_admin', 'admin', 'recruiter', 'nurse'],
    search: null,
    first: 100,
  });

  let users = [];
  const { loading, data, fetchMore } = useQuery(OrgQuery, {
    variables: variable,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setloadedData(data);
  }, [loading, data]);

  const filterByOrg = (arr) => {
    return arr.filter((item) => {
      const displayedOrg = parseInt(id, 10);
      if (
        !item.orgId ||
        displayedOrg === 1 ||
        displayedOrg === parseInt(item.orgId, 10)
      ) {
        return true;
      }

      return false;
    });
  };
  const filter = (arr, statusInput, inequal) => {
    return arr
      ? arr.filter((o) =>
        inequal ? o.status !== statusInput : o.status === statusInput,
      )
      : [];
  };

  const sortUsers = (unsorted) => {
    return _.orderBy(unsorted, sortKey, sortIndex);
  };

  const setupUsers = (startUsers) => {
    return startUsers.map((startUser) => {
      const endUser = { ...startUser };
      endUser.orgRole = endUser.roles?.find((r) => r.org.id === id);
      endUser.checklistsTaken = filterByOrg(startUser.checklistsTaken);
      endUser.testsTaken = filterByOrg(startUser.testsTaken);
      endUser.coursesTaken = filterByOrg(startUser.coursesTaken);
      endUser.checklistsFinished = filter(
        endUser.checklistsTaken,
        'finished',
      ).length;
      endUser.checklistsNotFinished =
        endUser.checklistsTaken.length - endUser.checklistsFinished;
      endUser.testsFinished = filter(endUser.testsTaken, 'finished').length;
      endUser.testsNotFinished =
        endUser.testsTaken.length - endUser.testsFinished;
      endUser.coursesFinished = filter(endUser.coursesTaken, 'finished').length;
      endUser.coursesNotFinished =
        endUser.coursesTaken.length - endUser.coursesFinished;
      if (
        endUser.testsFinished > 0 ||
        endUser.checklistsFinished > 0 ||
        endUser.coursesFinished > 0 ||
        endUser.orgRole.name !== 'nurse'
      ) {
        endUser.status = 'active';
      } else if (
        endUser.testsTaken.length +
        endUser.checklistsTaken.length +
        endUser.coursesTaken.length ===
        0
      ) {
        // nothing has been assigned
        endUser.status = 'inactive';
      } else {
        endUser.status = 'pending';
      }

      return endUser;
    });
  };

  const handleCheckboxClicked = (id) => {
    if (selectedUsers.includes(id)) {
      const updateSelectedUser = selectedUsers.filter(item => item !== id)
      setSelectedUsers(updateSelectedUser)
    } else {
      setSelectedUsers([...selectedUsers, id]);
    }
  }

  if (loadedData) {
    users = loadedData?.org.users;
    userArray = sortUsers(setupUsers(users?.edges.map((edge) => edge.node)));
    brandColor = loadedData.org.brandColor;
    secondaryColor = loadedData.org.secondaryColor;
    orgName = loadedData.org.title;
    orgActiveUsers = loadedData.org.activeUserCount || 0;
    orgPendingUsers = loadedData.org.pendingUserCount || 0;
    orgInactiveUsers = loadedData.org.inactiveUserCount || 0;
    orgLogo = loadedData.org.attachment;
  }

  const loadTablebody = () => {
    return userArray.length > 0 ? (
      userArray.map((userObj, index) => {
        const {
          attachment,
          id,
          email,
          firstName,
          lastName,
          roles: userRoles,
          status,
          checklistsFinished,
          checklistsNotFinished,
          testsFinished,
          testsNotFinished,
          coursesFinished,
          coursesNotFinished,
        } = userObj;

        return (
          <li className={'test-cell'} style={{ marginBottom: 3 }} key={index}>
            <div className="centered-frame">
              <div className="cell-content-frame" >
                <input
                  className='checkbox'
                  value="checkbox"
                  type="checkbox"
                  onChange={() => handleCheckboxClicked(id)}
                />
                <div className="cell-label list long" onClick={(e) => goToTestRoute(id)}>
                  {firstName} {lastName}
                </div>
                {/* <div className="cell-label list ">{"-"}</div> */}
                <div className="cell-label list long ">{email}</div>
                {/* <div className="cell-label list ">{checklistsFinished}</div>
                <div className="cell-label list ">{`(${checklistsNotFinished})`}</div>
                <div className="cell-label list ">{testsFinished}</div>
                <div className="cell-label list ">{email}</div> */}
              </div>
            </div>
          </li>
        );
      })
    ) : (
      <div className="layout-center hint">There are no members to display.</div>
    );
  };

  const onBack = () => {
    navigate(`/organizations/${id}/tests`);
  };

  const goToTestRoute = () => {
    if (selectedUsers.length > 0) {

      localStorage.setItem('selectedUsers', JSON.stringify(selectedUsers));
      navigate(`/organizations/${id}/rosters/subscribe`);
    } else {
      setAlert('No user selected')
    }
  };

  return (
    <div className="dashboard-feedback-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      {Alert && (
        <div className="modaloverlay">
          <div className="modal" style={{ width: '20%' }}>
            <h2>Alert</h2>
            <div
              className="model-body"
              dangerouslySetInnerHTML={{ __html: Alert }}
              style={{ display: 'inline' }}
            >
              {/* {Alert} */}
            </div>
            <div>
              <button
                type="submit"
                style={{ width: '20%' }}
                className="model-submit-button"
                onClick={onCloseAlert}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}

      <Toolbar cancel={onBack} next={goToTestRoute} title={'Choose users'} />

      <div style={{ padding: 10, marginTop: 80 }}>
        <div>
          <div className="feedback-table-viewthree" style={{ height: 610 }}>
            <div className="table-headers">
              <div className="centered-frame">
                <div className="table-header list long">Name</div>
                {/* <div className="table-header list">Type</div> */}
                <div className="table-header list long">Email</div>
                {/* <div
                  className="table-header list"
                  onClick={() => handleSort('checklistsNotFinished')}
                >
                  Skills Checklist
                </div>
                <div
                  className="table-header list"
                  onClick={() => handleSort('testsNotFinished')}
                >
                  Tests
                </div>
                <div
                  className="table-header list"
                  onClick={() => handleSort('coursesNotFinished')}
                >
                  Mandatories
                </div>
                <div
                  className="table-header list"
                  onClick={() => handleSort('status')}
                >
                  Status
                </div> */}
              </div>
            </div>
            <ul className="tests-list-view scroll">
              <>{loadTablebody()}</>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewRosterList;
