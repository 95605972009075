import React from 'react';
import Select from 'react-select';

const SelectMenu = (props) => {
  const {
    className,
    /*defaultValue,*/ isClearable,
    isDisabled = false,
    isMulti,
    onChange,
    options,
    placeholder,
    value,
  } = props;

  const height = 32;
  const fontSize = 12;

  return (
    <Select
      className={className}
      // defaultValue={options.find(o => o.value === defaultValue)}
      isDisabled={isDisabled}
      isClearable={isClearable}
      isMulti={isMulti}
      isSearchable={true}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      styles={{
        control: (provided) => ({
          ...provided,
          minHeight: height,
          borderColor: '#DBDBDB',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          height,
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          height,
        }),
        option: (provided) => ({
          ...provided,
          fontSize,
        }),
        valueContainer: (provided) => ({
          ...provided,
          fontSize,
          fontFamily: 'Soleil',
        }),
      }}
      value={options.find((o) => o.value === value) || null}
    />
  );
};

export default SelectMenu;
