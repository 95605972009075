import { gql } from 'apollo-boost';

const OrgQuery = gql`
  query OrgQuery($id: ID!) {
    org(id: $id) {
      id
      attachments(labels: ["pdf_logo"]) {
        id
        label
        url
      }
      checklists {
        nodes {
          id
          title
        }
      }
      courses {
        nodes {
          id
          title
        }
      }
      annualReminders
      autoResend
      brandColor
      csz
      desc
      expirationNotificationDays
      kind
      modules
      secondaryColor
      status
      signatureDocuments {
        nodes {
          id
          title
        }
      }
      streetAddress
      tests {
        nodes {
          id
          title
        }
      }
      title
    }
  }
`;

export default OrgQuery;
