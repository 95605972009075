import { gql } from 'apollo-boost';

const SubscriptionCreateMutation = gql`
mutation SubscriptionCreate($input:SubscriptionCreateInput){
    subscriptionCreate(input:$input){
      success
      result{
        id
        status
        title
        amount
        creditEarn
        details
        validity
        category
      }
      errors{
        code
        message
        backtrace
      }
    }
  }
`;

export default SubscriptionCreateMutation;
