import React from 'react';

import _ from 'lodash';
import moment from 'moment';
import LoadingPane from '../../../../components/Shared/LoadingPane';
import Resume from '../../../../components/Documents/Document/Resume';

export default class ResumeContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.state,
      edit: props.params.document === 'create',
      isModal: false,
      loading: false,
      onload: true,
      user: props.context.user,
    };
  }

  async componentDidMount() {
    fetch(this.state.imageUrl).then(async (response) => {
      this.setState({
        base64: await response.text(),
        onload: false,
      });
    });
  }
  componentDidUpdate() {
    if (!_.isEqual(this.props.mobile, this.state.mobile)) {
      this.handleChange('mobile', this.props.state.mobile);
    }
  }

  documentRemove = (hide) => {
    this.setState(
      {
        loading: true,
      },
      () => this.props.documentRemove(hide),
    );
  };

  handleChange = (key, value) => {
    this.setState(_.set(this.state, key, value));
  };

  save = () => {
    const { save, saveAttachment } = this.props;
    const { image } = this.state;

    const data = {
      documentNumber: '1 Photo',
      kind: 'resume',
      issuedOn: moment().format('YYYY-MM-DD'),
      issuingState: 'n/a',
      title: 'Resume / CV',
    };

    if (image) {
      this.setState(
        {
          loading: true,
        },
        async () => {
          if (image) data.image = await saveAttachment(image);

          save(data);
        },
      );
    }
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <Resume
        {...this.props}
        documentRemove={this.documentRemove}
        handleChange={this.handleChange}
        save={this.save}
        state={this.state}
      />
    );
  }
}
