import { gql } from 'apollo-boost';

const TestCategoryUpdateMutation = gql`
  mutation TestCategoryUpdateMutation($input: TestCategoryUpdateInput) {
    testCategoryUpdate(input: $input) {
      errors {
        message
      }
      result {
        id
        questions {
          id
        }
        rank
        title
      }
    }
  }
`;

export default TestCategoryUpdateMutation;
