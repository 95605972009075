import { gql } from 'apollo-boost';

const RosterQuery = gql`
  query RosterQuery($id: ID, $org: ID!) {
    org(id: $org) {
      id
      modules
    }
    user(id: $id) {
      id
      attachment(label: "avatar") {
        id
        label
        url
      }
      bio
      checklistsTaken {
        id
        answers {
          id
          question {
            id
            scoreValues
          }
          value
        }
        checklist {
          id
          categories {
            id
            rank
            sections {
              id
              rank
              questions {
                id
                rank
                scoreValues
                title
              }
              scoreValues
              scoreLabels
              title
            }
            title
          }
          title
        }
        createdAtIso
        endedAt
        orgId
        startedAt
        status
        title
      }
      coursesTaken {
        id
        createdAtIso
        endedAt
        orgId
        passed
        score
        startedAt
        status
        title
      }
      documents {
        nodes {
          id
          documentNumber
          expiresOn
          kind
          imageUrl
          imageBackUrl
          issuerLocation
          issuerName
          issuedOn
          issuingState
          isVerified
          status
          title
          verificationData
        }
      }
      firstName
      lastName
      email
      references(states: ["completed"]) {
        id
        completedAtIso
        firstName
        lastName
        organization
        relationship
      }
      specialties {
        id
        isPrimary
        name
      }
      testsTaken {
        id
        createdAtIso
        endedAt
        orgId
        passed
        score
        startedAt
        status
        title
      }
      roles {
        id
        name
        org {
          id
        }
      }
      workExperiences {
        id
        city
        endedOn
        kind
        name
        positionTitle
        state
        startedOn
      }
      userSubscription {
        status
        availedCredit
        subscriptionDate
        expiryDate
        usedCredit
        __typename
      }
    }
  }
`;

export default RosterQuery;
