import { gql } from 'apollo-boost';

const UsersByUlid = gql`
  query UserList($ulid: [String!]){
    users(ulid: $ulid){
      edges{
        node{
          id
          email
          fullName
        }
      }
    }
  }
`;

export default UsersByUlid;