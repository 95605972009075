import { gql } from 'apollo-boost';

const CourseUpdateMutation = gql`
  mutation CourseUpdateMutation($input: CourseSummaryUpdateUpdateInput) {
    courseUpdate(input: $input) {
      errors {
        code
        message
      }
      result {
        id
       
      }
    }
  }
`;

export default CourseUpdateMutation;
