import React from 'react';
import { graphql } from 'react-apollo';

import axios from 'axios';
import { flowRight as compose, uniq } from 'lodash';
import Cookie from 'js-cookie';

import RosterInviteQuery from '../../graphql/queries/Roster/Invite';
import UserChecklistCreateMutation from '../../graphql/mutations/User/Checklist/Create';
import UserCourseCreateMutation from '../../graphql/mutations/User/Course/Create';
import UserTestCreateMutation from '../../graphql/mutations/User/Test/Create';

import RosterInvite from '../../components/Roster/Invite';

import LoadingPane from '../../components/Shared/LoadingPane';
import { withRouter } from '../withRouter';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

const refs = {};

class RosterInviteContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: true,
      expandTests: false,
      expandCourseshecklists: false,
      expandCourses: false,
      expandDocuments: false,
      expandBundles: false,
      onload: true,
      selectedTests: [],
      selectedChecklists: [],
      selectedCourses: [],
      selectedDocuments: [],
      selectedBundles: [],
    };
  }

  componentDidMount() {
    const {
      rosterInviteQuery: { loading, refetch },
    } = this.props;
    const { onload } = this.state;

    if (onload && loading) {
      refetch().then(() => this.setup());
    } else {
      this.setup();
    }
  }

  componentDidUpdate() {
    this.setup();
  }

  setup = () => {
    const {
      rosterInviteQuery: { loading, org, user },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      const { bundles, courses, checklists, tests, signatureDocuments } = org;

      this.setState(
        {
          bundles,
          courses: {
            ...courses,
            nodes: uniq(courses.nodes, ['id']),
          },
          checklists: {
            ...checklists,
            nodes: uniq(checklists.nodes, ['id']),
          },
          onload: false,
          org,
          tests: {
            ...tests,
            nodes: uniq(tests.nodes, ['id']),
          },
          documents: {
            ...signatureDocuments,
            nodes: uniq(signatureDocuments.nodes, ['id']),
          },
          user,
        },
        () => {
          setTimeout(() => {
            this.setState({
              // expandTests: true,
              // expandCourseshecklists: true,
              // expandCourses: true,
              expandBundles: true,
            });
          }, 500);
        },
      );
    }
  };

  goToRosterRoute = () => {
    const { navigate, params } = this.props;

    navigate(`/organizations/${params.id}/roster/${params.roster}`);
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleSelectAll = (arr, key) => {
    const isAll = arr.length === this.state[key].length;

    this.handleChange(key, isAll ? [] : arr.map((o) => o.id));
  };

  handleSelection = (e, key, value, bundle) => {
    let i;
    const arr = this.state[key];
    const checkbox = e.target.parentNode.className === 'checkbox';
    const node = checkbox
      ? e.target.parentNode
      : e.target.parentNode.firstChild;
    const checked = checkbox ? !arr.includes(value) : !node.firstChild.checked;

    if (checked) {
      arr.concat(checked);
    } else {
      i = arr.findIndex((idx) => idx === value);
    }

    if (bundle) {
      const {
        selectedTests,
        selectedChecklists,
        selectedCourses,
        selectedDocuments,
        courses: possibleCourses,
        checklists: possibleChecklists,
        tests: possibleTests,
        documents: possibleDocuments,
      } = this.state;
      const { checklists, courses, tests, documents = [] } = bundle;

      this.setState({
        [key]: checked
          ? [...arr, value]
          : [...arr.slice(0, i), ...arr.slice(i + 1, arr.length)],
        selectedTests: checked
          ? uniq([
              ...selectedTests,
              ...tests
                .filter((test) => {
                  return (
                    possibleTests.nodes.findIndex((pt) => pt.id === test.id) !==
                    -1
                  );
                })
                .map((o) => o.id),
            ])
          : selectedTests.filter((id) => !tests.map((o) => o.id).includes(id)),
        selectedChecklists: checked
          ? uniq([
              ...selectedChecklists,
              ...checklists
                .filter((check) => {
                  return (
                    possibleChecklists.nodes.findIndex(
                      (pc) => pc.id === check.id,
                    ) !== -1
                  );
                })
                .map((o) => o.id),
            ])
          : selectedChecklists.filter(
              (id) => !checklists.map((o) => o.id).includes(id),
            ),
        selectedCourses: checked
          ? uniq([
              ...selectedCourses,
              ...courses
                .filter((course) => {
                  return (
                    possibleCourses.nodes.findIndex(
                      (pc) => pc.id === course.id,
                    ) !== -1
                  );
                })
                .map((o) => o.id),
            ])
          : selectedCourses.filter(
              (id) => !courses.map((o) => o.id).includes(id),
            ),
        selectedDocuments: checked
          ? uniq([
              ...selectedDocuments,
              ...documents
                .filter((document) => {
                  return (
                    possibleDocuments.nodes.findIndex(
                      (pd) => pd.id === document.id,
                    ) !== -1
                  );
                })
                .map((o) => o.id),
            ])
          : selectedDocuments.filter(
              (id) => !documents.map((o) => o.id).includes(id),
            ),
      });
    } else {
      this.handleChange(
        key,
        checked
          ? [...arr, value]
          : [...arr.slice(0, i), ...arr.slice(i + 1, arr.length)],
      );
    }
  };

  save = () => {
    const {
      selectedTests,
      selectedChecklists,
      selectedCourses,
      selectedDocuments,
    } = this.state;

    if (
      selectedTests.length +
        selectedChecklists.length +
        selectedCourses.length >
      0
    ) {
      this.setState(
        {
          loading: true,
        },
        () => this.saveChecklists(selectedChecklists),
      );
    } else {
      this.goToRosterRoute();
    }
  };

  saveChecklists = (arr) => {
    if (arr.length === 0) {
      this.saveCourses(this.state.selectedCourses);
    } else {
      const { params, userChecklistCreateMutation } = this.props;

      userChecklistCreateMutation({
        variables: {
          input: {
            checklistId: arr[0],
            orgId: this.props.params.id,
            userId: params.roster,
            notifyEmail: this.state.active
              ? this.props.context.user.email
              : null,
          },
        },
      }).then(() => {
        this.saveChecklists(arr.slice(1, arr.length));
      });
    }
  };

  saveCourses = (arr) => {
    if (arr.length === 0) {
      this.saveTests(this.state.selectedTests);
    } else {
      const { params, userCourseCreateMutation } = this.props;

      userCourseCreateMutation({
        variables: {
          input: {
            courseId: arr[0],
            orgId: this.props.params.id,
            userId: params.roster,
            notifyEmail: this.state.active
              ? this.props.context.user.email
              : null,
          },
        },
      }).then(() => {
        this.saveCourses(arr.slice(1, arr.length));
      });
    }
  };

  saveTests = (arr) => {
    if (arr.length === 0) {
      this.sendEmail();
    } else {
      const { params, userTestCreateMutation } = this.props;

      userTestCreateMutation({
        variables: {
          input: {
            testId: arr[0],
            orgId: this.props.params.id,
            userId: params.roster,
            notifyEmail: this.state.active
              ? this.props.context.user.email
              : null,
          },
        },
      }).then(() => {
        this.saveTests(arr.slice(1, arr.length));
      });
    }
  };

  sendEmail = () => {
    const {
      checklists,
      courses,
      documents,
      selectedTests,
      selectedChecklists,
      selectedCourses,
      selectedDocuments,
      tests,
      org,
      user,
    } = this.state;
    const { attachment, brandColor, title } = org;
    const { coursesTaken, checklistsTaken, email, testsTaken } = user;

    const defaultLogo =
      'https://res.cloudinary.com/inewton/image/upload/v1/una-app/cache/e80cad79f0f6e17fd3c8254c763c2b8d.png';
    const defaultColor = '#FF5A5F';
    const url = `${process.env.REACT_APP_PROXY_URL}/send`;
    const existing = [coursesTaken, checklistsTaken, testsTaken].flat();
    const data = {
      dynamicTemplateData: {
        tests: selectedTests.map((id) => ({
          title: `${tests.nodes.find((t) => t.id === id).title}\n`,
        })),
        checklists: selectedChecklists.map((id) => ({
          title: `${checklists.nodes.find((c) => c.id === id).title}\n`,
        })),
        courses: selectedCourses.map((id) => ({
          title: `${courses.nodes.find((c) => c.id === id).title}\n`,
        })),
        documents: selectedDocuments.map((id) => ({
          title: `${documents.nodes.find((d) => d.id === id).title}\n`,
        })),
        url: `${process.env.REACT_APP_UNA_TEST_URL}${
          existing.length === 0
            ? `?e=${encodeURIComponent(email)}&p=Welcome2020`
            : ''
        }`,
        logo: attachment ? attachment.url : defaultLogo,
        color: brandColor || defaultColor,
        name: title,
      },
      environment: 'production',
      recipient: email,
      templateId:
        existing.length > 0
          ? 'd-fce7c7544c3e437f9c019f3d1e77a7e8'
          : 'd-3ac00656aa2b4380b70c9594b99193f4',
    };
    const headers = {
      Authorization: Cookie.get(`${global.COOKIE_NAME}-token`),
    };

    axios
      .post(url, data, { headers })
      .then(() => {
        console.log('email sent.');

        this.handleChange('loading', false);
        this.goToRosterRoute();
      })
      .catch(() => {
        window.alert('Email invite unsuccessful.');
        this.setState({ loading: false });
      });
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <Elements stripe={stripePromise}>
        <RosterInvite
          goToRosterRoute={this.goToRosterRoute}
          handleChange={this.handleChange}
          handleSelectAll={this.handleSelectAll}
          handleSelection={this.handleSelection}
          refs={refs}
          save={this.save}
          state={this.state}
        />
      </Elements>
    );
  }
}

export default compose(
  withRouter,
  graphql(RosterInviteQuery, {
    name: 'rosterInviteQuery',
    options: ({ params: { id, roster } }) => ({
      variables: {
        id,
        user: roster,
      },

      fetchPolicy: 'cache-and-network',
    }),
  }),
  graphql(UserChecklistCreateMutation, { name: 'userChecklistCreateMutation' }),
  graphql(UserCourseCreateMutation, { name: 'userCourseCreateMutation' }),
  graphql(UserTestCreateMutation, { name: 'userTestCreateMutation' }),
)(RosterInviteContainer);
