import { gql } from 'apollo-boost';

const RosterQuery = gql`
  query RosterQuery($id: ID, $org: ID!) {
    user(id: $id) {
      id
      attachment(label: "avatar") {
        id
        label
        url
      }
      bio
      checklistsTaken(state: "finished") {
        id
        orgId
        answers {
          id
          question {
            id
            scoreValues
          }
          value
        }
        checklist {
          id
          categories {
            id
            rank
            sections {
              id
              rank
              questions {
                id
                rank
                scoreValues
                title
              }
              title
            }
            title
          }
        }
        createdAtIso
        endedAt
        startedAt
        status
        title
      }
      coursesTaken(state: "finished") {
        id
        orgId
        answers {
          id
          isCorrect
          question {
            id
            rank
            title
          }
          value
        }
        createdAtIso
        endedAt
        passed
        score
        startedAt
        status
        title
      }
      dob
      documents {
        nodes {
          id
          documentNumber
          expiresOn
          kind
          imageUrl
          imageBackUrl
          issuerLocation
          issuerName
          issuedOn
          issuingState
          isVerified
          status
          title
          verificationData
        }
      }
      email
      firstName
      homeStreet1
      homeStreet2
      homeCity
      homeState
      homeZip
      lastName
      phoneNumber
      references(states: ["completed"]) {
        id
        clinicalCompetancy
        communication
        completedAtIso
        eligibleForRehire
        email
        endDate
        firstName
        lastName
        organization
        otherDetails
        phone
        professionalism
        startDate
        relationship
        technologyCompetancy
        attendancePunctuality
        title
      }
      specialties {
        id
        isPrimary
        name
        startedOn
      }
      testsTaken(state: "finished") {
        id
        orgId
        answers {
          id
          isCorrect
          question {
            id
            rank
            title
          }
          value
        }
        createdAtIso
        endedAt
        passed
        score
        startedAt
        status
        test {
          id
          desc
        }
        title
      }
      workExperiences {
        id
        city
        endedOn
        kind
        name
        positionTitle
        state
        startedOn
      }
    }
    org(id: $org) {
      id
      attachments(labels: ["pdf_logo"]) {
        id
        label
        url
      }
      modules
    }
  }
`;

export default RosterQuery;
