import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import ColorPicker from '../Shared/ColorPicker';
import LoadingPane from '../Shared/LoadingPane';
import Toolbar from '../Shared/Toolbar';

import DragList from '../../containers/Shared/DragList';
import SelectMenu from '../Shared/SelectMenu';

const Checklist = (props) => {
  const {
    addCategory,
    cancelCategory,
    deleteCategory,
    duplicateCategory,
    goToCategoryRoute,
    goToChecklistsRoute,
    handleChange,
    saveCategory,
    save,
    state,
  } = props;
  const {
    active,
    admin,
    categories,
    category,
    color,
    desc,
    kind,
    loading,
    id,
    title,
    mappingOptions,
    checklistType
  } = state;



  return (
    <div className="checklist-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      <Toolbar
        active={active}
        cancel={goToChecklistsRoute}
        handleChange={handleChange}
        isToggle={true}
        save={save}
        title={id ? 'Edit Skills Checklist' : 'Add New Skills Checklist'}
        checklistType={checklistType}
      />

      <div className="content-frame scroll">
        <div className="centered-frame">
          <div className="section-frame">
            <div className="section-header-frame">
              <div className="section-header">Checklist Details</div>
            </div>

            <div className="form-frame">
              <div className="form-input-frame">
                <div className="input-title">Title</div>

                <input
                  className="form-input"
                  defaultValue={title}
                  onChange={(e) => handleChange('title', e.target.value)}
                  placeholder="Enter Title of Test"
                />
              </div>

              <div className="form-input-frame middle">
                <div className="input-title">Color</div>

                <ColorPicker
                  displayHex={true}
                  onChange={(color) => handleChange('color', color)}
                  value={color}
                />
              </div>


              <div className="form-input-frame">
                <div className="input-title">Checklist Type</div>

                <SelectMenu
                  className="form-menu"
                  isClearable={true}
                  onChange={(e) => {
                    console.log("Selected Option:", e);
                    if (e) handleChange('checklistType', e.value);
                  }}
                  options={mappingOptions}
                  placeholder="Select Test Type"
                  value={checklistType}
                />

              </div>

              {admin ? (
                <div className="form-input-frame">
                  <div className="input-title">Kind</div>

                  <input
                    className="form-input"
                    defaultValue={kind}
                    onChange={(e) => handleChange('kind', e.target.value)}
                    placeholder="Enter Kind of Test"
                  />
                </div>
              ) : null}

              <div
                className="form-input-frame"
                style={{ width: '100%', margin: 0 }}
              >
                <div className="input-title">Description</div>

                <textarea
                  className="form-area"
                  defaultValue={desc}
                  placeholder="Enter description for test..."
                  onChange={(e) => handleChange('desc', e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className={id ? 'section-frame' : 'hide'}>
            <div className="section-header-frame">
              <div className="section-header">{`Categories (${categories.length})`}</div>

              <button className="section-button" onClick={addCategory}>
                + Add Category
              </button>
            </div>

            {categories.length === 0 ? (
              <div className="empty-hint hint">
                No categories have been added.
              </div>
            ) : (
              <div className="categories-table-view">
                <div className="table-headers">
                  <div className="table-header title">Title</div>
                  <div className="table-header questions">Sections</div>
                  <div className="table-header questions">Items</div>
                </div>

                <DragList
                  onChange={(arr) => handleChange('categories', arr)}
                  data={categories.map((obj, i) => {
                    const { id, sections, title } = obj;
                    const last = i === categories.length - 1;

                    return {
                      ...obj,
                      content:
                        category.id === id ? (
                          <div
                            className="category-cell edit"
                            key={i}
                            style={{ borderWidth: last ? 0 : 1 }}
                          >
                            <input
                              autoFocus
                              className="category-title"
                              defaultValue={title}
                              onChange={(e) =>
                                handleChange('category.title', e.target.value)
                              }
                              placeholder="Enter chapter title"
                            />

                            <button
                              className="category-button red"
                              onClick={cancelCategory}
                            >
                              Cancel
                            </button>
                            <button
                              className="category-button teal"
                              onClick={() => saveCategory(i)}
                            >
                              Save
                            </button>
                          </div>
                        ) : (
                          <div
                            className="category-cell"
                            style={{ borderWidth: last ? 0 : 1 }}
                          >
                            <div className="cell-content-frame">
                              <div className="drag-icon">
                                <img
                                  draggable={false}
                                  src={require('../../theme/images/hashes.png')}
                                  alt="hashes-icon"
                                />
                              </div>

                              <div className="cell-label title">{title}</div>

                              <div className="cell-label questions">
                                {sections.length}
                              </div>

                              <div className="cell-label questions">
                                {sections.map((s) => s.questions).flat().length}
                              </div>
                            </div>

                            <OverlayTrigger
                              trigger="click"
                              placement="bottom"
                              rootClose
                              overlay={
                                <Popover id="menu-pane" className="menu-pane">
                                  <div
                                    className="menu-content-view"
                                    style={{ padding: '5px 0px' }}
                                  >
                                    <button
                                      className="menu-button teal"
                                      onClick={() => goToCategoryRoute(id)}
                                    >
                                      View Details
                                    </button>
                                    <button
                                      className="menu-button teal"
                                      onClick={() =>
                                        handleChange('category', { ...obj })
                                      }
                                    >
                                      Edit Title
                                    </button>
                                    <button
                                      className="menu-button teal"
                                      onClick={() => duplicateCategory(obj)}
                                    >
                                      Duplicate
                                    </button>
                                    <button
                                      className="menu-button red"
                                      onClick={() => deleteCategory(id, i)}
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </Popover>
                              }
                            >
                              <div className="expander-icon">
                                <img
                                  src={require('../../theme/images/expander-icon-grey-2x.png')}
                                  alt="expander-icon"
                                />
                              </div>
                            </OverlayTrigger>
                          </div>
                        ),
                    };
                  })}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checklist;
