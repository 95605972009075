import React from 'react';
import renderHTML from 'react-render-html';

import _, { orderBy } from 'lodash';
import moment from 'moment';
import DocumentDisplay from '../../Control/DocumentDisplay';

import ProgressBar from '../../Shared/ProgressBar';

import { jobTitles, positionTitles } from '../../../utils/Constants';

import TestIcon from '../../../theme/images/test-icon-2x.png';
import CompanyLogo from '../../../theme/images/hire-logo-2x.png';

import STRINGS from '../../../localization';
import ChecklistScore from '../../Shared/Checklist/ChecklistScore';

const color = (score) => {
  if (score === 4) {
    return 'bg-green';
  } else if (score === 3) {
    return 'bg-blue';
  } else if (score === 2) {
    return 'bg-red';
  } else {
    return 'bg-orange';
  }
};

const isPDF = (url) => {
  if (url) {
    const urlSplit = url.split('.');
    const ext = urlSplit[urlSplit.length - 1];

    if (ext === 'pdf') {
      return true;
    }
  }
  return false;
};

const PacketSelect = (props) => {
  const {
    formatPhone,
    generateContentUrl,
    handleChange,
    parseChecklist,
    state,
  } = props;
  const {
    address,
    attachment: avatar,
    bio,
    dob,
    email,
    firstName,
    lastName,
    org,
    phoneNumber,
    specialty,
    summary,
    certifications,
    checklists,
    courses,
    documents,
    education,
    experiences,
    licenses,
    references,
    tests,
    attendanceOptions,
    contactDisplay = [],
  } = state;

  const attachment = org.attachments.find((a) => a.label === 'pdf_logo');
  const experience = specialty
    ? moment.duration(moment().diff(moment(specialty.startedOn)))
    : null;
  const summaryCreds = [licenses, certifications, education].flat();
  const browser = navigator.userAgent.toLowerCase();
  console.log(documents);

  return (
    <div className="roster-pdf-view full-screen-view">
      <table>
        <tbody>
          <tr>
            <td>
              <div className="toolbar">
                <button
                  className="cancel-button"
                  onClick={() => handleChange('pdf', false)}
                >
                  Back
                </button>

                <div className="print-button" onClick={() => window.print()}>
                  <img
                    src={require('../../../theme/images/print-icon-2x.png')}
                    alt="print-icon"
                  />
                </div>
              </div>

              <div className="content-frame scroll">
                <div className="centered-frame">
                  <div className="profile-frame">
                    <div className="company-logo">
                      <img
                        src={
                          attachment && false
                            ? attachment.url
                            : CompanyLogo
                        }
                        alt="company-logo"
                      />
                    </div>

                    <div className="avatar-frame">
                      <div className="avatar">
                        {avatar ? (
                          <img src={avatar.url} alt="avatar" />
                        ) : (
                          <div className="initials">{`${firstName.slice(
                            0,
                            1,
                          )}${lastName.slice(0, 1)}`}</div>
                        )}
                      </div>

                      <div className="full-name">{`${firstName} ${lastName}`}</div>
                    </div>

                    <div
                      className="details-frame"
                      style={{
                        borderBottom:
                          summaryCreds.length > 0
                            ? 'none'
                            : '1px dashed #919191',
                      }}
                    >
                      <div className="detail-frame">
                        <div className="detail-label hint">{`${STRINGS.primary} ${STRINGS.specialty}`}</div>
                        <div className="detail-label bold">
                          {specialty ? specialty.name : '-'}
                        </div>
                      </div>

                      <div className="detail-frame">
                        <div className="detail-label hint">{`${STRINGS._years} ${STRINGS.of} ${STRINGS._experience}`}</div>
                        <div className="detail-label bold">
                          {experience
                            ? `${experience.asYears() > 1
                              ? Math.floor(experience.asYears())
                              : Math.floor(experience.asMonths())
                            } ${experience.asYears() > 1
                              ? STRINGS.years
                              : STRINGS.months
                            }`
                            : '-'}
                        </div>
                      </div>

                      <div className="detail-frame" />

                      {contactDisplay.includes('email') && (
                        <div className="detail-frame">
                          <div className="detail-label hint capitalize">
                            {STRINGS.email}
                          </div>
                          <div className="detail-label bold">
                            {email || '-'}
                          </div>
                        </div>
                      )}

                      {contactDisplay.includes('phone') && (
                        <div className="detail-frame">
                          <div className="detail-label hint capitalize">
                            {STRINGS.phone}
                          </div>
                          <div className="detail-label bold">
                            {formatPhone(phoneNumber)}
                          </div>
                        </div>
                      )}

                      {contactDisplay.includes('address') && (
                        <div className="detail-frame" style={{ width: '100%' }}>
                          <div className="detail-label hint capitalize">
                            {STRINGS.address}
                          </div>
                          <div className="detail-label bold">
                            {address || '-'}
                          </div>
                        </div>
                      )}

                      <div
                        className={bio ? 'detail-frame' : 'hide'}
                        style={{ width: '100%' }}
                      >
                        <div className="detail-label hint capitalize">
                          {STRINGS.bio}
                        </div>
                        <div className="detail-label">{bio}</div>
                      </div>

                      <div
                        className={
                          summaryCreds.length > 0
                            ? `detail-creds-frame ${browser.includes('chrome') ? 'chrome' : 'safari'
                            }`
                            : 'hide'
                        }
                      >
                        {licenses.length > 0 ? (
                          <div className="detail-cred-frame">
                            <div className="detail-cred-header">{`${STRINGS.license} (${licenses.length})`}</div>
                            <ul className="detail-cred-list-view">
                              {orderBy(licenses, ['expiresOn'], ['desc'])
                                .slice(0, 3)
                                .map((result) => {
                                  const {
                                    documentNumber,
                                    expiresOn,
                                    id,
                                    issuingState,
                                    title,
                                  } = result;
                                  const expired = moment().isAfter(
                                    moment(expiresOn),
                                  );

                                  return (
                                    <li className="detail-cred-cell" key={id}>
                                      <div className="cell-label bold">
                                        {title}
                                      </div>
                                      <div className="cell-label">{`#${documentNumber} | ${issuingState}`}</div>
                                      <div className="cell-label">{`${expired
                                        ? STRINGS.expired
                                        : STRINGS.expires
                                        } - ${moment(expiresOn).format(
                                          'MMM DD, YYYY',
                                        )}`}</div>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        ) : null}

                        {certifications.length > 0 ? (
                          <div className="detail-cred-frame">
                            <div className="detail-cred-header">{`${STRINGS.certification} (${certifications.length})`}</div>

                            <ul className="detail-cred-list-view">
                              {orderBy(certifications, ['title'], ['asc'])
                                .slice(0, 3)
                                .map((result) => {
                                  const { expiresOn, id, title } = result;

                                  const expired = moment().isAfter(
                                    moment(expiresOn),
                                  );

                                  return (
                                    <li className="detail-cred-cell" key={id}>
                                      <div className="cell-label bold">
                                        {title}
                                      </div>
                                      <div className="cell-label">{`${expired
                                        ? STRINGS.expired
                                        : STRINGS.expires
                                        } - ${moment(expiresOn).format(
                                          'MMM DD, YYYY',
                                        )}`}</div>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        ) : null}

                        {education.length > 0 ? (
                          <div className="detail-cred-frame">
                            <div className="detail-cred-header">{`${STRINGS.education} (${education.length})`}</div>
                            <ul className="detail-cred-list-view">
                              {education.slice(0, 3).map((result) => {
                                const {
                                  id,
                                  issuedOn,
                                  issuerLocation,
                                  issuerName,
                                  issuingState,
                                  title,
                                } = result;
                                const degree = {
                                  associate: STRINGS.associateDegree,
                                  bachelor: STRINGS.bachelorDegree,
                                  doctor: STRINGS.doctoralDegree,
                                  master: STRINGS.masterDegree,
                                };

                                return (
                                  <li className="detail-cred-cell" key={id}>
                                    <div className="cell-label bold">
                                      {issuerName}
                                    </div>
                                    <div className="cell-label">{`${degree[title]
                                      } ${issuerLocation && issuerLocation
                                        ? `| ${issuerLocation}, ${issuingState}`
                                        : ''
                                      }`}</div>
                                    <div className="cell-label">
                                      {moment(issuedOn).format('MMM YYYY')}
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        ) : null}
                        {/* end */}
                      </div>
                    </div>
                  </div>

                  {/* work experience */}
                  <div
                    className={
                      experiences.length === 0 ? 'hide' : 'results-frame'
                    }
                  >
                    <div className="header">{STRINGS.workExperience}</div>
                    <ul className="results-list-view">
                      {experiences.map((result) => {
                        const {
                          city,
                          endedOn,
                          id,
                          kind,
                          name,
                          positionTitle,
                          startedOn,
                          state: addrState,
                          __typename: type,
                        } = result;
                        const position = positionTitles.find(
                          (o) => o.id === positionTitle,
                        );

                        return (
                          <li
                            className={
                              summary ? 'result-cell summary' : 'result-cell'
                            }
                            key={`${type}-${id}`}
                          >
                            <div className="top-section">
                              <div className="flex-frame column">
                                <div className="cell-label bold">{name}</div>
                                <div className="cell-label">{`${position
                                  ? `${position.value} ${kind ? `- ${kind} ` : ''
                                  }| `
                                  : ''
                                  }${city}, ${addrState}`}</div>
                              </div>
                              <div className="flex-frame score">
                                <div className="cell-label date">{`${moment(
                                  startedOn,
                                ).format('MMM DD, YYYY')} - ${endedOn
                                  ? moment(endedOn).format('MMM DD, YYYY')
                                  : 'Present'
                                  }`}</div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  {/* education */}
                  <div
                    className={
                      education.length === 0 ? 'hide' : 'results-frame'
                    }
                  >
                    <div className="header">{STRINGS.education}</div>
                    <ul className="results-list-view">
                      {education.map((result) => {
                        const {
                          id,
                          imageUrl,
                          imageBackUrl,
                          issuedOn,
                          issuerLocation,
                          issuerName,
                          issuingState,
                          title,
                          __typename: type,
                        } = result;
                        const degree = {
                          associate: STRINGS.associateDegree,
                          bachelor: STRINGS.bachelorDegree,
                          doctor: STRINGS.doctoralDegree,
                          master: STRINGS.masterDegree,
                        };
                        const resultClassname = isPDF(imageUrl)
                          ? 'result-cell-breakable'
                          : 'result-cell';
                        return (
                          <li
                            className={
                              summary
                                ? `${resultClassname} summary`
                                : resultClassname
                            }
                            key={`${type}-${id}`}
                          >
                            <div className="top-section">
                              <div className="flex-frame column">
                                <div className="cell-label bold">
                                  {issuerName}
                                </div>
                                <div className="cell-label">{`${degree[title]
                                  } ${issuerLocation && issuerLocation
                                    ? `| ${issuerLocation}, ${issuingState}`
                                    : ''
                                  }`}</div>
                              </div>
                              <div className="flex-frame score">
                                <div className="cell-label date">
                                  {moment(issuedOn).format('MMM YYYY')}
                                </div>
                              </div>
                            </div>

                            <div
                              className={
                                !summary && imageUrl
                                  ? `result-image-frame ${browser.includes('chrome')
                                    ? 'chrome'
                                    : 'safari'
                                  }`
                                  : 'hide'
                              }
                            >
                              <DocumentDisplay
                                docUrl={imageUrl}
                                title={title}
                              />
                            </div>

                            <div
                              className={
                                !summary && imageBackUrl
                                  ? `result-image-frame ${browser.includes('chrome')
                                    ? 'chrome'
                                    : 'safari'
                                  }`
                                  : 'hide'
                              }
                            >
                              <DocumentDisplay
                                docUrl={imageBackUrl}
                                title={title}
                              />
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  {/* reference */}
                  <div
                    className={
                      references.length === 0 ? 'hide' : 'results-frame'
                    }
                  >
                    <div className="header">{STRINGS.reference}</div>
                    <ul className="results-list-view">
                      {references.map((result) => {
                        const {
                          clinicalCompetancy,
                          communication,
                          completedAtIso,
                          eligibleForRehire,
                          email,
                          endDate,
                          firstName,
                          lastName,
                          id,
                          organization,
                          otherDetails,
                          phone,
                          professionalism,
                          relationship,
                          startDate,
                          technologyCompetancy,
                          attendancePunctuality,
                          title,
                          __typename: type,
                        } = result;
                        const ratings = {
                          clinical: {
                            0: 'Unable to answer',
                            1: 'Still learning',
                            2: 'Gets the job done',
                            3: 'Can serve as a preceptor',
                          },
                          comm: {
                            0: 'Unable to answer',
                            1: 'Painfully quiet',
                            2: 'Collaborative',
                            3: 'Role-model for others',
                          },
                          pro: {
                            0: 'Unable to answer',
                            1: 'Varies from time to time',
                            2: 'Consistently positive',
                            3: 'Stellar',
                          },
                          tech: {
                            0: 'Unable to answer',
                            1: 'Hand holding required',
                            2: 'Savvy/catches on easily',
                            3: 'Should have been an engineer',
                          },
                          attendance: attendanceOptions,
                        };

                        const formattedTitle = jobTitles.find(
                          (o) => o.id === title,
                        );
                        const formattedRelationship = jobTitles.find(
                          (o) => o.id === relationship,
                        );

                        return (
                          <li
                            className={
                              summary ? 'result-cell summary' : 'result-cell'
                            }
                            key={`${type}-${id}`}
                          >
                            <div className="top-section">
                              <div className="flex-frame column">
                                <div className="cell-label bold">{`${firstName} ${lastName}`}</div>
                                <div className="cell-label">{`${organization}${title ? ` | ${formattedTitle.value}` : ''
                                  }`}</div>
                              </div>
                              <div className="cell-label date">
                                {moment(completedAtIso).format('MMM D, YYYY')}
                              </div>
                            </div>

                            <div
                              className={summary ? 'hide' : 'reference-frame'}
                            >
                              <div className="flex-frame">
                                <div className="cell-label key">
                                  {STRINGS.relationship}
                                </div>
                                <div className="cell-label bold">
                                  {formattedRelationship
                                    ? formattedRelationship.value
                                    : '-'}
                                </div>
                              </div>

                              <div className="flex-frame">
                                <div className="cell-label key">
                                  {STRINGS.email}
                                </div>
                                <div className="cell-label bold">
                                  {email.toLowerCase() || '-'}
                                </div>
                              </div>

                              <div className="flex-frame">
                                <div className="cell-label key">
                                  {STRINGS.phoneNumber}
                                </div>
                                <div className="cell-label bold">
                                  {phone || '-'}
                                </div>
                              </div>

                              <div className="flex-frame">
                                <div className="cell-label key">{`${STRINGS.employment} ${STRINGS.date}s`}</div>
                                <div className="cell-label bold">{`${moment(
                                  startDate,
                                ).format('MMM YYYY')} - ${endDate
                                  ? moment(endDate).format('MMM YYYY')
                                  : STRINGS.present
                                  }`}</div>
                              </div>

                              <div className="flex-frame">
                                <div className="cell-label key">
                                  {STRINGS.eligibleForRehire}
                                </div>
                                <div className="cell-label bold capitalize">
                                  {eligibleForRehire ? eligibleForRehire : '-'}
                                </div>
                              </div>

                              <div className="flex-frame">
                                <div className="cell-label key">
                                  {STRINGS.professionalism}
                                </div>
                                <div className="cell-label bold">
                                  {ratings.pro[professionalism] || '-'}
                                </div>
                              </div>

                              <div className="flex-frame">
                                <div className="cell-label key">
                                  {STRINGS.communicationWithOthers}
                                </div>
                                <div className="cell-label bold">
                                  {ratings.comm[communication] || '-'}
                                </div>
                              </div>

                              <div className="flex-frame">
                                <div className="cell-label key">
                                  {STRINGS.clinicalCompetancy}
                                </div>
                                <div className="cell-label bold">
                                  {ratings.clinical[clinicalCompetancy] || '-'}
                                </div>
                              </div>

                              <div className="flex-frame">
                                <div className="cell-label key">
                                  {STRINGS.technologyCompetancy}
                                </div>
                                <div className="cell-label bold">
                                  {ratings.tech[technologyCompetancy] || '-'}
                                </div>
                              </div>

                              <div className="flex-frame">
                                <div className="cell-label key">
                                  {STRINGS.attendancePunctuality}
                                </div>
                                <div className="cell-label bold">
                                  {ratings.attendance[attendancePunctuality] ||
                                    '-'}
                                </div>
                              </div>

                              <div className="flex-frame full">
                                <div className="cell-label key">
                                  {STRINGS.additionalInformation}
                                </div>
                                <div className="cell-label bold">
                                  {otherDetails || '-'}
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  {/* license */}
                  <div
                    className={licenses.length === 0 ? 'hide' : 'results-frame'}
                  >
                    <div className="header">{STRINGS.license}</div>
                    <ul className="results-list-view">
                      {orderBy(licenses, ['expiresOn'], ['desc']).map(
                        (result) => {
                          const {
                            documentNumber,
                            expiresOn,
                            id,
                            imageUrl,
                            imageBackUrl,
                            issuingState,
                            title,
                            __typename: type,
                          } = result;

                          const expired = moment().isAfter(moment(expiresOn));
                          const resultClassname = isPDF(imageUrl)
                            ? 'result-cell-breakable'
                            : 'result-cell';
                          return (
                            <li
                              className={
                                summary
                                  ? `${resultClassname} summary`
                                  : resultClassname
                              }
                              key={`${type}-${id}`}
                            >
                              <div className="top-section">
                                <div className="flex-frame column">
                                  <div className="cell-label bold">{title}</div>
                                  <div className="cell-label">{`${documentNumber} | ${issuingState}`}</div>
                                </div>

                                <div className="flex-frame score">
                                  {expired ? (
                                    <div className="cell-label margin red">
                                      Expired
                                    </div>
                                  ) : (
                                    <div className="cell-label margin">
                                      Expires
                                    </div>
                                  )}
                                  <div className="cell-label date">{`- ${moment(
                                    expiresOn,
                                  ).format('MMM DD, YYYY')}`}</div>
                                </div>
                              </div>

                              <div
                                className={
                                  !summary && imageUrl
                                    ? `result-image-frame ${browser.includes('chrome')
                                      ? 'chrome'
                                      : 'safari'
                                    }`
                                    : 'hide'
                                }
                              >
                                <DocumentDisplay
                                  docUrl={imageUrl}
                                  title={title}
                                />
                              </div>

                              <div
                                className={
                                  !summary && imageBackUrl
                                    ? `result-image-frame ${browser.includes('chrome')
                                      ? 'chrome'
                                      : 'safari'
                                    }`
                                    : 'hide'
                                }
                              >
                                <DocumentDisplay
                                  docUrl={imageBackUrl}
                                  title={title}
                                />
                              </div>
                            </li>
                          );
                        },
                      )}
                    </ul>
                  </div>

                  {/* certification */}
                  <div
                    className={
                      certifications.length === 0 ? 'hide' : 'results-frame'
                    }
                  >
                    <div className="header">{STRINGS.certification}</div>

                    <ul className="results-list-view">
                      {orderBy(certifications, ['title'], ['asc']).map(
                        (result) => {
                          const {
                            expiresOn,
                            id,
                            imageUrl,
                            imageBackUrl,
                            title,
                            __typename: type,
                          } = result;

                          const expired = moment().isAfter(moment(expiresOn));
                          // url = generateContentUrl(result)
                          const resultClassname = isPDF(imageUrl)
                            ? 'result-cell-breakable'
                            : 'result-cell';
                          return (
                            <li
                              className={
                                summary
                                  ? `${resultClassname} summary`
                                  : resultClassname
                              }
                              key={`${type}-${id}`}
                            >
                              <div className="top-section">
                                <div className="flex-frame column">
                                  {summary && imageUrl ? (
                                    <a
                                      href={imageUrl}
                                      rel="noopener noreferrer"
                                      target="_blank"
                                    >
                                      <div className="cell-label bold">
                                        {title}
                                      </div>
                                    </a>
                                  ) : (
                                    <div className="cell-label bold">
                                      {title}
                                    </div>
                                  )}
                                </div>

                                <div className="flex-frame score">
                                  {expired ? (
                                    <div className="cell-label margin red">
                                      Expired
                                    </div>
                                  ) : (
                                    <div className="cell-label margin">
                                      Expires
                                    </div>
                                  )}
                                  <div className="cell-label date">{`- ${moment(
                                    expiresOn,
                                  ).format('MMM DD, YYYY')}`}</div>
                                </div>
                              </div>

                              <div
                                className={
                                  !summary && imageUrl
                                    ? `result-image-frame ${browser.includes('chrome')
                                      ? 'chrome'
                                      : 'safari'
                                    }`
                                    : 'hide'
                                }
                              >
                                <DocumentDisplay
                                  docUrl={imageUrl}
                                  title={title}
                                />
                              </div>

                              <div
                                className={
                                  !summary && imageBackUrl
                                    ? `result-image-frame ${browser.includes('chrome')
                                      ? 'chrome'
                                      : 'safari'
                                    }`
                                    : 'hide'
                                }
                              >
                                <DocumentDisplay
                                  docUrl={imageBackUrl}
                                  title={title}
                                />
                              </div>
                            </li>
                          );
                        },
                      )}
                    </ul>
                  </div>

                  {/* tests */}
                  <div
                    className={tests.length === 0 ? 'hide' : 'results-frame'}
                  >
                    <div className="header">Tests</div>
                    <ul className="results-list-view">
                      {tests.map((result) => {
                        const {
                          answers,
                          endedAt,
                          id,
                          passed,
                          score,
                          __typename: type,
                          test,
                          title,
                        } = result;
                        const incorrect = answers.filter((a) => !a.isCorrect);
                        const isRule1 = test.desc
                          ? test.desc.includes('__rule-1__')
                          : false;

                        return (
                          <li
                            className={
                              summary ? 'result-cell summary' : 'result-cell'
                            }
                            key={`${type}-${id}`}
                          >
                            <div className="top-section">
                              <div className="flex-frame column">
                                <div className="cell-label bold">{title}</div>
                                <div className="cell-label">
                                  {moment(endedAt).format('MMM D, YYYY')}
                                </div>
                              </div>
                              <div className="flex-frame column score">
                                <div className="cell-label bold score">{`${score}%`}</div>
                                <div
                                  className={`cell-label score ${passed ? 'green' : 'orange'
                                    }`}
                                >
                                  {passed ? 'Pass' : 'Did Not Pass'}
                                </div>
                              </div>
                            </div>
                            {isRule1 ? (
                              <div className="empty-frame" />
                            ) : (
                              <div
                                className={
                                  incorrect.length === 0
                                    ? 'hide'
                                    : 'result-details-frame'
                                }
                              >
                                <div className="header">{`INCORRECTLY ANSWERED QUESTIONS (${incorrect.length})`}</div>
                                <ul className="answers-list-view">
                                  {incorrect.map((answer, i) => {
                                    const { id, question, value } = answer;
                                    const regex = /<\/?[a-z][\s\S]*>/i;
                                    const isHTML = regex.test(question.title);
                                    return (
                                      <li className="answer-cell" key={id}>
                                        <div className="cell-label bold">{`Q${i + 1
                                          }`}</div>
                                        <div className="cell-label">
                                          {isHTML
                                            ? renderHTML(question.title)
                                            : question.title}
                                        </div>
                                        <div className="cell-label una">
                                          {value}
                                        </div>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  {/* checklists */}
                  <div
                    className={
                      checklists.length === 0 ? 'hide' : 'results-frame'
                    }
                  >
                    <div className="header">Checklists</div>
                    <ul className="results-list-view">
                      {checklists.map((result) => {
                        const {
                          answers,
                          endedAt,
                          id,
                          __typename: type,
                          title,
                        } = result;

                        const answersHash = {};

                        answers.forEach(
                          (answer) =>
                          (answersHash[answer.question.id] = parseFloat(
                            answer.value,
                          )),
                        );

                        const url = generateContentUrl(result);
                        const checklistClone = parseChecklist(
                          result.checklist,
                          answersHash,
                        );

                        return (
                          <li
                            className={
                              summary
                                ? 'result-cell-breakable summary'
                                : 'result-cell-breakable'
                            }
                            key={`${type}-${id}`}
                          >
                            <div className="top-section">
                              <div className="flex-frame column">
                                {summary ? (
                                  <a
                                    href={url}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    <div className="cell-label bold">
                                      {title}
                                    </div>
                                  </a>
                                ) : (
                                  <div className="cell-label bold">{title}</div>
                                )}

                                <div className="cell-label">
                                  {moment(endedAt).format('MMM D, YYYY')}
                                </div>
                              </div>

                              <div className="flex-frame">
                                <div className="cell-label score">
                                  {checklistClone.avgQuestionScore.toFixed(1)}
                                </div>
                                <ProgressBar
                                  pct={checklistClone.checklistPct}
                                />
                              </div>
                            </div>

                            <div className="result-details-frame">
                              <ul className="categories-list-view">
                                {checklistClone.categories.map((category) => {
                                  const { id, sections, score, title } =
                                    category;
                                  return (
                                    <li className="category-cell" key={id}>
                                      <div className="flex-frame">
                                        <div className="cell-label bold">
                                          {title}
                                        </div>
                                        <div className="cell-label">
                                          {score}
                                        </div>
                                      </div>

                                      <ul className="sections-list-view">
                                        {sections.map((section) => {
                                          const {
                                            id,
                                            questions,
                                            score,
                                            title,
                                          } = section;
                                          return (
                                            <li
                                              className="section-cell"
                                              key={id}
                                            >
                                              <div className="flex-frame">
                                                <div className="cell-label">
                                                  {title}
                                                </div>
                                                <div className="cell-label">
                                                  {score}
                                                </div>
                                              </div>

                                              <ul className="questions-list-view">
                                                {_.orderBy(
                                                  questions,
                                                  'value',
                                                ).map((question) => {
                                                  const { id, title } =
                                                    question;

                                                  const answer = parseInt(
                                                    answersHash[id],
                                                    10,
                                                  );

                                                  return (
                                                    <li
                                                      className="question-cell"
                                                      key={id}
                                                    >
                                                      <div className="question">
                                                        {title}
                                                      </div>
                                                      <div
                                                        className={`answer ${color(
                                                          answer,
                                                        )}`}
                                                      >
                                                        {answer}
                                                      </div>
                                                    </li>
                                                  );
                                                })}
                                              </ul>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  {/* mandatories */}
                  <div
                    className={courses.length === 0 ? 'hide' : 'results-frame'}
                  >
                    <div className="header">Mandatories</div>
                    <ul className="results-list-view">
                      {courses.map((result) => {
                        const {
                          answers,
                          endedAt,
                          id,
                          passed,
                          score,
                          __typename: type,
                          title,
                        } = result;
                        const incorrect = answers.filter((a) => !a.isCorrect);

                        return (
                          <li
                            className={
                              summary ? 'result-cell summary' : 'result-cell'
                            }
                            key={`${type}-${id}`}
                          >
                            <div className="top-section">
                              <div className="flex-frame column">
                                <div className="cell-label bold">{title}</div>
                                <div className="cell-label">
                                  {moment(endedAt).format('MMM D, YYYY')}
                                </div>
                              </div>
                              <div className="flex-frame column score">
                                <div className="cell-label bold score">{`${score}%`}</div>
                                <div
                                  className={`cell-label score ${passed ? 'green' : 'orange'
                                    }`}
                                >
                                  {passed ? 'Pass' : 'Did Not Pass'}
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                incorrect.length === 0
                                  ? 'hide'
                                  : 'result-details-frame'
                              }
                            >
                              <div className="header">{`INCORRECTLY ANSWERED QUESTIONS (${incorrect.length})`}</div>
                              <ul className="answers-list-view">
                                {incorrect.map((answer, i) => {
                                  const { id, question, value } = answer;
                                  return (
                                    <li className="answer-cell" key={id}>
                                      <div className="cell-label bold">{`Q${i + 1
                                        }`}</div>
                                      <div className="cell-label">
                                        {question.title}
                                      </div>
                                      <div className="cell-label una">
                                        {value}
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  {/* document */}
                  <div
                    className={
                      documents.length === 0 ? 'hide' : 'results-frame'
                    }
                  >
                    <div className="header">{STRINGS.document}</div>

                    <ul className="results-list-view">
                      {documents.map((result) => {
                        const {
                          expiresOn,
                          id,
                          imageUrl,
                          imageBackUrl,
                          issuedOn,
                          kind,
                          title,
                          __typename: type,
                        } = result;

                        const date =
                          kind === 'drivers_license' || kind === 'ppd'
                            ? expiresOn
                            : issuedOn;
                        const resultClassname = isPDF(imageUrl)
                          ? 'result-cell-breakable'
                          : 'result-cell';
                        return (
                          <li
                            className={
                              summary
                                ? `${resultClassname} summary`
                                : resultClassname
                            }
                            key={`${type}-${id}`}
                          >
                            <div className="top-section">
                              <div className="flex-frame column">
                                <div className="cell-label bold">{title}</div>
                              </div>
                              <div className="cell-label date">
                                {moment(date).format('MMM DD, YYYY')}
                              </div>
                            </div>

                            {imageUrl ? (
                              summary ? (
                                <div className="top-section">
                                  <a
                                    className="attachment-link"
                                    href={imageUrl}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    <div className="cell-label bold">{`${STRINGS.attachment} 1`}</div>
                                  </a>
                                </div>
                              ) : (
                                <div
                                  className={
                                    !summary && imageUrl
                                      ? `result-image-frame ${browser.includes('chrome')
                                        ? 'chrome'
                                        : 'safari'
                                      }`
                                      : 'hide'
                                  }
                                >
                                  <DocumentDisplay
                                    docUrl={imageUrl}
                                    title={title}
                                  />
                                </div>
                              )
                            ) : null}

                            {imageBackUrl ? (
                              summary ? (
                                <div className="top-section">
                                  <a
                                    className="attachment-link"
                                    href={imageBackUrl}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    <div className="cell-label bold">{`${STRINGS.attachment} 2`}</div>
                                  </a>
                                </div>
                              ) : (
                                <div
                                  className={
                                    !summary && imageBackUrl
                                      ? `result-image-frame ${browser.includes('chrome')
                                        ? 'chrome'
                                        : 'safari'
                                      }`
                                      : 'hide'
                                  }
                                >
                                  <DocumentDisplay
                                    docUrl={imageBackUrl}
                                    title={`${title} back`}
                                  />
                                </div>
                              )
                            ) : null}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  {/* end */}
                </div>
              </div>
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td>
              <div className="footer-space" />
            </td>
          </tr>
        </tfoot>
      </table>

      <div className="page-footer">
        <div className="centered-frame">
          <div className="flex-frame">
            <div className="footer-label">Powered by</div>
            <img src={TestIcon} alt="test-icon" />
          </div>
          <a
            className="footer-link"
            href="https://www.unahealth.co/una-test"
            target="_blank"
            rel="noopener noreferrer"
          >
            unahealth.co/una-test
          </a>
        </div>
      </div>
    </div>
  );
};

export default PacketSelect;
