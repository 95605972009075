import React from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';

import _ from 'lodash';

import CourseQuery from '../../graphql/queries/Chapter';
import CourseChapterUpdateMutation from '../../graphql/mutations/Courses/Chapter/Update';
import CourseQuestionRemoveMutation from '../../graphql/mutations/Courses/Question/Remove';
import CourseQuestionUpdateMutation from '../../graphql/mutations/Courses/Question/Update';

import Chapter from '../../components/Chapter';

import LoadingPane from '../../components/Shared/LoadingPane';
import { withRouter } from '../withRouter';

class ChapterContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      onload: true,
    };
  }

  componentDidMount() {
    const {
      courseQuery: { loading, refetch },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      refetch().then(() => this.setup());
    } else {
      this.setup();
    }

    window.addEventListener('keydown', this.handleKeyPress);
  }

  componentDidUpdate() {
    this.setup();
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress);
  }

  handleKeyPress = (e) => {
    // shift + enter
    if (e.shiftKey && e.keyCode === 13) {
      this.save(() => this.goToQuestionRoute());
    }
  };

  setup = () => {
    const {
      courseQuery: { loading, course },
      location,
      navigate,
      params,
      context: { user },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      const chapter = course.chapters.find((c) => c.id === params.chapter);
      const org = location.state ? location.state.org : null;
      const role = user.roles.find((r) => r.org.id === org);
      const admin = role ? role.name === 'corporate' : false;

      if (course && course.kind === 'global' && !admin) {
        navigate(`/error${org ? `?org=${org}` : ''}`);
      } else {
        this.setState({
          ...chapter,
          content: chapter.content || '',
          course,
          onload: false,
          questions: _.sortBy(chapter.questions, ['rank']),
        });
      }
    }
  };

  back = () => {
    const { location, navigate } = this.props;

    if (location.state) {
      const { previous, org } = location.state;

      navigate(previous, {
        state: {
          previous: `/organizations/${org}/mandatories`,
          org,
        },
      });
    } else {
      navigate(`/error`);
    }
  };

  deleteQuestion = (question, i) => {
    const { courseQuestionRemoveMutation } = this.props;

    const arr = this.state.questions;

    this.setState(
      {
        loading: true,
      },
      () => {
        courseQuestionRemoveMutation({
          variables: {
            input: {
              id: question.id,
            },
          },
        }).then((response) => {
          const {
            data: {
              courseQuestionRemove: { errors },
            },
          } = response;

          if (errors) {
            window.alert(errors[0].message);
          } else {
            this.handleChange('questions', [
              ...arr.slice(0, i),
              ...arr.slice(i + 1, arr.length),
            ]);
          }
          this.setState({
            loading: false,
          });
        });
      },
    );
  };

  goToCourseRoute = () => {
    this.back();
  };

  goToQuestionRoute = (id) => {
    const { location, navigate, params } = this.props;

    navigate(
      `/courses/${params.id}/chapters/${params.chapter}/questions/${
        id || 'create'
      }`,
      { state: { previous: location.pathname, org: location.state.org } },
    );
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  save = (success) => {
    const { courseChapterUpdateMutation } = this.props;
    const { id, content } = this.state;

    this.setState(
      {
        loading: true,
      },
      () => {
        courseChapterUpdateMutation({
          variables: {
            input: {
              id,
              content,
            },
          },
        }).then((response) => {
          const {
            data: {
              courseChapterUpdate: { errors },
            },
          } = response;

          if (errors) {
            this.handleChange('loading', false);

            window.alert(errors[0].message);
          } else if (success) {
            success();
          } else {
            this.updateQuestions(this.state.questions, 0);
          }
        });
      },
    );
  };

  updateQuestions = (arr, i) => {
    if (i === arr.length) {
      this.goToCourseRoute();
    } else {
      const { courseQuestionUpdateMutation } = this.props;

      courseQuestionUpdateMutation({
        variables: {
          input: {
            id: arr[i].id,
            rank: i + 1,
          },
        },
      }).then(() => this.updateQuestions(arr, i + 1));
    }
  };

  uploadFile = async (success, fail, blobInfo) => {
    const xhr = new XMLHttpRequest();
    const fileName = blobInfo.filename();
    const config = {
      type: `image/${fileName.split('.')[1]}`,
    };
    const file = new File([blobInfo.blob()], fileName, config);
    const formData = new FormData();

    console.log(blobInfo.blob());

    formData.append('file', file);

    xhr.open('POST', process.env.REACT_APP_ATTACHMENT_URL);

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        const response = JSON.parse(xhr.response);
        const url = response.metadata.cloudinary.secure_url;

        success(url);
      }
    };

    xhr.send(formData);
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <Chapter
        {...this.props}
        deleteQuestion={this.deleteQuestion}
        goToCourseRoute={this.goToCourseRoute}
        goToQuestionRoute={this.goToQuestionRoute}
        handleChange={this.handleChange}
        save={this.save}
        state={this.state}
        uploadFile={this.uploadFile}
      />
    );
  }
}

export default compose(
  withRouter,
  graphql(CourseQuery, {
    name: 'courseQuery',
    options: (props) => ({
      variables: {
        id: props.params.id,
      },
    }),
  }),
  graphql(CourseChapterUpdateMutation, { name: 'courseChapterUpdateMutation' }),
  graphql(CourseQuestionRemoveMutation, {
    name: 'courseQuestionRemoveMutation',
  }),
  graphql(CourseQuestionUpdateMutation, {
    name: 'courseQuestionUpdateMutation',
  }),
)(ChapterContainer);
