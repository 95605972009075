import { gql } from 'apollo-boost';

const ChecklistQuery = gql`
  query ChecklistQuery($id: ID) {
    checklist(id: $id) {
      id
      categories {
        id
        title
        rank
        sections {
          id
          title
          rank
          questions {
            id
            rank
            scoreLabels
            scoreValues
            title
          }
        }
      }
      org {
        id
      }
      color
      desc
      durationMins
      kind
      status
      title
      updatedAt
      checklistType
    }
  }
`;

export default ChecklistQuery;
