import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { useParams, useNavigate } from 'react-router-dom';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import axios from 'axios';

import Cookie from 'js-cookie';

import _ from 'lodash';
import OrgQuery from '../../graphql/queries/Org/Users';
import UserRoleRemoveMutation from '../../graphql/mutations/User/Role/Remove';
import UserUpdateMutation from '../../graphql/mutations/User/Update';

import LoadingPane from '../Shared/LoadingPane';
import ModalPane from '../Shared/ModalPane';
import SearchField from '../Shared/SearchField';

import { roles as roleHierarchy, roleNames } from '../../utils/Constants';

const defaultColor = '#FF5A5F';
const defaultSecondaryColor = '#008489';
const refs = {};
const role = 'all';
const submessage = '';

const DashboardRoster = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const { user } = props;
  const viewerRoles = user.roles;
  const viewerRole = viewerRoles?.find((r) => r.org.id === params.id);

  const actionableRoles =
    viewerRole.name === 'corporate'
      ? roleHierarchy(viewerRole.name).map((o) => o.value)
      : roleHierarchy(viewerRole.name)
          .map((o) => o.value)
          .slice(1); //slicing the array as actionable are lower only
  const viewableRoles =
    props.state.role.name === 'corporate'
      ? roleHierarchy(props.state.role.name).map((o) => o.value)
      : ['super_admin', 'admin', 'recruiter', 'nurse'];

  const [variable, setVariable] = useState({
    id: params.id,
    roles: viewableRoles,
    search: null,
    first: 100,
  });
  const [sortKey, setSortKey] = useState(['first_name']);
  const [sortIndex, setSortIndex] = useState(['asc']);
  const [isModal, setIsModal] = useState(false);
  const [modalMessage, setmodalMessage] = useState('');
  const [isDelete, setIsDelete] = useState(false);
  const [actOnUser, setActOnUser] = useState();
  const [loadedData, setloadedData] = useState();

  const [userRoleRemoveMutation] = useMutation(UserRoleRemoveMutation, {
    refetchQueries: [OrgQuery],
  });
  const [userUpdateMutation] = useMutation(UserUpdateMutation);

  const sortUsers = (unsorted) => {
    return _.orderBy(unsorted, sortKey, sortIndex);
  };

  const filterByOrg = (arr) => {
    return arr.filter((item) => {
      const displayedOrg = parseInt(params.id, 10);
      if (
        !item.orgId ||
        displayedOrg === 1 ||
        displayedOrg === parseInt(item.orgId, 10)
      ) {
        return true;
      }

      return false;
    });
  };

  const filter = (arr, statusInput, inequal) => {
    return arr
      ? arr.filter((o) =>
          inequal ? o.status !== statusInput : o.status === statusInput,
        )
      : [];
  };

  const setupUsers = (startUsers) => {
    return startUsers.map((startUser) => {
      const endUser = { ...startUser };
      endUser.orgRole = endUser.roles?.find((r) => r.org.id === params.id);
      endUser.checklistsTaken = filterByOrg(startUser.checklistsTaken);
      endUser.testsTaken = filterByOrg(startUser.testsTaken);
      endUser.coursesTaken = filterByOrg(startUser.coursesTaken);
      endUser.checklistsFinished = filter(
        endUser.checklistsTaken,
        'finished',
      ).length;
      endUser.checklistsNotFinished =
        endUser.checklistsTaken.length - endUser.checklistsFinished;
      endUser.testsFinished = filter(endUser.testsTaken, 'finished').length;
      endUser.testsNotFinished =
        endUser.testsTaken.length - endUser.testsFinished;
      endUser.coursesFinished = filter(endUser.coursesTaken, 'finished').length;
      endUser.coursesNotFinished =
        endUser.coursesTaken.length - endUser.coursesFinished;
      if (
        endUser.testsFinished > 0 ||
        endUser.checklistsFinished > 0 ||
        endUser.coursesFinished > 0 ||
        endUser.orgRole.name !== 'nurse'
      ) {
        endUser.status = 'active';
      } else if (
        endUser.testsTaken.length +
          endUser.checklistsTaken.length +
          endUser.coursesTaken.length ===
        0
      ) {
        // nothing has been assigned
        endUser.status = 'inactive';
      } else {
        endUser.status = 'pending';
      }

      return endUser;
    });
  };

  let users = [];
  const { loading, data, fetchMore } = useQuery(OrgQuery, {
    variables: variable,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setloadedData(data);
  }, [loading, data]);

  const target = React.createRef();
  let userArray = [];
  let brandColor = null;
  let secondaryColor = null;
  let orgName = '';
  let orgActiveUsers = 0;
  let orgPendingUsers = 0;
  let orgInactiveUsers = 0;
  let orgLogo = null;

  if (loadedData) {
    users = loadedData?.org.users;
    userArray = sortUsers(setupUsers(users?.edges.map((edge) => edge.node)));
    brandColor = loadedData.org.brandColor;
    secondaryColor = loadedData.org.secondaryColor;
    orgName = loadedData.org.title;
    orgActiveUsers = loadedData.org.activeUserCount || 0;
    orgPendingUsers = loadedData.org.pendingUserCount || 0;
    orgInactiveUsers = loadedData.org.inactiveUserCount || 0;
    orgLogo = loadedData.org.attachment;
  }

  // const goToCSVRoute = () => {
  //   navigate(`/organizations/${params.id}/roster/upload`);
  // };

  const goToRosterRoute = (profile) => {
    navigate(`/organizations/${params.id}/roster/${profile}`);
  };

  const goToUserRoute = (id) => {
    navigate(`/organizations/${params.id}/users/${id || 'create'}`);
  };
  const handleScroll = (e) => {
    // const node = e.target;
    // const isBottom =
    //   node.scrollTop >= node.scrollHeight - node.offsetHeight - 1000;

    if (loadedData.org.users.pageInfo.hasNextPage) {
      _.throttle(async () => {
        await fetchMore(
          {
            variables: { after: loadedData.org.users.pageInfo.endCursor },
          },
          100,
        );
      })();
    }
  };

  const handleSearch = (e) => {
    const search = e.target.value.toLowerCase() || null;
    const newVar = { ...variable };
    newVar.search = search;
    setVariable(newVar);
  };

  const handleRole = (e) => {
    const roleSelect = e.target.value;
    const roleVariable = roleSelect === 'all' ? viewableRoles : [roleSelect];
    const newVar = { ...variable };
    newVar.roles = roleVariable;
    setVariable(newVar);
  };

  const handleSort = (key) => {
    if (key !== sortKey) {
      setSortKey([key]);
    }
    const ind = sortIndex[0] === 'asc' ? ['desc'] : ['asc'];
    setSortIndex(ind);
  };

  const handleModal = (inputUser, del) => {
    document.body.click();
    setIsDelete(del);

    const msg = del
      ? `Are you sure you want to remove ${inputUser.firstName} ${inputUser.lastName} from ${orgName}?`
      : `Are you sure you want to resend credentials? This will reset and override ${inputUser.firstName}'s current password.`;

    setmodalMessage(msg);
    setIsModal(true);
    setActOnUser(inputUser);
  };
  const userUpdate = () => {
    const password = 'Welcome2020';

    userUpdateMutation({
      variables: {
        input: {
          id: actOnUser.id,
          password,
        },
      },
    }).then((response) => {
      const {
        data: {
          userUpdate: { errors, success },
        },
      } = response;

      if (success) {
        setActOnUser(null);
        setIsDelete(false);
        setIsModal(false);
        setmodalMessage('');
      } else {
        window.alert(errors[0].message);
      }
    });
  };

  const sendEmail = () => {
    // const { attachment, brandColor, title, user } = this.state;

    const url = `${process.env.REACT_APP_PROXY_URL}/send`;
    const actOnUserRole = actOnUser.roles.find((r) => r.org.id === params.id);
    const defaultLogo =
      'https://res.cloudinary.com/inewton/image/upload/v1/una-app/cache/e80cad79f0f6e17fd3c8254c763c2b8d.png';
    //const defaultColor = '#FF5A5F';
    const isAdmin = actOnUserRole.name !== 'nurse';
    const emailUrl = isAdmin
      ? process.env.REACT_APP_UNA_ADMIN_URL
      : process.env.REACT_APP_UNA_TEST_URL;
    const emailData = {
      dynamicTemplateData: {
        email: actOnUser.email,
        name: orgName,
        url: `${emailUrl}/getting_started${`?e=${encodeURIComponent(
          actOnUser.email,
        )}&p=Welcome2020`}`,
        color: orgLogo ? brandColor : defaultColor,
        logo: orgLogo ? orgLogo.url : defaultLogo,
      },
      environment: 'production',
      recipient: actOnUser.email,
      templateId: 'd-62dcb32d7374487a8aadc811cc4e14d7',
    };
    const headers = {
      Authorization: Cookie.get(`${global.COOKIE_NAME}-token`),
    };

    axios.post(url, emailData, { headers }).then(() => {
      userUpdate();
    });
  };

  const userRoleRemove = () => {
    const actOnRole = actOnUser.roles.find((r) => r.org.id === params.id);

    userRoleRemoveMutation({
      variables: {
        input: {
          id: actOnUser.id,
          role: actOnRole.name,
          orgId: actOnRole.org.id,
        },
      },
    }).then((response) => {
      const {
        data: {
          userRoleRemove: { errors, success },
        },
      } = response;

      if (success) {
        setIsDelete(false);
        setActOnUser(null);
        setIsModal(false);
        setmodalMessage('');
      } else {
        window.alert(errors[0].message);
      }
    });
  };

  const handleModalAction = () => {
    if (isDelete) userRoleRemove();
    else sendEmail();
  };

  const rolesForFilter =
    viewerRole.name === 'corporate'
      ? roleHierarchy('corporate')
      : roleHierarchy('super_admin');

  const useOrgTotals = false;
  const activeUsers = useOrgTotals
    ? orgActiveUsers
    : userArray.filter((o) => o.status === 'active').length;
  const pendingUsers = useOrgTotals
    ? orgPendingUsers
    : userArray.filter((o) => o.status === 'pending').length;
  const inactiveUsers = useOrgTotals
    ? orgInactiveUsers
    : userArray.filter((o) => o.status === 'inactive').length;

  const totalUsers = activeUsers + pendingUsers + inactiveUsers;

  return (
    <div className="dashboard-roster-view full-screen-view">
      {loading && !loadedData ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      <>
        <div className="stats-frame">
          <div className="centered-frame">
            <div className="stat-frame">
              <div className="header">TOTAL</div>
              <div className="stat-label black">{totalUsers}</div>
            </div>

            <div className="stat-frame middle">
              <div className="header">ACTIVE</div>
              <div
                className="stat-label"
                style={{
                  color:
                    activeUsers > 0
                      ? secondaryColor
                        ? secondaryColor
                        : defaultSecondaryColor
                      : '#919191',
                }}
              >
                {activeUsers}
              </div>
            </div>

            <div className="stat-frame middle">
              <div className="header">PENDING</div>
              <div
                className="stat-label"
                style={{
                  color:
                    pendingUsers > 0
                      ? brandColor
                        ? brandColor
                        : defaultColor
                      : '#919191',
                }}
              >
                {pendingUsers}
              </div>
            </div>

            <div className="stat-frame middle">
              <div className="header">INACTIVE</div>
              <div className="stat-label">{inactiveUsers}</div>
            </div>
          </div>
        </div>

        <div className="toolbar-frame">
          <div className="centered-frame">
            <div className="left-section">
              <div className="role-menu-frame">
                <div className="menu-label">Role:</div>

                <select
                  className="role-menu"
                  defaultValue={role}
                  onChange={handleRole}
                >
                  {[
                    <option key={-1} value={-1} disabled>
                      Select Role
                    </option>,
                    <option key={0} value="all">
                      All
                    </option>,
                    ...rolesForFilter.map((roleObj, i) => (
                      <option key={i + 1} value={roleObj.value}>
                        {roleObj.name}
                      </option>
                    )),
                  ]}
                </select>

                <div className="down-arrow-icon">
                  <img
                    src={require('../../theme/images/down-arrow-2x-copy.png')}
                    alt="down-arrow-icon"
                  />
                </div>
              </div>

              <SearchField onChange={handleSearch} placeholder="Search Users" />
            </div>

            <div className="button-frame">
              {/* <button
                className="upload-button"
                style={{ color: brandColor || defaultColor }}
                onClick={goToCSVRoute}
              >
                + Upload CSV
              </button> */}
              <button
                className="create-button"
                style={{ color: brandColor || defaultColor }}
                onClick={() => goToUserRoute()}
              >
                + Create New Member
              </button>
            </div>
          </div>
        </div>

        <div className="table-headers">
          <div className="centered-frame">
            <div className="table-header name">Name</div>
            <div className="table-header type">Type</div>
            <div className="table-header email">Email</div>
            <div
              className="table-header sortable"
              onClick={() => handleSort('checklistsNotFinished')}
            >
              Skills Checklist
            </div>
            <div
              className="table-header sortable"
              onClick={() => handleSort('testsNotFinished')}
            >
              Tests
            </div>
            <div
              className="table-header sortable"
              onClick={() => handleSort('coursesNotFinished')}
            >
              Mandatories
            </div>
            <div
              className="table-header status sortable"
              onClick={() => handleSort('status')}
            >
              Status
            </div>
          </div>
        </div>

        <ul className="roster-list-view scroll" onScroll={handleScroll}>
          {userArray.length > 0 ? (
            userArray.map((userObj, index) => {
              const {
                attachment,
                id,
                email,
                firstName,
                lastName,
                roles: userRoles,
                status,
                checklistsFinished,
                checklistsNotFinished,
                testsFinished,
                testsNotFinished,
                coursesFinished,
                coursesNotFinished,
              } = userObj;

              const profileImage = attachment;
              const userRole = userRoles?.find((r) => r.org.id === params.id);

              return (
                <li
                  className="roster-cell"
                  key={`${index}-${id}`}
                  ref={(ref) => (refs[id] = ref)}
                >
                  <div className="centered-frame">
                    <div className="name">
                      <div className="profile-picture">
                        {profileImage ? (
                          <img src={profileImage.url} alt="profile-icon" />
                        ) : (
                          <img
                            src={require('../../theme/images/profile-icon-2x.png')}
                            alt="profile-icon"
                          />
                        )}
                      </div>

                      <div className="cell-label bold">{`${firstName} ${lastName}`}</div>
                    </div>

                    <div className="cell-label type">
                      {roleNames[userRole.name] || _.startCase(userRole.name)}
                    </div>

                    <div className="cell-label email">{email}</div>

                    <div className="flex-frame">
                      <div className="finished">{checklistsFinished}</div>

                      <div
                        className={
                          checklistsNotFinished > 0
                            ? 'flex-frame progress'
                            : 'hide'
                        }
                      >
                        <div className="seperator">-</div>
                        <div
                          className="pending-label"
                          style={{ color: brandColor || defaultColor }}
                        >{`(${checklistsNotFinished})`}</div>
                      </div>
                    </div>

                    <div className="flex-frame">
                      <div className="finished">{testsFinished}</div>

                      <div
                        className={
                          testsNotFinished > 0 ? 'flex-frame progress' : 'hide'
                        }
                      >
                        <div className="seperator">-</div>
                        <div
                          className="pending-label"
                          style={{ color: brandColor || defaultColor }}
                        >{`(${testsNotFinished})`}</div>
                      </div>
                    </div>

                    <div className="flex-frame">
                      <div className="finished">{coursesFinished}</div>

                      <div
                        className={
                          coursesNotFinished > 0
                            ? 'flex-frame progress'
                            : 'hide'
                        }
                      >
                        <div className="seperator">-</div>
                        <div
                          className="pending-label"
                          style={{ color: brandColor || defaultColor }}
                        >{`(${coursesNotFinished})`}</div>
                      </div>
                    </div>

                    <div
                      className="cell-label status"
                      style={{
                        color:
                          status === 'active'
                            ? secondaryColor || defaultSecondaryColor
                            : status === 'inactive'
                            ? '#919191'
                            : brandColor || defaultColor,
                      }}
                    >
                      {status}
                    </div>

                    <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      rootClose
                      overlay={
                        <Popover id="menu-pane" className="menu-pane">
                          <div
                            className="menu-content-view"
                            style={{ padding: '5px 10px' }}
                          >
                            <button
                              className="menu-button teal"
                              onClick={() => goToRosterRoute(id)}
                            >
                              View Profile
                            </button>

                            <button
                              className="menu-button teal"
                              onClick={() => handleModal(userObj, false)}
                            >
                              Resend Credentials
                            </button>

                            <button
                              className={
                                viewerRole.name === 'corporate'
                                  ? 'menu-button red'
                                  : 'hide'
                              }
                              onClick={() => handleModal(userObj, true)}
                            >
                              Remove
                            </button>
                          </div>
                        </Popover>
                      }
                    >
                      <div className="expander-icon" ref={target}>
                        <img
                          src={require('../../theme/images/expander-icon-grey-2x.png')}
                          alt="expand-icon"
                        />
                      </div>
                    </OverlayTrigger>
                  </div>
                </li>
              );
            })
          ) : (
            <div className="layout-center hint">
              There are no members to display.
            </div>
          )}
        </ul>

        {/* <div className={isPaginate ? 'loading-label primary-color' : 'hide'}>
          Loading...
        </div> */}

        <ModalPane
          content={(hide) => (
            <div>
              <div className="modal-message">{modalMessage}</div>
              <div className="modal-submessage">{submessage}</div>

              <div className="button-frame">
                <button className="modal-button hint" onClick={hide}>
                  Cancel
                </button>

                <button
                  className="modal-button"
                  onClick={() => handleModalAction()}
                  style={{ color: brandColor || defaultColor }}
                >
                  Submit
                </button>
              </div>
            </div>
          )}
          hide={() => setIsModal(false)}
          show={isModal}
        />
      </>
    </div>
  );
};

export default DashboardRoster;
