import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import LoadingPane from '../Shared/LoadingPane';
import Toolbar from '../Shared/Toolbar';

import DragList from '../../containers/Shared/DragList';

const CategoryChecklist = (props) => {
  const {
    addQuestion,
    cancelQuestion,
    deleteQuestion,
    duplicateQuestion,
    saveQuestion,
    addSection,
    cancelSection,
    deleteSection,
    saveSection,
    goToChecklistRoute,
    handleChange,
    handleScroll,
    handleSectionChange,
    save,
    state,
  } = props;
  const { checklist, loading, question, sections, section, title } = state;

  return (
    <div className="category-checklist-view full-screen-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      <Toolbar
        cancel={goToChecklistRoute}
        save={save}
        subTitle={`Skills Checklist - ${checklist.title}`}
        title={title}
      />

      <div className="content-frame scroll" onScroll={handleScroll}>
        <div className="centered-frame">
          {sections.length === 0 ? (
            <div className="layout-center">
              <div className="empty-section-hint hint">
                No sections have been added to this category.
              </div>
              <div className="empty-section-hint hint">
                {' '}
                Click the + button on the bottom right of the page.
              </div>
            </div>
          ) : (
            <ul className="sections-list-view">
              {sections.map((s, i) => {
                const { id, questions, title: sTitle } = s;

                return section.id === id ? (
                  <div className="section-input-frame" key={id}>
                    <input
                      autoFocus
                      className="section-title"
                      defaultValue={section.title}
                      onChange={(e) =>
                        handleChange('section.title', e.target.value)
                      }
                      placeholder="Enter section title"
                    />

                    <button
                      className="section-button red"
                      onClick={cancelSection}
                    >
                      Cancel
                    </button>
                    <button
                      className={`section-button ${section.title ? 'teal' : 'hint'
                        }`}
                      onClick={saveSection}
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div className="section-frame" key={id}>
                    <div className="section-header-frame">
                      <div className="checklist-header-frame">
                        <div
                          className="section-header"
                          onClick={() => handleChange('section', { ...s })}
                        >{`${sTitle} (${questions.length})`}</div>

                        <button
                          className="delete-button"
                          onClick={() => deleteSection(i)}
                        >
                          Delete
                        </button>
                      </div>

                      <button
                        className="section-button"
                        onClick={() => addQuestion(i)}
                      >
                        + Add Item
                      </button>
                    </div>

                    {questions.length === 0 ? (
                      <div className="empty-hint hint">
                        No items have been added to this section.
                      </div>
                    ) : (
                      <div className="questions-table-view">
                        <div className="table-headers">
                          <div className="table-header title">
                            Question Title
                          </div>
                        </div>

                        <DragList
                          onChange={(arr) => handleSectionChange(i, arr)}
                          data={questions.map((q, qI) => {
                            const { id: qId, title: qTitle } = q;
                            const last = qI === questions.length - 1;

                            return {
                              ...q,
                              content:
                                question.id === qId ? (
                                  <div
                                    className="question-cell edit"
                                    key={i}
                                    style={{ borderWidth: last ? 0 : 1 }}
                                  >
                                    <input
                                      autoFocus
                                      className="question-title"
                                      defaultValue={qTitle}
                                      onChange={(e) =>
                                        handleChange(
                                          'question.title',
                                          e.target.value,
                                        )
                                      }
                                      placeholder="Enter item title"
                                    />

                                    <button
                                      className="question-button red"
                                      onClick={cancelQuestion}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      className="question-button teal"
                                      onClick={() => saveQuestion(s, i, qI)}
                                    >
                                      Save
                                    </button>
                                  </div>
                                ) : (
                                  <div
                                    className="question-cell"
                                    key={qId}
                                    style={{ borderWidth: last ? 0 : 1 }}
                                  >
                                    <div className="cell-content-frame">
                                      <div className="drag-icon">
                                        <img
                                          draggable={false}
                                          src={require('../../theme/images/hashes.png')}
                                          alt="hashes-icon"
                                        />
                                      </div>

                                      <div className="cell-label title">
                                        {qTitle}
                                      </div>
                                    </div>

                                    <OverlayTrigger
                                      trigger="click"
                                      placement="bottom"
                                      rootClose
                                      overlay={
                                        <Popover
                                          id="menu-pane"
                                          className="menu-pane category"
                                        >
                                          <div
                                            className="menu-content-view"
                                            style={{ padding: '5px 0px' }}
                                          >
                                            <button
                                              className="menu-button teal"
                                              onClick={() => {
                                                handleChange('i', i);
                                                handleChange('question', {
                                                  ...q,
                                                });
                                              }}
                                            >
                                              Edit Title
                                            </button>
                                            <button
                                              className="menu-button teal"
                                              onClick={() =>
                                                duplicateQuestion(s, q)
                                              }
                                            >
                                              Duplicate
                                            </button>
                                            <button
                                              className="menu-button red"
                                              onClick={() =>
                                                deleteQuestion(s, qId)
                                              }
                                            >
                                              Delete
                                            </button>
                                          </div>
                                        </Popover>
                                      }
                                    >
                                      <div className="expander-icon">
                                        <img
                                          src={require('../../theme/images/expander-icon-grey-2x.png')}
                                          alt="expander-icon"
                                        />
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                ),
                            };
                          })}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </ul>
          )}

          <div className="add-section-button" onClick={addSection}>
            <div>+</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryChecklist;
