import { gql } from 'apollo-boost';

const DocumentUpdateMutation = gql`
  mutation DocumentUpdateMutation($input: DocumentUpdateUpdateInput) {
    documentUpdate(input: $input) {
      errors {
        message
      }
      result {
        id
      }
      success
    }
  }
`;

export default DocumentUpdateMutation;
