import { gql } from 'apollo-boost';

const FeedbackRequestExternalQuery = gql`
query FeedbackRequestExternalQuery($ulid: String) {
  feedbackRequest(ulid: $ulid) {
    id
    ulid
    fname
    lname
    email
    company
    ofactory
    ftype
    parentid
    childlist
    datetime
    managerFeedbackPresent
    requestingUser{
      id
      email
      firstName
      lastName
      feedbackSeekerDetails
    }
  }
}
`;

export default FeedbackRequestExternalQuery;
