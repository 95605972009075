import { gql } from 'apollo-boost';

const TestCategoryCreateMutation = gql`
  mutation TestCategoryCreateMutation($input: TestCategoryCreateInput) {
    testCategoryCreate(input: $input) {
      errors {
        code
        message
      }
      result {
        id
        questions {
          id
        }
        rank
        title
      }
    }
  }
`;

export default TestCategoryCreateMutation;
