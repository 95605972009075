import React from 'react';

const refs = {};

export default class AutoComplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      google: window.google,
    };
  }

  componentDidMount() {
    const autoComplete = new window.google.maps.places.Autocomplete(refs.input);

    autoComplete.addListener('place_changed', () => {
      const place = autoComplete.getPlace();
      const addressParts = place.address_components;
      const city = addressParts.find((o) => o.types.includes('locality'));
      const state = addressParts.find((o) =>
        o.types.includes('administrative_area_level_1'),
      );

      this.props.onChange(place.name, city.short_name, state.short_name);
    });
  }

  render() {
    const { className, placeholder, defaultValue } = this.props;

    return (
      <div className="autocomplete-input">
        <input
          className={className}
          defaultValue={defaultValue}
          placeholder={placeholder || 'Enter location'}
          ref={(ref) => (refs.input = ref)}
        />
      </div>
    );
  }
}
