import { gql } from 'apollo-boost';

const CourseQuestionCreateMutation = gql`
  mutation CourseQuestionCreateMutation(
    $input: CourseQuestionCreateCreateInput
  ) {
    courseQuestionCreate(input: $input) {
      errors {
        code
        message
      }
      result {
        id
        answers
        isRandomized
        rank
        title
        
      }
    }
  }
`;

export default CourseQuestionCreateMutation;
