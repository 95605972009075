import { gql } from 'apollo-boost';

const FeedbackRequestOrgQuery = gql`
  query FeedbackRequestOrgQuery($id: ID!) {
    org(id: $id) {
      id
      brandColor
      status
      secondaryColor

      feedbackRequests(order: "id") {
        nodes {
          id
          ulid
          fname
          lname
          email
          company
          ofactory
          ftype
          parentid
          childlist
          datetime
          org {
            id
          }
        }
      }
    }
  }
`;

export default FeedbackRequestOrgQuery;
