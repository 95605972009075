import { gql } from 'apollo-boost';

const SignatureDocumentQuery = gql`
  query SignatureDocumentQuery($id: ID) {
        signatureDocument(id: $id) {
            id
            annualExpiration
            description
            status
            title
            url
        }
    }
`;

export default SignatureDocumentQuery;
