import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import _ from 'lodash';
import moment from 'moment';

import LoadingPane from '../../components/Shared/LoadingPane';

import { jobTitles, positionTitles, roles as roleHierarchy } from '../../utils/Constants';

import STRINGS from '../../localization';

const statuses = {
  finished: {
    background: 'white',
    color: '',
    text: 'Complete',
    time: 'endedAt',
  },
  started: {
    background: '#e9f5fb',
    color: 'blue',
    text: 'In Progress',
    time: 'startedAt',
  },
  untaken: {
    background: '#FFF5F6',
    color: 'red',
    text: 'Invite sent',
    time: 'createdAtIso',
  },
};

const timeFormat = 'MMM DD, YYYY (h:mma)';

const Roster = (props) => {
  const {
    goToDocumentRoute,
    goToInviteRoute,
    goToItemRoute,
    goToPacketRoute,
    goToRosterRoute,
    goToUserRoute,
    handleChange,
    params,
    removeItem,
    sendReminder,
    state,
  } = props;
  const {
    id,
    checklistsTaken,
    coursesTaken,
    credentials,
    expand,
    loading,
    me,
    org,
    references,
    roles,
    specialty,
    testsTaken,
    workExperiences,
  } = state;

  const sortedArray = (arr) => {
    const org = parseInt(params.id);
    const filtered = arr.filter((o) => {
      if (!o.orgId || org === 1 || org === parseInt(o.orgId)) {
        return true;
      }

      return false;
    });

    return _.orderBy(
      filtered,
      ['status', 'endedAt', 'startedAt', 'createdAtIso'],
      ['desc', 'desc', 'desc', 'desc'],
    );
  };
  
  const myRole = me.roles.find(r => r.org.id === org.id).name;
  const profileRole = roles.find(r => r.org.id === org.id).name;
  const actionableRoles = 
    myRole === 'corporate'
      ? roleHierarchy(myRole).map((o) => o.value)
      : roleHierarchy(myRole)
        .map((o) => o.value)
        .slice(1); 

  const sortedCheckLists = sortedArray(checklistsTaken);
  const sortedMandatories = sortedArray(coursesTaken);
  const sortedTests = sortedArray(testsTaken);

  return (
    <div className="roster-view full-screen-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      <div className="toolbar">
        <button className="cancel-button" onClick={goToRosterRoute}>
          Back
        </button>

        <div className="page-header">{`${state.firstName} ${state.lastName}`}</div>

        <div className="button-frame">
          <div className="print-packet-button" onClick={goToPacketRoute}>
            <div className="print-icon">
              <img
                src={require('../../theme/images/print-icon-2x.png')}
                alt="print-icon"
              />
            </div>

            <div className="print-label">Print Packet</div>
          </div>
          <button
            className="create-invite-button bg-red"
            onClick={() => sendReminder(true)}
          >
            Send Reminders
          </button>
          <button
            className="create-invite-button bg-red"
            onClick={goToInviteRoute}
          >
            + Create New Invite
          </button>
        </div>
      </div>

      <div className="content-frame scroll">
        <div className="centered-frame">
          <div className="profile-frame">
            <div className="profile-picture">
              <img
                src={
                  state.attachment
                    ? state.attachment.url
                    : require('../../theme/images/profile-icon-3x.png')
                }
                alt="profile-icon"
              />
            </div>

            <div className="profile-details-frame">
              <div className="name">{`${state.firstName} ${state.lastName}`}</div>
              <div className={specialty ? 'specialty' : 'hide'}>
                {specialty ? specialty.name : '-'}
              </div>
              <div className="bio">{state.bio}</div>
            </div>
            
            {actionableRoles.includes(profileRole) ? (
              <button
              className="edit-user-button red"
              onClick={() => goToUserRoute(id)}
            >
              Edit User
            </button>
            ) : null}

            </div>
        
          <div
            className={org.modules.includes('tests') ? 'section-frame' : 'hide'}
          >
            <div className="section-header">{`Tests (${sortedTests.length})`}</div>

            <div className="section-table-view">
              <div className="table-headers">
                <div className="table-header title">Title</div>
                <div className="table-header status">Status</div>
                <div className="table-header score">Score</div>
              </div>

              {sortedTests.length > 0 ? (
                sortedTests.map((test) => {
                  const { id, passed, score, title } = test;

                  const status = statuses[test.status];

                  return (
                    <li
                      className="section-cell"
                      key={id}
                      style={{ background: status.background }}
                    >
                      <div className="cell-label bold title">{title}</div>

                      <div className="flex-frame status">
                        <div
                          className={`flex-label initial ${status.color} ${
                            status.color ? 'bold' : ''
                          }`}
                        >
                          {status.text}
                        </div>
                        <div className="flex-label">
                          - {moment(test[status.time]).format(timeFormat)}
                        </div>
                      </div>

                      {score !== null && score >= 0 ? (
                        <div className="flex-frame">
                          <div className="flex-label initial bold">
                            {`${score}%`} -{' '}
                          </div>
                          <div
                            className={`flex-label bold ${
                              passed ? 'green' : 'orange'
                            }`}
                          >
                            {passed ? 'Pass' : 'Did Not Pass'}
                          </div>
                        </div>
                      ) : (
                        <div className="flex-frame cell-label">-</div>
                      )}

                      <OverlayTrigger
                        trigger="click"
                        placement="bottom"
                        rootClose
                        overlay={
                          <Popover
                            id="menu-pane"
                            className="menu-pane category"
                          >
                            <div
                              className="menu-content-view"
                              style={{ padding: '4px 8px 8px 8px' }}
                            >
                              <button
                                className={
                                  test.status === 'finished'
                                    ? 'menu-button teal'
                                    : 'hide'
                                }
                                onClick={() => goToItemRoute('tests', id)}
                              >
                                View Results
                              </button>
                              <button
                                className={
                                  test.status === 'finished'
                                    ? 'menu-button teal'
                                    : 'hide'
                                }
                                onClick={() => goToItemRoute('tests', id, true)}
                              >
                                View Results in Tab
                              </button>
                              <button
                                className={
                                  test.status !== 'finished'
                                    ? 'menu-button teal'
                                    : 'hide'
                                }
                                onClick={() =>
                                  sendReminder(false, test, 'test')
                                }
                              >
                                Send Reminder
                              </button>
                              <button
                                className={
                                  test.status !== 'finished'
                                    ? 'menu-button una'
                                    : 'hide'
                                }
                                onClick={() =>
                                  removeItem(test, 'testsTaken', 'test')
                                }
                              >
                                Remove
                              </button>
                            </div>
                          </Popover>
                        }
                      >
                        <div className="expander-icon">
                          <img
                            src={require('../../theme/images/expander-icon-grey-2x.png')}
                            alt="expander-icon"
                          />
                        </div>
                      </OverlayTrigger>
                    </li>
                  );
                })
              ) : (
                <div className="empty-label hint">
                  There are no tests to display.
                </div>
              )}
            </div>
          </div>

          <div
            className={
              org.modules.includes('checklists') ? 'section-frame' : 'hide'
            }
          >
            <div className="section-header">{`Skills Checklists (${sortedCheckLists.length})`}</div>

            <div className="section-table-view">
              <div className="table-headers">
                <div className="table-header title">Title</div>
                <div className="table-header status">Status</div>
              </div>

              {sortedCheckLists.length > 0 ? (
                sortedCheckLists.map((checklist) => {
                  const { id, score, title } = checklist;

                  const status = statuses[checklist.status];
                  const completed = checklist.status === 'finished';

                  return (
                    <li
                      className="section-cell"
                      key={id}
                      style={{ background: status.background }}
                    >
                      <div className="cell-label bold title">{title}</div>

                      <div className="flex-frame status">
                        <div
                          className={`flex-label initial ${status.color} ${
                            status.color ? 'bold' : ''
                          }`}
                        >
                          {status.text}
                        </div>
                        <div className="flex-label">
                          - {moment(checklist[status.time]).format(timeFormat)}
                        </div>
                      </div>

                      <div className="flex-frame">
                        {completed ? (
                          <div className="flex-label bold">
                            {score.toFixed(1)}
                          </div>
                        ) : null}
                      </div>

                      <OverlayTrigger
                        trigger="click"
                        placement="bottom"
                        rootClose
                        overlay={
                          <Popover
                            id="menu-pane"
                            className="menu-pane category"
                          >
                            <div
                              className="menu-content-view"
                              style={{ padding: '4px 8px 8px 8px' }}
                            >
                              <button
                                className={
                                  completed ? 'menu-button teal' : 'hide'
                                }
                                onClick={() => goToItemRoute('checklists', id)}
                              >
                                View Results
                              </button>
                              <button
                                className={
                                  completed ? 'menu-button teal' : 'hide'
                                }
                                onClick={() =>
                                  goToItemRoute('checklists', id, true)
                                }
                              >
                                View Results in Tab
                              </button>
                              <button
                                className={
                                  checklist.status !== 'finished'
                                    ? 'menu-button teal'
                                    : 'hide'
                                }
                                onClick={() =>
                                  sendReminder(false, checklist, 'checklist')
                                }
                              >
                                Send Reminder
                              </button>
                              <button
                                className={
                                  checklist.status !== 'finished'
                                    ? 'menu-button una'
                                    : 'hide'
                                }
                                onClick={() =>
                                  removeItem(
                                    checklist,
                                    'checklistsTaken',
                                    'checklist',
                                  )
                                }
                              >
                                Remove
                              </button>
                            </div>
                          </Popover>
                        }
                      >
                        <div className="expander-icon">
                          <img
                            src={require('../../theme/images/expander-icon-grey-2x.png')}
                            alt="expander-icon"
                          />
                        </div>
                      </OverlayTrigger>
                    </li>
                  );
                })
              ) : (
                <div className="empty-label hint">
                  There are no checklists to display.
                </div>
              )}
            </div>
          </div>

          <div
            className={
              org.modules.includes('mandatories') ? 'section-frame' : 'hide'
            }
          >
            <div className="section-header">{`Mandatories (${sortedMandatories.length})`}</div>

            <div className="section-table-view">
              <div className="table-headers">
                <div className="table-header title">Title</div>
                <div className="table-header status">Status</div>
                <div className="table-header score">Score</div>
              </div>

              {sortedMandatories.length > 0 ? (
                sortedMandatories.map((course) => {
                  const { id, passed, score, title } = course;

                  const status = statuses[course.status];

                  return (
                    <li
                      className="section-cell"
                      key={id}
                      style={{ background: status.background }}
                    >
                      <div className="cell-label bold title">{title}</div>

                      <div className="flex-frame status">
                        <div
                          className={`flex-label initial ${status.color} ${
                            status.color ? 'bold' : ''
                          }`}
                        >
                          {status.text}
                        </div>
                        <div className="flex-label">
                          - {moment(course[status.time]).format(timeFormat)}
                        </div>
                      </div>

                      {score !== null && score >= 0 ? (
                        <div className="flex-frame">
                          <div className="flex-label initial bold">
                            {`${score}%`} -{' '}
                          </div>
                          <div
                            className={`flex-label bold ${
                              passed ? 'green' : 'orange'
                            }`}
                          >
                            {passed ? 'Pass' : 'Did Not Pass'}
                          </div>
                        </div>
                      ) : (
                        <div className="flex-frame cell-label">-</div>
                      )}

                      <OverlayTrigger
                        trigger="click"
                        placement="bottom"
                        rootClose
                        overlay={
                          <Popover
                            id="menu-pane"
                            className="menu-pane category"
                          >
                            <div
                              className="menu-content-view"
                              style={{ padding: '4px 8px 8px 8px' }}
                            >
                              <button
                                className={
                                  course.status === 'finished'
                                    ? 'menu-button teal'
                                    : 'hide'
                                }
                                onClick={() => goToItemRoute('courses', id)}
                              >
                                View Results
                              </button>
                              <button
                                className={
                                  course.status === 'finished'
                                    ? 'menu-button teal'
                                    : 'hide'
                                }
                                onClick={() =>
                                  goToItemRoute('courses', id, true)
                                }
                              >
                                View Results in Tab
                              </button>
                              <button
                                className={
                                  course.status !== 'finished'
                                    ? 'menu-button teal'
                                    : 'hide'
                                }
                                onClick={() =>
                                  sendReminder(false, course, 'course')
                                }
                              >
                                Send Reminder
                              </button>
                              <button
                                className={
                                  course.status !== 'finished'
                                    ? 'menu-button una'
                                    : 'hide'
                                }
                                onClick={() =>
                                  removeItem(course, 'coursesTaken', 'course')
                                }
                              >
                                Remove
                              </button>
                            </div>
                          </Popover>
                        }
                      >
                        <div className="expander-icon">
                          <img
                            src={require('../../theme/images/expander-icon-grey-2x.png')}
                            alt="expander-icon"
                          />
                        </div>
                      </OverlayTrigger>
                    </li>
                  );
                })
              ) : (
                <div className="empty-label hint">
                  There are no courses to display.
                </div>
              )}
            </div>
          </div>

          <div
            className={
              org.modules.includes('credentials') ? 'section-frame' : 'hide'
            }
          >
            <div className="section-header-frame">
              <div className="section-header">Wallet</div>

              <button className="add-new-button" onClick={goToDocumentRoute}>
                + Add New Document
              </button>
            </div>

            <ul className="expands-frame">
              {/* work experiences */}
              <li className="expand-frame">
                <div
                  className="header-frame"
                  onClick={() =>
                    handleChange(
                      'expand.workExperiences',
                      !expand.workExperiences,
                    )
                  }
                >
                  <div className="section-header">{`work experiences (${workExperiences.length})`}</div>

                  <div
                    className={
                      expand.workExperiences
                        ? 'chevron-icon'
                        : 'chevron-icon rotate'
                    }
                  >
                    <img
                      src={require('../../theme/images/chevron-icon-2x.png')}
                      alt="chevron-icon"
                    />
                  </div>
                </div>

                <ul
                  className="expand-list-view"
                  style={{
                    height: expand.workExperiences
                      ? workExperiences.length * 48
                      : 0,
                  }}
                >
                  {workExperiences.map((experience) => {
                    const {
                      city,
                      endedOn,
                      id,
                      kind,
                      name,
                      positionTitle,
                      state: addrState,
                      startedOn,
                    } = experience;

                    const position = positionTitles.find(
                      (o) => o.id === positionTitle,
                    );

                    return (
                      <li className="reference-cell" key={id}>
                        <div className="details-frame">
                          <div className="cell-label bold doc-title">
                            {name}
                          </div>
                          <div className="cell-label">{`${
                            position
                              ? `${position.value} ${
                                  kind ? `- ${kind} ` : ''
                                }| `
                              : ''
                          }${city}, ${addrState}`}</div>
                        </div>

                        <div className="cell-label date">{`${moment(
                          startedOn,
                        ).format('MMM DD, YYYY')} - ${
                          endedOn
                            ? moment(endedOn).format('MMM DD, YYYY')
                            : 'Present'
                        }`}</div>

                        <OverlayTrigger
                          trigger="click"
                          placement="bottom"
                          rootClose
                          overlay={
                            <Popover
                              id="menu-pane"
                              className="menu-pane category"
                            >
                              <div
                                className="menu-content-view"
                                style={{ padding: '4px 8px 8px 8px' }}
                              >
                                <button
                                  className="menu-button teal"
                                  onClick={() => goToItemRoute('documents', id)}
                                >
                                  View Results
                                </button>
                                <button
                                  className="menu-button teal"
                                  onClick={() =>
                                    goToItemRoute('documents', id, true)
                                  }
                                >
                                  View Results in Tab
                                </button>
                              </div>
                            </Popover>
                          }
                        >
                          <div className="expander-icon">
                            <img
                              src={require('../../theme/images/expander-icon-grey-2x.png')}
                              alt="expander-icon"
                            />
                          </div>
                        </OverlayTrigger>
                      </li>
                    );
                  })}
                </ul>
              </li>

              {/* educations */}
              <li className="expand-frame">
                <div
                  className="header-frame"
                  onClick={() =>
                    handleChange('expand.education', !expand.education)
                  }
                >
                  <div className="section-header">{`education (${credentials.education.length})`}</div>

                  <div
                    className={
                      expand.education ? 'chevron-icon' : 'chevron-icon rotate'
                    }
                  >
                    <img
                      src={require('../../theme/images/chevron-icon-2x.png')}
                      alt="chevron-icon"
                    />
                  </div>
                </div>

                <ul
                  className="expand-list-view"
                  style={{
                    height: expand.education
                      ? credentials.education.length * 48
                      : 0,
                  }}
                >
                  {credentials.education.map((education) => {
                    const {
                      id,
                      issuedOn,
                      issuerLocation,
                      issuerName,
                      issuingState,
                      title,
                    } = education;

                    const degree = {
                      associate: STRINGS.associateDegree,
                      bachelor: STRINGS.bachelorDegree,
                      doctor: STRINGS.doctoralDegree,
                      master: STRINGS.masterDegree,
                    };

                    return (
                      <li className="reference-cell" key={id}>
                        <div className="details-frame">
                          <div className="cell-label bold doc-title">
                            {issuerName}
                          </div>
                          <div className="cell-label">{`${degree[title]} ${
                            issuerLocation && issuerLocation
                              ? `| ${issuerLocation}, ${issuingState}`
                              : ''
                          }`}</div>
                        </div>

                        <div className="cell-label date">
                          {moment(issuedOn).format('MMM YYYY')}
                        </div>

                        <OverlayTrigger
                          trigger="click"
                          placement="bottom"
                          rootClose
                          overlay={
                            <Popover
                              id="menu-pane"
                              className="menu-pane category"
                            >
                              <div
                                className="menu-content-view"
                                style={{ padding: '4px 8px 8px 8px' }}
                              >
                                <button
                                  className="menu-button teal"
                                  onClick={() => goToItemRoute('documents', id)}
                                >
                                  View Results
                                </button>
                                <button
                                  className="menu-button teal"
                                  onClick={() =>
                                    goToItemRoute('documents', id, true)
                                  }
                                >
                                  View Results in Tab
                                </button>
                              </div>
                            </Popover>
                          }
                        >
                          <div className="expander-icon">
                            <img
                              src={require('../../theme/images/expander-icon-grey-2x.png')}
                              alt="expander-icon"
                            />
                          </div>
                        </OverlayTrigger>
                      </li>
                    );
                  })}
                </ul>
              </li>

              {/* licenses */}
              <li className="expand-frame">
                <div
                  className="header-frame"
                  onClick={() =>
                    handleChange('expand.licenses', !expand.licenses)
                  }
                >
                  <div className="section-header">{`licenses (${credentials.license.length})`}</div>

                  <div
                    className={
                      expand.licenses ? 'chevron-icon' : 'chevron-icon rotate'
                    }
                  >
                    <img
                      src={require('../../theme/images/chevron-icon-2x.png')}
                      alt="chevron-icon"
                    />
                  </div>
                </div>

                <ul
                  className="expand-list-view"
                  style={{
                    height: expand.licenses
                      ? credentials.license.length * 48
                      : 0,
                  }}
                >
                  {_.orderBy(credentials.license, ['expiresOn'], ['desc']).map(
                    (document) => {
                      const {
                        documentNumber,
                        expiresOn,
                        id,
                        issuingState,
                        title,
                      } = document;

                      const expired = moment().isAfter(moment(expiresOn));

                      return (
                        <li className="reference-cell" key={id}>
                          <div className="details-frame">
                            <div className="cell-label bold doc-title">
                              {title}
                            </div>
                            <div className="cell-label doc-title">{`${documentNumber} | ${issuingState}`}</div>
                          </div>

                          <div className="flex-frame date">
                            {expired ? (
                              <div className="cell-label red">Expired</div>
                            ) : (
                              <div className="cell-label">Expires</div>
                            )}

                            <div className="cell-label">{`- ${moment(
                              expiresOn,
                            ).format('MMM DD, YYYY')}`}</div>
                          </div>

                          <OverlayTrigger
                            trigger="click"
                            placement="bottom"
                            rootClose
                            overlay={
                              <Popover
                                id="menu-pane"
                                className="menu-pane category"
                              >
                                <div
                                  className="menu-content-view"
                                  style={{ padding: '4px 8px 8px 8px' }}
                                >
                                  <button
                                    className="menu-button teal"
                                    onClick={() =>
                                      goToItemRoute('documents', id)
                                    }
                                  >
                                    View Results
                                  </button>
                                  <button
                                    className="menu-button teal"
                                    onClick={() =>
                                      goToItemRoute('documents', id, true)
                                    }
                                  >
                                    View Results in Tab
                                  </button>
                                </div>
                              </Popover>
                            }
                          >
                            <div className="expander-icon">
                              <img
                                src={require('../../theme/images/expander-icon-grey-2x.png')}
                                alt="expander-icon"
                              />
                            </div>
                          </OverlayTrigger>
                        </li>
                      );
                    },
                  )}
                </ul>
              </li>

              {/* certifications */}
              <li className="expand-frame">
                <div
                  className="header-frame"
                  onClick={() =>
                    handleChange(
                      'expand.certifications',
                      !expand.certifications,
                    )
                  }
                >
                  <div className="section-header">{`certifications (${credentials.certification.length})`}</div>

                  <div
                    className={
                      expand.certifications
                        ? 'chevron-icon'
                        : 'chevron-icon rotate'
                    }
                  >
                    <img
                      src={require('../../theme/images/chevron-icon-2x.png')}
                      alt="chevron-icon"
                    />
                  </div>
                </div>

                <ul
                  className="expand-list-view"
                  style={{
                    height: expand.certifications
                      ? credentials.certification.length * 48
                      : 0,
                  }}
                >
                  {_.orderBy(credentials.certification, ['title'], ['asc']).map(
                    (document) => {
                      const { documentNumber, expiresOn, id, title } = document;

                      const hint =
                        documentNumber.toLowerCase() === 'not provided';
                      const expired = moment().isAfter(moment(expiresOn));

                      return (
                        <li className="reference-cell" key={id}>
                          <div className="details-frame">
                            <div className="cell-label bold doc-title">
                              {title}
                            </div>
                            <div
                              className={`cell-label doc-title ${
                                hint ? 'hint' : ''
                              }`}
                            >
                              {documentNumber}
                            </div>
                          </div>

                          <div className="flex-frame date">
                            {expired ? (
                              <div className="cell-label red">Expired</div>
                            ) : (
                              <div className="cell-label">Expires</div>
                            )}

                            <div className="cell-label">{`- ${moment(
                              expiresOn,
                            ).format('MMM DD, YYYY')}`}</div>
                          </div>

                          <OverlayTrigger
                            trigger="click"
                            placement="bottom"
                            rootClose
                            overlay={
                              <Popover
                                id="menu-pane"
                                className="menu-pane category"
                              >
                                <div
                                  className="menu-content-view"
                                  style={{ padding: '4px 8px 8px 8px' }}
                                >
                                  <button
                                    className="menu-button teal"
                                    onClick={() =>
                                      goToItemRoute('documents', id)
                                    }
                                  >
                                    View Results
                                  </button>
                                  <button
                                    className="menu-button teal"
                                    onClick={() =>
                                      goToItemRoute('documents', id, true)
                                    }
                                  >
                                    View Results in Tab
                                  </button>
                                </div>
                              </Popover>
                            }
                          >
                            <div className="expander-icon">
                              <img
                                src={require('../../theme/images/expander-icon-grey-2x.png')}
                                alt="expander-icon"
                              />
                            </div>
                          </OverlayTrigger>
                        </li>
                      );
                    },
                  )}
                </ul>
              </li>

              {/* references */}
              <li className="expand-frame">
                <div
                  className="header-frame"
                  onClick={() =>
                    handleChange('expand.references', !expand.references)
                  }
                >
                  <div className="section-header">{`references (${references.length})`}</div>

                  <div
                    className={
                      expand.references ? 'chevron-icon' : 'chevron-icon rotate'
                    }
                  >
                    <img
                      src={require('../../theme/images/chevron-icon-2x.png')}
                      alt="chevron-icon"
                    />
                  </div>
                </div>

                <ul
                  className="expand-list-view"
                  style={{
                    height: expand.references ? references.length * 48 : 0,
                  }}
                >
                  {references.map((reference) => {
                    const {
                      completedAtIso,
                      firstName,
                      lastName,
                      id,
                      organization,
                      relationship,
                    } = reference;

                    const r = jobTitles.find(
                      (title) => title.id === relationship,
                    );

                    return (
                      <li className="reference-cell" key={id}>
                        <div className="details-frame">
                          <div className="cell-label bold">{`${firstName} ${lastName}`}</div>
                          <div className="cell-label">{`${
                            r ? `${r.value} - ` : ''
                          }${organization}`}</div>
                        </div>

                        <div className="cell-label date">
                          {moment(completedAtIso).format('MMM D, YYYY')}
                        </div>

                        <OverlayTrigger
                          trigger="click"
                          placement="bottom"
                          rootClose
                          overlay={
                            <Popover
                              id="menu-pane"
                              className="menu-pane category"
                            >
                              <div
                                className="menu-content-view"
                                style={{ padding: '4px 8px 8px 8px' }}
                              >
                                <button
                                  className="menu-button teal"
                                  onClick={() =>
                                    goToItemRoute('references', id)
                                  }
                                >
                                  View Results
                                </button>
                                <button
                                  className="menu-button teal"
                                  onClick={() =>
                                    goToItemRoute('references', id, true)
                                  }
                                >
                                  View Results in Tab
                                </button>
                              </div>
                            </Popover>
                          }
                        >
                          <div className="expander-icon">
                            <img
                              src={require('../../theme/images/expander-icon-grey-2x.png')}
                              alt="expander-icon"
                            />
                          </div>
                        </OverlayTrigger>
                      </li>
                    );
                  })}
                </ul>
              </li>

              {/* documents */}
              <li className="expand-frame">
                <div
                  className="header-frame"
                  onClick={() =>
                    handleChange('expand.documents', !expand.documents)
                  }
                >
                  <div className="section-header">{`documents (${credentials.misc.length})`}</div>

                  <div
                    className={
                      expand.documents ? 'chevron-icon' : 'chevron-icon rotate'
                    }
                  >
                    <img
                      src={require('../../theme/images/chevron-icon-2x.png')}
                      alt="chevron-icon"
                    />
                  </div>
                </div>

                <ul
                  className="expand-list-view"
                  style={{
                    height: expand.documents ? credentials.misc.length * 48 : 0,
                  }}
                >
                  {_.orderBy(
                    credentials.misc,
                    ['title', 'issuedOn'],
                    ['asc'],
                  ).map((document) => {
                    const { expiresOn, id, issuedOn, kind, title } = document;

                    const expiredDocs = ['drivers_license', 'other', 'ppd'];
                    const date =
                      expiredDocs.includes(kind) && expiresOn
                        ? expiresOn
                        : issuedOn;

                    return (
                      <li className="reference-cell" key={id}>
                        <div className="cell-label bold doc-title">
                          {kind === 'other'
                            ? `${STRINGS.other} - ${title}`
                            : title}
                        </div>

                        <div className="cell-label date">
                          {moment(date).format('MMM DD, YYYY')}
                        </div>

                        <OverlayTrigger
                          trigger="click"
                          placement="bottom"
                          rootClose
                          overlay={
                            <Popover
                              id="menu-pane"
                              className="menu-pane category"
                            >
                              <div
                                className="menu-content-view"
                                style={{ padding: '4px 8px 8px 8px' }}
                              >
                                <button
                                  className="menu-button teal"
                                  onClick={() => goToItemRoute('documents', id)}
                                >
                                  View Results
                                </button>
                                <button
                                  className="menu-button teal"
                                  onClick={() =>
                                    goToItemRoute('documents', id, true)
                                  }
                                >
                                  View Results in Tab
                                </button>
                              </div>
                            </Popover>
                          }
                        >
                          <div className="expander-icon">
                            <img
                              src={require('../../theme/images/expander-icon-grey-2x.png')}
                              alt="expander-icon"
                            />
                          </div>
                        </OverlayTrigger>
                      </li>
                    );
                  })}
                </ul>
              </li>

              {/* end wallet */}
            </ul>
          </div>

          {/* end */}
        </div>
      </div>
    </div>
  );
};

export default Roster;
