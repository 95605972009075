import React, { useState, useEffect } from 'react';
import Toolbar from '../Shared/Toolbar';
import { useParams, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-apollo';
import SubscriptionCreateMutation from '../../graphql/mutations/Subscription/Create';
import SubscriptionUpdateMutation from '../../graphql/mutations/Subscription/Update';
import SubscriptionsList from '../../graphql/queries/Org/Subscription';

const Createpackages = () => {
  const { package_id } = useParams();
  const navigate = useNavigate();
  const [userArray, setUserArray] = useState([]);
  const [Alert, setAlert] = useState('');
  const [PackageDetails, setPackageDetails] = useState([
    {
      title: '',
      amount: '',
      creditEarn: '',
      details: '',
      validityInMonths: '',
      category: '',
    },
  ]);

  const [subscriptionCreateMutation] = useMutation(SubscriptionCreateMutation);
  const [subscriptionUpdateMutation] = useMutation(SubscriptionUpdateMutation);

  const { loading, data, refetch } = useQuery(SubscriptionsList, {
    variables: { status: ['active', 'inactive'] },
  });

  useEffect(() => {
    refetch();
  }, [refetch]); // Refetch whenever the component mounts or the route changes

  useEffect(() => {
    if (!loading) {
      setUserArray(data?.subscriptions?.edges.map((edge) => edge.node));
    }
  }, [loading, data]);

  useEffect(() => {
    const foundUser = userArray.find((userItem) => userItem.id === package_id);
    if (foundUser) {
      setPackageDetails([
        {
          title: foundUser.title,
          amount: foundUser.amount,
          creditEarn: foundUser.creditEarn,
          details: foundUser.details,
          validityInMonths: foundUser.validity,
          category: foundUser.category ? foundUser.category : '',
        },
      ]);
    }
  }, [package_id, userArray]);

  const onCloseAlert = () => {
    if (Alert === 'Package created' || Alert === 'Package updated') {
      setAlert('');
      navigate(`/organizations/1/packages`);
    } else {
      setAlert('');
    }
  };

  const handleChange = (index, fieldName, type, value) => {
    if (type === 'number') {
      value = Number(value); // Convert value to number
    }
    setPackageDetails((prevUpdate) => {
      const updatedArray = [...prevUpdate];
      updatedArray[index][fieldName] = value;
      return updatedArray;
    });
  };

  const onBack = () => {
    navigate(`/organizations/1/packages`);
  };

  const onSave = () => {
    const isAllValid = PackageDetails.every(
      (obj) =>
        obj.title && obj.amount && obj.creditEarn && obj.validityInMonths
    );
    if (!isAllValid) {
      setAlert('Please fill Mandatory Fields');
      return;
    }

    const packageData = PackageDetails[0]; // Assuming you only deal with the first item

    if (package_id === 'create') {
      subscriptionCreateMutation({
        variables: {
          input: packageData,
        },
      })
        .then((res) => {
          setAlert('Package created');
        })
        .catch((error) => {
          console.error('Error creating Package:', error);
        });
    } else {
      subscriptionUpdateMutation({
        variables: {
          input: {
            ...packageData,
            id: Number(package_id),
          },
        },
      })
        .then((res) => {
          setAlert('Package updated');
        })
        .catch((error) => {
          console.error('Error updating Package:', error);
        });
    }
  };

  const loadTablebody = (row, index) => {
    return (
      <React.Fragment key={`package_${index}`}>
        <div className="form-frame" style={{ height: 84 * 2 + 150 }}>
          <div className="form-input-frame">
            <div className="input-title">
              Credit point
              <span style={{ color: '#ce1818' }}>*</span>
            </div>

            <input
              className="form-input"
              type="number"
              value={row.creditEarn}
              onChange={(e) =>
                handleChange(index, 'creditEarn', 'number', e.target.value)
              }
              placeholder="Enter Credit point"
            />
          </div>

          <div className="form-input-frame middle">
            <div className="input-title">
              Amount <span style={{ color: '#ce1818' }}>*</span>
            </div>

            <input
              className="form-input"
              type="number"
              value={row.amount}
              onChange={(e) =>
                handleChange(index, 'amount', 'number', e.target.value)
              }
              placeholder="Enter Amount"
            />
          </div>

          <div className="form-input-frame">
            <div className="input-title">
              Validity <span style={{ color: '#ce1818' }}>*</span>
            </div>

            <input
              className="form-input"
              type="number"
              value={row.validityInMonths}
              onChange={(e) =>
                handleChange(
                  index,
                  'validityInMonths',
                  'number',
                  e.target.value
                )
              }
              placeholder="Enter Validity In Months"
            />
          </div>

          <div className="form-input-frame">
            <div className="input-title">
              Package Name <span style={{ color: '#ce1818' }}>*</span>
            </div>

            <input
              className="form-input"
              value={row.title}
              onChange={(e) =>
                handleChange(index, 'title', 'text', e.target.value)
              }
              placeholder="Enter Package Name"
            />
          </div>

          <div className="form-input-frame middle">
            <div className="input-title">Category</div>

            <input
              className="form-input"
              value={row.category}
              onChange={(e) =>
                handleChange(index, 'category', 'text', e.target.value)
              }
              placeholder="Enter Category"
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="test-view full-screen-view">
      <Toolbar
        cancel={onBack}
        save={onSave}
        title={package_id === 'create' ? 'Add New Package' : 'Edit Package'}
      />

      {Alert && (
        <div className="modaloverlay">
          <div className="modal" style={{ width: '20%' }}>
            <h2>Alert</h2>
            <div
              className="model-body"
              dangerouslySetInnerHTML={{ __html: Alert }}
              style={{ display: 'inline' }}
            >
              {/* {Alert} */}
            </div>
            <div>
              <button
                type="submit"
                style={{ width: '20%' }}
                className="model-submit-button"
                onClick={onCloseAlert}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="content-frame">
        <div className="centered-frame">
          <div className="section-frame">
            <div className="section-header">
              {package_id === 'create' ? 'Create' : 'Edit'} Package
            </div>
            {PackageDetails.map((row, index) => {
              return loadTablebody(row, index, `package_${index}`);
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Createpackages;
