import { gql } from 'apollo-boost';

const TestSummaryDeactivateMutation = gql`
  mutation TestSummaryDeactivateMutation($input: IDInput) {
    testSummaryDeactivate(input: $input) {
      errors {
        code
        message
      }
    }
  }
`;

export default TestSummaryDeactivateMutation;
