import React from 'react';

// import Createjobs from '../../components/CreateJobPosts';
import { useParams } from 'react-router';
import JobPost from '../../components/JobPost';

const Createjobposts = (props) => {

  // return <Createjobs />;
  return <JobPost />;
};
export default Createjobposts;
