import React from 'react';
import DashboardFeedback from '../../components/Dashboard/Feedback';

const FeedbackContainer = (props) => {
// const tds = { width: '10%', height: '0%' }
 
const { addRow, onSave,  FormarArr ,CurrentArr } = props;

return (
   <DashboardFeedback
        {...props}
        addRow={addRow}
        FormarArr={FormarArr}
        CurrentArr={CurrentArr}
        onSave={onSave}
    />

  );
};


export default FeedbackContainer;
