import { gql } from 'apollo-boost';

const TestQuestionCreateMutation = gql`
  mutation TestQuestionCreateMutation($input: TestQuestionCreateInput) {
    testQuestionCreate(input: $input) {
      errors {
        code
        message
      }
      result {
        id
        answers
        isRandomized
        rank
        title
      }
    }
  }
`;

export default TestQuestionCreateMutation;
