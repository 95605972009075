import { gql } from 'apollo-boost';

const UserCourseRemoveMutation = gql`
  mutation UserCourseRemoveMutation($input: IDInput) {
    userCourseRemove(input: $input) {
      errors {
        code
        message
      }
      success
    }
  }
`;

export default UserCourseRemoveMutation;
