import gql from 'graphql-tag';

const TestCategoryQuery = gql`
  query TestCategoryQuery($id: ID) {
    test(id: $id) {
      id
      categories {
        id
        questions {
          id
          answers
          # answersForTest
          correctAnswerIndex
          isRandomized
          rank
          title
        }
        rank
        title
      }
      title
    }
  }
`;

export default TestCategoryQuery;
