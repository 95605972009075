import { gql } from 'apollo-boost';

const SignatureDocumentCreateMutation = gql`
  mutation SignatureDocumentCreateMutation($input: SignatureDocumentCreateCreateInput) {
    signatureDocumentCreate(input: $input) {
      errors {
        message
      }
      result {
        id
      }
      success
    }
  }
`;

export default SignatureDocumentCreateMutation;
