import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import Cookie from 'js-cookie';

import '../globals';

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const cache = new InMemoryCache({
  typePolicies: {
    User: {
      keyFields: ['id'],
    },
    Query: {
      fields: {
        users: relayStylePagination(['search']),
      },
    },
    Org: {
      fields: {
        users: relayStylePagination(['search', 'roles']),
      },
    },
  },
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => {
    const token = Cookie.get(`${global.COOKIE_NAME}-token`);
    const org = Cookie.get('org');
    const returnValue = {
      headers: { ...headers },
    };
    if (token) {
      returnValue.headers.authorization = `Bearer ${token}`;
    }
    if (org) {
      returnValue.headers.org = org;
    }
    return returnValue;
  });
  return forward(operation);
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
  defaultOptions,
});

export default client;
