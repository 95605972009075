const TestSummaryFragment = `
    fragment TestSummary on TestSummary {
        id
        categories {
            id
            questions {
                id
                answers
                correctAnswerIndex
                isRandomized
                rank
                title
            }
            rank
            title
            subtopic
        }
        desc
        durationMins
        kind
        passingScore
        status
        title
        updatedAt
        testType
    }
`;

export default TestSummaryFragment;
