import { gql } from 'apollo-boost';

const OrgCoursesQuery = gql`
  query OrgCoursesQuery($id: ID!, $first: Int, $after: String) {
    org(id: $id) {
      id
      brandColor
      courses(
        first: $first
        after: $after
        order: "title"
        status: ["active", "inactive"]
      ) {
        nodes {
          id
          chapters {
            id
            content
            questions {
              id
              answers
              isRandomized
              rank
              title
            }
            title
          }
          org {
            id
          }
          durationMins
          kind
          status
          title
          updatedAt
          courseType
        }
      }
      secondaryColor
    }
  }
`;

export default OrgCoursesQuery;
