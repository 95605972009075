import { gql } from 'apollo-boost';

const CourseRemoveMutation = gql`
  mutation CourseRemoveMutation($input: IDInput) {
    courseRemove(input: $input) {
      errors {
        code
        message
      }
      result {
        id
        
      }
    }
  }
`;

export default CourseRemoveMutation;
