import React, { useEffect, useState } from 'react';
import OptionalFactoryQuery from '../../graphql/queries/Org/Feedback/FeedbackFactors';
import ManagerFeedbackQuery from '../../graphql/queries/FeedbackRequest/ManagerFeedback';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import LoadingPane from '../Shared/LoadingPane';
import { withRouter } from '../../containers/withRouter';
import Toolbar from '../Shared/Toolbar';
import { useLocation } from 'react-router-dom';

const FeedbackEmailRequest = (props) => {
  const [loading, setLoading] = useState(false);
  const [Factors, setFactors] = useState([]);
  const [feedbackResponse, setfeedbackResponse] = useState([]);
  const [Alert, setAlert] = useState('');
  const { state } = useLocation();

  const onCloseAlert = () => {
    setAlert('');
  };

  const fetchData = async () => {
    const {
      ManagerFeedbackQuery: { refetch },
    } = props;

    try {
      const res = await refetch();
      if (
        res.data &&
        res.data.managerFeedback
      ) {
        setfeedbackResponse(res.data.managerFeedback.feedbackResponse);
        setLoading(false);
      } else {
        setAlert('Feedback has not been provided yet')
        console.error('Data is not as expected:', res.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    setLoading(true);
    setLoading(false);
    fetchData();

    const {
      optionalFactoryQuery: { refetch },
    } = props;
    refetch().then((res) => {
      const factors = res.data.org.optionalFactors.nodes;

      if (factors) {
        setFactors(factors);
        setLoading(false);
      }
    });
  }, []);

  const onBack = () => {
    const { navigate } = props;
    navigate(state.previous, {
      state: {
        org: state.org,
      },
    });
  };

  const loadTablebody = () => {
    const feedbackResponseData = Factors && feedbackResponse.map((feedback) => {
      const current_factor = Factors.find(factor => factor.id === feedback.factor_id);
      return { ...feedback, factor_title: current_factor?.title, factor_description: current_factor?.description, otype: current_factor?.otype }
    })

    return feedbackResponseData && feedbackResponseData.map((row, index) => (
      <li
        className={'test-cell'}
        key={index}
        style={{ marginBottom: 3, height: '40px' }}
      >
        <div className="centered-frame">
          <div className="cell-content-frame">
            <div className="cell-label list long" style={{ marginRight: 5 }}>
              {row.factor_title}
            </div>
            <div className="cell-label list">
              <input
                type="text"
                name="WorstEmployee"
                disabled
                className="input-field"
                value={row.prefer}
              />
            </div>
            <div className="cell-label list">
              <input
                type="text"
                name="rate"
                disabled
                className="input-field"
                value={row.rate || '-'}
              />
            </div>
            {/* <div className="cell-label list">
              <TextCarousel items={carouselItems} />
            </div> */}
            {/* <div className="cell-label list">
              <input
                type="text"
                name="company"
                disabled
                className="input-field"
              />
            </div>
            <div className="cell-label list">
              <input
                type="text"
                name="reported"
                disabled
                className="input-field"
              />
            </div>
            <div className="cell-label list">
              <input
                type="text"
                name="WorkQuality"
                disabled
                className="input-field"
              />
            </div>
            <div className="cell-label list">
              <input
                type="text"
                name="WorkQuality"
                disabled
                className="input-field"
              />
            </div>
            <div className="cell-label list">
              <input
                type="text"
                name="WorkQuality"
                disabled
                className="input-field"
              />
            </div>
            <div className="cell-label list">
              <input
                type="text"
                name="WorkQuality"
                disabled
                className="input-field"
              />
            </div> */}
          </div>
        </div>
      </li>
    ));
  };

  const loadTableHeader = (
    <div className="table-headers">
      <div className="centered-frame">
        <div className="table-header list long">{'Factors'}</div>
        <div className="table-header list">{'Prefer Not to Say'}</div>
        <div className="table-header list">{'Rating'}</div>
        {/* <div className="table-header list">{'Botttom 10%'}</div>
        <div className="table-header list">{'Below Average'}</div>
        <div className="table-header list">{'Average'}</div>
        <div className="table-header list">{'Above Average'}</div>
        <div className="table-header list">{'Top 10%'}</div>
        <div className="table-header list">{'Top Performer'}</div> */}
      </div>
    </div>
  );

  return (
    <div className="dashboard-feedback-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      {Alert && (
        <div className="modaloverlay">
          <div className="modal" style={{ width: '20%' }}>
            <h2>Alert</h2>
            <div
              className="model-body"
              dangerouslySetInnerHTML={{ __html: Alert }}
              style={{ display: 'inline' }}
            >
              {/* {Alert} */}
            </div>
            <div>
              <button
                type="submit"
                style={{ width: '20%' }}
                className="model-submit-button"
                onClick={onCloseAlert}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}

      <Toolbar cancel={onBack} title={'Feedback Form'} />

      <div style={{ padding: 10, marginTop: 80 }}>
        <div>
          <div className="feedback-table-viewthree" style={{ height: 450 }}>
            <>{loadTableHeader}</>
            <ul className="tests-list-view scroll">
              <>{feedbackResponse && Factors && loadTablebody()}</>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default compose(
  withRouter,
  graphql(OptionalFactoryQuery, {
    name: 'optionalFactoryQuery',
    options: (props) => ({
      variables: {
        id: props.location.state.org,
      },
    }),
  }),

  graphql(ManagerFeedbackQuery, {
    name: 'ManagerFeedbackQuery',
    options: (props) => ({
      variables: {
        id: props.params.id,
      },
    }),
  }),
)(FeedbackEmailRequest);
