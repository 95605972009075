import React from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';

import UserQuery from '../../graphql/queries/Document';

import LoadingPane from '../../components/Shared/LoadingPane';
import Certification from './Certification';
import Document from './Document';
import Education from './Education';
import License from './License';
import Reference from './Reference';
import WorkExperience from './WorkExperience';
import { withRouter } from '../withRouter';

class DocumentsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      onload: true,
    };
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate() {
    this.setup();
  }

  setup = () => {
    const {
      params,
      userQuery: { loading, user },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      const document = user.document;
      const workExperience = user.workExperiences.find(
        (o) => o.id === params.item,
      );

      this.setState({
        onload: false,
        type: workExperience
          ? 'work_experience'
          : document
          ? document.kind
          : params.item,
      });
    }
  };

  goToUserRoute = () => {
    const {
      navigate,
      params: { id, roster },
    } = this.props;

    navigate(`/organizations/${id}/roster/${roster}`);
  };

  goToNotFoundRoute = () => {
    this.props.navigate('/error');
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      this.renderDocument(this.state)
    );
  }

  renderDocument = () => {
    const { type } = this.state;

    if (type === 'certification') {
      return <Certification {...this.props} />;
    } else if (type === 'education') {
      return <Education {...this.props} />;
    } else if (type === 'license') {
      return <License {...this.props} />;
    } else if (type === 'reference') {
      return <Reference {...this.props} />;
    } else if (type === 'work_experience') {
      return <WorkExperience {...this.props} />;
    } else {
      return <Document {...this.props} goToUserRoute={this.goToUserRoute} />;
    }
  };
}

export default compose(
  withRouter,
  graphql(UserQuery, {
    name: 'userQuery',
    options: ({ params: { roster, item } }) => ({
      variables: {
        id: roster,
        document: item,
      },
    }),
  }),
)(DocumentsContainer);

// export default DocumentsContainer
