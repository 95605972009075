import { gql } from 'apollo-boost';

const CourseChapterCreateMutation = gql`
  mutation CourseChapterCreateMutation($input: CourseChapterCreateCreateInput) {
    courseChapterCreate(input: $input) {
      errors {
        message
      }
      result {
        id
        questions {
          id
          answers
          isRandomized
          rank
        }
        title
       
      }
    }
  }
`;

export default CourseChapterCreateMutation;
