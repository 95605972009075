import React, { useState, useEffect } from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import LoadingPane from '../Shared/LoadingPane';
import { withRouter } from '../../containers/withRouter';
import Toolbar from '../Shared/Toolbar';
import ExampleApp from './ExampleApp';
import FeedbackCheckbox from './FeebackCheckbox';
import FeedbackRequestExternalQuery from '../../graphql/queries/FeedbackRequest';
import FeedbackOnSave from '../../graphql/mutations/360Feedback/Category/FeedbackOnSave';

const FeedbackEmailPage = (props) => {
  const [loading, setLoading] = useState(false);
  const [factors, setFactors] = useState([]);
  const [alert, setAlert] = useState('');
  const [userData, setUserData] = useState('');
  const [selectedRatings, setSelectedRatings] = useState({});
  const [preferNotToSayStates, setPreferNotToSayStates] = useState({});
  const [disabledRates, setDisabledRates] = useState({});
  const [clickedIndex, setClickedIndex] = useState(null);

  const { page } = props;

  // Define the loadTableHeader function
  const loadTableHeader = (
    <div className="table-headers">
      <div className="centered-frame">
        <div className="table-header list long">{'Factors'}</div>
        <div className="table-header list">{'Prefer Not to Say'}</div>
        <div className="table-header list">{'Rating'}</div>
      </div>
    </div>
  );

  const toggleRateDisabled = (index, isDisabled) => {
    setDisabledRates((prevDisabledRates) => ({
      ...prevDisabledRates,
      [index]: isDisabled,
    }));
    // Update localStorage
    localStorage.setItem('disabledRates', JSON.stringify({ ...disabledRates, [index]: isDisabled }));
  };


  useEffect(() => {
    setLoading(true);
    const {
      feedbackRequestExternalQuery: { refetch: feedback },
    } = props;

    feedback().then((res) => {
      const { ofactory, requestingUser, managerFeedbackPresent } = res.data.feedbackRequest;

      if (managerFeedbackPresent) {
        setAlert('Feedback has already been sent');
      }
      setUserData(requestingUser);
      setFactors(ofactory);
      setLoading(false);
    });
  }, []);

  // Load data from localStorage on component mount
  useEffect(() => {
    const storedRatings = JSON.parse(localStorage.getItem('selectedRatings')) || {};
    setSelectedRatings(storedRatings);
    const storedStates = JSON.parse(localStorage.getItem('preferNotToSayStates')) || {};
    setPreferNotToSayStates(storedStates);
    const storedDisabledRates = JSON.parse(localStorage.getItem('disabledRates')) || {};
    setDisabledRates(storedDisabledRates); // Update disabledRates state
  }, []);


  const onSave = () => {
    const { FeedbackOnSave } = props;
    const { id } = props.feedbackRequestExternalQuery.feedbackRequest;

    // Construct feedbackResponse array based on state
    const feedbackResponse = factors.map((factor) => ({
      prefer: preferNotToSayStates[factor.id] || false,
      factorId: factor.id,
      rate: selectedRatings[factor.id] || '',
    }));

    FeedbackOnSave({
      variables: {
        input: {
          feedbackResponse,
          feedbackId: id,
          orgId: 1, // Assuming orgId is hardcoded to 1, you can change this dynamically if needed
        },
      },
    })
      .then((res) => {
        console.log('onSave', res);
        const { success, errors, result } = res.data.managerFeedbackCreate;
        if (success) {
          setAlert('Feedback has been sent successfully');
          // Clear localStorage values
          localStorage.removeItem('selectedRatings');
          localStorage.removeItem('preferNotToSayStates');
          localStorage.removeItem('disabledRates');
          // Reset states
          setSelectedRatings({});
          setPreferNotToSayStates({});
          setDisabledRates({});
        } else {
          // Handle errors
          const errorMessage = errors.map((error) => error.message).join(', ');
          setAlert(`Error: ${errorMessage}`);
        }
      })
      .catch((error) => {
        console.error('Error creating feedback requests:', error);
        setAlert('An error occurred while creating feedback requests.');
      });
  };

  const onRate = (index) => {
    // Toggle clickedIndex to open or close ExampleApp
    setClickedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handlePreferNotToSayChange = (index) => (e) => {
    const isChecked = e.target.checked;
    const updatedState = {
      ...preferNotToSayStates,
      [index]: isChecked, // Invert the isChecked value
    };

    toggleRateDisabled(index, isChecked);
    setPreferNotToSayStates(updatedState);
    // Update localStorage
    localStorage.setItem('preferNotToSayStates', JSON.stringify(updatedState));
  };

  const handlePageChange = (page, id) => {
    setSelectedRatings((prevSelectedRatings) => ({
      ...prevSelectedRatings,
      [id]: page,
    }));
    setClickedIndex(null);
    // Update localStorage
    localStorage.setItem('selectedRatings', JSON.stringify({ ...selectedRatings, [id]: page }));
  };

  const loadTableBody = () => {
    return factors.map((factor, index) => (
      <li key={index} className={'test-cell'} style={{ marginBottom: 3, height: '40px' }}>
        <div className="centered-frame">
          <div className="cell-content-frame">
            <div className="cell-label list long" style={{ marginRight: 5 }}>{factor.title}</div>
            <div className="cell-label list">
              <FeedbackCheckbox
                checked={preferNotToSayStates[index] || false}
                onChange={handlePreferNotToSayChange(index)}
              />
            </div>
            <div className="cell-label list">
              <button
                type="button"
                style={{
                  width: '80%',
                  height: 25,
                  color: 'white',
                  background: disabledRates[index] ? '#CCCCCC' : '#FF5A5F',
                  borderRadius: 4,
                }}
                onClick={() => onRate(index)}
                disabled={disabledRates[index]}
              >
                {selectedRatings[index] || 'Rate'}
              </button>
            </div>
            <div className="cell-label list long">
              {clickedIndex === index && (
                <ExampleApp
                  key={factor.id}
                  id={index}
                  page={page}
                  onPageChange={handlePageChange}
                />
              )}
            </div>
          </div>
        </div>
      </li>
    ));
  };

  return (
    <div>
      <div className="dashboard-feedback-view">
        {loading ? <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} /> : null}
        {alert && (
          <div className="modaloverlay">
            <div className="modal" style={{ width: '20%' }}>
              <div className="model-body" dangerouslySetInnerHTML={{ __html: alert }} style={{ display: 'inline' }}>
              </div>
              <div>
                <button
                  type="submit"
                  style={{ width: '20%' }}
                  className="model-submit-button"
                  onClick={() => {
                    setAlert('');
                    window.location.replace('/login');
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        )}
        <Toolbar title={'Feedback Form'} save={onSave} />
        <div style={{ marginTop: 40, display: 'flex', justifyContent: 'center', marginTop: 25 }}>
          <div style={{ paddingRight: 250, marginTop: 35 }}>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Firstname</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>Lastname</th>
                  <th></th>
                  <th></th>
                  <th>Email</th>
                  <th></th>
                  <th></th>
                  <th>Company</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{ }</td>
                  <td>{userData.firstName}</td>
                  <td>{ }</td>
                  <td>{ }</td>
                  <td>{ }</td>
                  <td>{ }</td>
                  <td>{userData.lastName}</td>
                  <td>{ }</td>
                  <td>{ }</td>
                  <td>{userData.email}</td>
                  <td>{ }</td>
                  <td>{ }</td>
                  <td>{userData?.feedbackSeekerDetails?.company}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="feedback-table-viewthree" style={{ height: 500, marginTop: 80, textAlign: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              {loadTableHeader}
              <ul className="tests-list-view scroll" style={{ listStyleType: 'none', width: '100%' }}>
                {loadTableBody()}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(
  withRouter,
  graphql(FeedbackRequestExternalQuery, {
    name: 'feedbackRequestExternalQuery',
    options: (props) => {
      return {
        variables: {
          ulid: props.params.ulid,
        },
      };
    },
  }),
  graphql(FeedbackOnSave, {
    name: 'FeedbackOnSave',
  }),
)(FeedbackEmailPage);
