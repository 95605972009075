import React from 'react';

import { orderBy } from 'lodash';
import moment from 'moment';

import Checkbox from '../../Shared/Checkbox';
import LoadingPane from '../../Shared/LoadingPane';
import Switch from '../../Shared/Switch';

import { positionTitles } from '../../../utils/Constants';

import STRINGS from '../../../localization';

const PacketSelect = (props) => {
  const {
    handleChange,
    handleSelection,
    handleContactSelection,
    parseChecklist,
    state,
  } = props;
  const {
    attachment,
    firstName,
    lastName,
    loading,
    org,
    summary,
    certifications,
    checklists,
    courses,
    documents,
    education,
    experiences,
    licenses,
    references,
    tests,
    checklistsTaken,
    coursesTaken,
    credentials,
    referencesTaken,
    testsTaken,
    workExperiences,
    contactDisplay=[]
  } = state;

  const credentialsArr = [
    certifications,
    documents,
    education,
    experiences,
    licenses,
    references,
  ].flat();
  const arr = [credentialsArr, checklists, courses, tests].flat();

  const contactDisplayAll = ['email','phone', 'address'];

  const back = () => {
    const { params, navigate, location } = props;

    if (!location.state || location.state?.previous === '/login') {
      navigate(`/organizations/${params.id}/roster/${params.roster}`);
      return;
    }
    navigate(-1);
  };

  return (
    <div className="roster-packet-view full-screen-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      <div className="toolbar">
        <button className="cancel-button" onClick={() => back()}>
          Back
        </button>

        <div className="header-frame">
          <div className="header-label bold">Create Packet - </div>

          <div className="avatar">
            <img
              src={
                attachment
                  ? attachment.url
                  : require('../../../theme/images/profile-icon-2x.png')
              }
              alt="profile-icon"
            />
          </div>

          <div className="header-label">{`${firstName} ${lastName}`}</div>
        </div>

        {arr.length > 0 ? (
          <div className="button-frame">
            <div className="switch-frame">
              <Switch
                defaultValue={summary}
                onChange={(enabled) => handleChange('summary', enabled)}
              />

              <div className={`switch-label ${summary ? 'black' : 'hint'}`}>
                Summary
              </div>
            </div>

            <button
              className="create-invite-button bg-red"
              onClick={() => handleChange('pdf', true)}
            >
              Generate Packet
            </button>
          </div>
        ) : (
          <div className="button-frame"></div>
        )}
      </div>

      <div className="content-frame scroll">
        <div className="centered-frame">
          <div className="section-frame">
            <div className="section-header-frame">
              <div className="header">Contact information</div>

              <div className="flex-frame">
                <button
                  className={contactDisplay.length > 0 ? 'clear-button una' : 'hide'}
                  onClick={() => handleChange('contactDisplay', [])}
                >
                  Clear
                </button>
                <button
                  className="select-all-button blue"
                  onClick={() => handleChange('contactDisplay', contactDisplayAll)}
                >
                  Select All
                </button>
              </div>
            </div>

            <ul className="section-list-view">
              <li
                className="section-cell"
                onClick={() => handleContactSelection('email')}
              >
                <Checkbox
                  checked={contactDisplay.includes('email')}
                  readOnly={true}
                />

                <div className="flex-frame column title">
                  <div className="cell-label title">Email</div>
                </div>
              </li>
              <li
                className="section-cell"
                onClick={() => handleContactSelection('phone')}
              >
                <Checkbox
                  checked={contactDisplay.includes('phone')}
                  readOnly={true}
                />

                <div className="flex-frame column title">
                  <div className="cell-label title">Phone Number</div>
                </div>
              </li>
              <li
                className="section-cell"
                onClick={() => handleContactSelection('address')}
              >
                <Checkbox
                  checked={contactDisplay.includes('address')}
                  readOnly={true}
                />

                <div className="flex-frame column title">
                  <div className="cell-label title">Address</div>
                </div>
              </li>
            </ul>
          </div>
          <div className="section-frame">
            <div className="section-header-frame">
              <div className="header">{`Tests (${testsTaken.length})`}</div>

              <div className="flex-frame">
                <button
                  className={tests.length > 0 ? 'clear-button una' : 'hide'}
                  onClick={() => handleChange('tests', [])}
                >
                  Clear
                </button>
                <button
                  className={
                    testsTaken.length > 0 ? 'select-all-button blue' : 'hide'
                  }
                  onClick={() => handleChange('tests', testsTaken)}
                >
                  Select All
                </button>
              </div>
            </div>

            <ul className="section-list-view">
              {testsTaken
                .sort((a, b) => new Date(b.endedAt) - new Date(a.endedAt))
                .map((result) => {
                  const { endedAt, id, passed, score, title } = result;

                  return (
                    <li
                      className="section-cell"
                      key={id}
                      onClick={() => handleSelection('tests', result)}
                    >
                      <Checkbox
                        checked={!!tests.find((r) => r.id === id)}
                        readOnly={true}
                      />

                      <div className="flex-frame column title">
                        <div className="cell-label title">{title}</div>

                        <div className="flex-frame">
                          <div className="cell-label">{`${score}% -`}</div>
                          <div
                            className={`cell-label ${
                              passed ? 'green' : 'orange'
                            }`}
                          >
                            {passed ? 'Pass' : 'Did Not Pass'}
                          </div>
                        </div>
                      </div>

                      <div className="cell-label">
                        {moment(endedAt).format('MM/DD/YY')}
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>

          <div className="section-frame">
            <div className="section-header-frame">
              <div className="header">{`Checklists (${checklistsTaken.length})`}</div>

              <div className="flex-frame">
                <button
                  className={
                    checklists.length > 0 ? 'clear-button una' : 'hide'
                  }
                  onClick={() => handleChange('checklists', [])}
                >
                  Clear
                </button>
                <button
                  className={
                    checklistsTaken.length > 0
                      ? 'select-all-button blue'
                      : 'hide'
                  }
                  onClick={() => handleChange('checklists', checklistsTaken)}
                >
                  Select All
                </button>
              </div>
            </div>

            <ul className="section-list-view">
              {checklistsTaken
                .sort((a, b) => new Date(b.endedAt) - new Date(a.endedAt))
                .map((result) => {
                  const { answers, endedAt, id, title } = result;

                  const answersHash = {};

                  answers.forEach(
                    (answer) =>
                      (answersHash[answer.question.id] = parseFloat(
                        answer.value,
                      )),
                  );

                  const checklistClone = parseChecklist(
                    result.checklist,
                    answersHash,
                  );

                  return (
                    <li
                      className="section-cell"
                      key={id}
                      onClick={() => handleSelection('checklists', result)}
                    >
                      <Checkbox
                        checked={!!checklists.find((r) => r.id === id)}
                        readOnly={true}
                      />

                      <div className="flex-frame column title">
                        <div className="cell-label title">{title}</div>
                        <div className="cell-label title">
                          {checklistClone.avgQuestionScore.toFixed(1)}
                        </div>
                      </div>

                      <div className="cell-label">
                        {moment(endedAt).format('MM/DD/YY')}
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>

          <div className="section-frame">
            <div className="section-header-frame">
              <div className="header">{`Mandatories (${coursesTaken.length})`}</div>

              <div className="flex-frame">
                <button
                  className={courses.length > 0 ? 'clear-button una' : 'hide'}
                  onClick={() => handleChange('courses', [])}
                >
                  Clear
                </button>
                <button
                  className={
                    coursesTaken.length > 0 ? 'select-all-button blue' : 'hide'
                  }
                  onClick={() => handleChange('courses', coursesTaken)}
                >
                  Select All
                </button>
              </div>
            </div>

            <ul className="section-list-view">
              {coursesTaken
                .sort((a, b) => new Date(b.endedAt) - new Date(a.endedAt))
                .map((result) => {
                  const { endedAt, id, passed, score, title } = result;

                  return (
                    <li
                      className="section-cell"
                      key={id}
                      onClick={() => handleSelection('courses', result)}
                    >
                      <Checkbox
                        checked={!!courses.find((r) => r.id === id)}
                        readOnly={true}
                      />

                      <div className="flex-frame column title">
                        <div className="cell-label title">{title}</div>

                        <div className="flex-frame">
                          <div className="cell-label">{`${score}% -`}</div>
                          <div
                            className={`cell-label ${
                              passed ? 'green' : 'orange'
                            }`}
                          >
                            {passed ? 'Pass' : 'Did Not Pass'}
                          </div>
                        </div>
                      </div>

                      <div className="cell-label">
                        {moment(endedAt).format('MM/DD/YY')}
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>

          {/* work experience */}
          <div
            className={
              org.modules.includes('credentials') ? 'section-frame' : 'hide'
            }
          >
            <div className="section-header-frame">
              <div className="header">{`${STRINGS.workExperience} (${workExperiences.length})`}</div>

              <div className="flex-frame">
                <button
                  className={
                    experiences.length > 0 ? 'clear-button una' : 'hide'
                  }
                  onClick={() => handleChange('experiences', [])}
                >
                  Clear
                </button>
                <button
                  className={
                    workExperiences.length > 0
                      ? 'select-all-button blue'
                      : 'hide'
                  }
                  onClick={() => handleChange('experiences', workExperiences)}
                >
                  Select All
                </button>
              </div>
            </div>

            <ul className="section-list-view">
              {orderBy(
                workExperiences,
                ['endedOn', 'startedOn'],
                ['desc', 'desc'],
              ).map((result) => {
                const {
                  city,
                  endedOn,
                  id,
                  kind,
                  name,
                  positionTitle,
                  startedOn,
                  state: addrState,
                } = result;

                const position = positionTitles.find(
                  (o) => o.id === positionTitle,
                );

                return (
                  <li
                    className="section-cell"
                    key={id}
                    onClick={() => handleSelection('experiences', result)}
                  >
                    <Checkbox
                      checked={!!experiences.find((r) => r.id === id)}
                      readOnly={true}
                    />

                    <div className="flex-frame column title">
                      <div className="cell-label bold">{name}</div>
                      <div className="cell-label">{`${
                        position
                          ? `${position.value} ${kind ? `- ${kind} ` : ''}| `
                          : ''
                      }${city}, ${addrState}`}</div>
                    </div>

                    <div className="cell-label date">{`${moment(
                      startedOn,
                    ).format('MMM DD, YYYY')} - ${
                      endedOn
                        ? moment(endedOn).format('MMM DD, YYYY')
                        : 'Present'
                    }`}</div>
                  </li>
                );
              })}
            </ul>
          </div>

          {/* education */}
          <div
            className={
              org.modules.includes('credentials') ? 'section-frame' : 'hide'
            }
          >
            <div className="section-header-frame">
              <div className="header">{`${STRINGS.education} (${credentials.education.length})`}</div>

              <div className="flex-frame">
                <button
                  className={education.length > 0 ? 'clear-button una' : 'hide'}
                  onClick={() => handleChange('education', [])}
                >
                  Clear
                </button>
                <button
                  className={
                    credentials.education.length > 0
                      ? 'select-all-button blue'
                      : 'hide'
                  }
                  onClick={() =>
                    handleChange('education', credentials.education)
                  }
                >
                  Select All
                </button>
              </div>
            </div>

            <ul className="section-list-view">
              {credentials.education.map((result) => {
                const {
                  id,
                  issuedOn,
                  issuerLocation,
                  issuerName,
                  issuingState,
                  title,
                } = result;

                const degree = {
                  associate: STRINGS.associateDegree,
                  bachelor: STRINGS.bachelorDegree,
                  doctor: STRINGS.doctoralDegree,
                  master: STRINGS.masterDegree,
                };
                const formattedTitle = degree[title];

                return (
                  <li
                    className="section-cell"
                    key={id}
                    onClick={() => handleSelection('education', result)}
                  >
                    <Checkbox
                      checked={!!education.find((r) => r.id === id)}
                      readOnly={true}
                    />

                    <div className="flex-frame column title">
                      <div className="cell-label bold">{issuerName}</div>
                      <div className="cell-label" style={{ width: '100%' }}>{`${
                        formattedTitle || STRINGS._degree
                      } ${
                        issuerLocation && issuerLocation
                          ? `| ${issuerLocation}, ${issuingState}`
                          : ''
                      }`}</div>
                    </div>

                    <div className="cell-label date">
                      {moment(issuedOn).format('MMM YYYY')}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>

          {/* license */}
          <div
            className={
              org.modules.includes('credentials') ? 'section-frame' : 'hide'
            }
          >
            <div className="section-header-frame">
              <div className="header">{`${STRINGS.license} (${credentials.license.length})`}</div>

              <div className="flex-frame">
                <button
                  className={licenses.length > 0 ? 'clear-button una' : 'hide'}
                  onClick={() => handleChange('licenses', [])}
                >
                  Clear
                </button>
                <button
                  className={
                    credentials.license.length > 0
                      ? 'select-all-button blue'
                      : 'hide'
                  }
                  onClick={() => handleChange('licenses', credentials.license)}
                >
                  Select All
                </button>
              </div>
            </div>

            <ul className="section-list-view">
              {orderBy(credentials.license, ['expiresOn'], ['desc']).map(
                (result) => {
                  const { documentNumber, expiresOn, id, issuingState, title } =
                    result;

                  const expired = moment().isAfter(moment(expiresOn));

                  return (
                    <li
                      className="section-cell"
                      key={id}
                      onClick={() => handleSelection('licenses', result)}
                    >
                      <Checkbox
                        checked={!!licenses.find((r) => r.id === id)}
                        readOnly={true}
                      />

                      <div className="flex-frame column title">
                        <div className="cell-label bold">{title}</div>
                        <div className="cell-label">{`${documentNumber} | ${issuingState}`}</div>
                      </div>

                      <div className="flex-frame">
                        {expired ? (
                          <div className="cell-label margin red">Expired</div>
                        ) : (
                          <div className="cell-label margin">Expires</div>
                        )}

                        <div className="cell-label date">{`- ${moment(
                          expiresOn,
                        ).format('MMM DD, YYYY')}`}</div>
                      </div>
                    </li>
                  );
                },
              )}
            </ul>
          </div>

          {/* certifications */}
          <div
            className={
              org.modules.includes('credentials') ? 'section-frame' : 'hide'
            }
          >
            <div className="section-header-frame">
              <div className="header">{`${STRINGS.certification} (${credentials.certification.length})`}</div>

              <div className="flex-frame">
                <button
                  className={
                    certifications.length > 0 ? 'clear-button una' : 'hide'
                  }
                  onClick={() => handleChange('certifications', [])}
                >
                  Clear
                </button>
                <button
                  className={
                    credentials.certification.length > 0
                      ? 'select-all-button blue'
                      : 'hide'
                  }
                  onClick={() =>
                    handleChange('certifications', credentials.certification)
                  }
                >
                  Select All
                </button>
              </div>
            </div>

            <ul className="section-list-view">
              {orderBy(credentials.certification, ['title'], ['asc']).map(
                (result) => {
                  const { expiresOn, id, title } = result;

                  const expired = moment().isAfter(moment(expiresOn));

                  return (
                    <li
                      className="section-cell"
                      key={id}
                      onClick={() => handleSelection('certifications', result)}
                    >
                      <Checkbox
                        checked={!!certifications.find((r) => r.id === id)}
                        readOnly={true}
                      />

                      <div className="flex-frame column title">
                        <div className="cell-label bold">{title}</div>
                      </div>

                      <div className="flex-frame">
                        {expired ? (
                          <div className="cell-label margin red">Expired</div>
                        ) : (
                          <div className="cell-label margin">Expires</div>
                        )}

                        <div className="cell-label date">{` - ${moment(
                          expiresOn,
                        ).format('MMM DD, YYYY')}`}</div>
                      </div>
                    </li>
                  );
                },
              )}
            </ul>
          </div>

          {/* reference */}
          <div
            className={
              org.modules.includes('credentials') ? 'section-frame' : 'hide'
            }
          >
            <div className="section-header-frame">
              <div className="header">{`${STRINGS.reference} (${referencesTaken.length})`}</div>

              <div className="flex-frame">
                <button
                  className={
                    references.length > 0 ? 'clear-button una' : 'hide'
                  }
                  onClick={() => handleChange('references', [])}
                >
                  Clear
                </button>
                <button
                  className={
                    referencesTaken.length > 0
                      ? 'select-all-button blue'
                      : 'hide'
                  }
                  onClick={() => handleChange('references', referencesTaken)}
                >
                  Select All
                </button>
              </div>
            </div>

            <ul className="section-list-view">
              {referencesTaken.map((result) => {
                const {
                  completedAtIso,
                  firstName,
                  lastName,
                  id,
                  organization,
                } = result;

                return (
                  <li
                    className="section-cell"
                    key={id}
                    onClick={() => handleSelection('references', result)}
                  >
                    <Checkbox
                      checked={!!references.find((r) => r.id === id)}
                      readOnly={true}
                    />

                    <div className="flex-frame column title">
                      <div className="cell-label bold">{`${firstName} ${lastName}`}</div>
                      <div className="cell-label">{organization}</div>
                    </div>

                    <div className="cell-label date">
                      {moment(completedAtIso).format('MMM D, YYYY')}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>

          {/* document */}
          <div
            className={
              org.modules.includes('credentials') ? 'section-frame' : 'hide'
            }
          >
            <div className="section-header-frame">
              <div className="header">{`${STRINGS.document} (${credentials.misc.length})`}</div>

              <div className="flex-frame">
                <button
                  className={documents.length > 0 ? 'clear-button una' : 'hide'}
                  onClick={() => handleChange('documents', [])}
                >
                  Clear
                </button>
                <button
                  className={
                    credentials.misc.length > 0
                      ? 'select-all-button blue'
                      : 'hide'
                  }
                  onClick={() => handleChange('documents', credentials.misc)}
                >
                  Select All
                </button>
              </div>
            </div>

            <ul className="section-list-view">
              {orderBy(credentials.misc, ['title'], ['asc']).map((result) => {
                const { expiresOn, id, issuedOn, kind, title } = result;

                const date =
                  kind === 'drivers_license' || kind === 'ppd'
                    ? expiresOn
                    : issuedOn;

                return (
                  <li
                    className="section-cell"
                    key={id}
                    onClick={() => handleSelection('documents', result)}
                  >
                    <Checkbox
                      checked={!!documents.find((r) => r.id === id)}
                      readOnly={true}
                    />

                    <div className="flex-frame column title">
                      <div className="cell-label bold">{title}</div>
                    </div>

                    <div className="cell-label date">
                      {moment(date).format('MMM DD, YYYY')}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          {/* end */}
        </div>
      </div>
    </div>
  );
};

export default PacketSelect;
