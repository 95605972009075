import React from 'react';

import _ from 'lodash';

import STRINGS from '../../../../../localization';

const AnnualEducationAttestation = (props) => {
  const {
    state: { kind },
  } = props;

  const arr = [
    'Abuse & Neglect',
    'Age Specific Care',
    'Body Mechanics',
    'COVID 19',
    'Cultural Diversity',
    'Emergency Preparedness',
    'Environmental Safety',
    'Ethics',
    'Fire Safety',
    'Hazard Communication',
    'HIPAA',
    'Infection Control & Bloodborne Pathogens',
    'National Patient Safety Goals',
    'Pain Management',
    'Patient Rights',
    'Patient Safety Systems',
    'Sexual Harassment',
    'Workplace Violence',
  ];

  return (
    <div className="key-value-frame full">
      <div className="form-frame">
        <div className="form-name">{`${
          STRINGS[_.camelCase(kind)]
        } & ${_.capitalize(STRINGS.orientation)}`}</div>

        <div className="form-label">
          I acknowledge that I have received training on and always have access
          to an online updated copy of the Annual Education and Core Compentency
          Education, which contains information and verification of procedures
          included but not limited to the following:
        </div>

        <ul className="form-list-view">
          {arr.map((o, i) => {
            return (
              <li className="form-label" key={i}>
                {o}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default AnnualEducationAttestation;
