import { gql } from 'apollo-boost';

const MinUserQuery = gql`
  query MinUserQuery($id: ID) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      phoneNumber
      roles {
        id
        name
        org {
          id
          status
          attachment(label: "pdf_logo") {
            id
            url
          }
          brandColor
          modules
          title
        }
      }
    }
  }
`;

export default MinUserQuery;
