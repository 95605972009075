import React from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';

import _ from 'lodash';
import moment from 'moment';

import TestQuery from '../../graphql/queries/Test';
import { withRouter } from '../withRouter';
import TestCategoryCreateMutation from '../../graphql/mutations/Tests/Category/Create';
import TestCategoryRemoveMutation from '../../graphql/mutations/Tests/Category/Remove';
import TestCategoryUpdateMutation from '../../graphql/mutations/Tests/Category/Update';
import TestQuestionCreateMutation from '../../graphql/mutations/Tests/Question/Create';
import TestSummaryActivateMutation from '../../graphql/mutations/Tests/Summary/Activate';
import TestSummaryDeactivateMutation from '../../graphql/mutations/Tests/Summary/Deactivate';
import TestSummaryCreateMutation from '../../graphql/mutations/Tests/Summary/Create';
import TestSummaryUpdateMutation from '../../graphql/mutations/Tests/Summary/Update';

import Test from '../../components/Test';

import LoadingPane from '../../components/Shared/LoadingPane';

import { createNumericOptions } from '../../utils/HelperMethods';

class TestContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
      categories: [],
      categoryOnFocus: [],
      days: createNumericOptions(0, 365, false),
      isSwap: false,
      loading: false,
      onload: true,
      scores: createNumericOptions(60, 100, true),
      mappingOptions: [
        { value: 'normal', label: 'Normal' },
        { value: 'self_report', label: 'Self Report Assessment ' },
        { value: 'ecp_job', label: 'ECP Jobs Assessment ' },
      ],
    };
  }


  componentDidMount() {
    const {
      testQuery: { loading, refetch },
    } = this.props;

    if (this.state.onload && loading) {
      this.setupTest();
    } else {
      refetch().then(() => this.setupTest());
    }
  }

  componentDidUpdate() {
    this.setupTest();
  }

  setupTest = () => {
    const {
      location,
      navigate,
      testQuery: { loading, test },
      context,
    } = this.props;
    const { user } = context;

    if (this.state.onload && !loading) {
      const mins = test ? test.durationMins : 0;
      const duration = moment.duration(mins, 'minutes').asDays();
      const org = location.state ? location.state.org : null;
      const role = user.roles.find((r) => r.org.id === org);
      const admin = role ? role.name === 'corporate' : false;

      if (test && test.kind === 'global' && !admin) {
        navigate(`/error${org ? `?org=${org}` : ''}`);
      } else {
        this.setState({
          ...test,
          active: test ? test.status === 'active' : false,
          admin,
          durationMins: duration >= 0 && duration <= 365 ? duration : 1,
          onload: false,
        });
      }
    }
  };

  addCategory = (lastNewAddedCategory) => {
    let index;
    if (lastNewAddedCategory.length === 0) {
      index = lastNewAddedCategory.length;
    } else {
      index = parseInt(lastNewAddedCategory[0].id.split("-")[1])
    }

    const category = {
      id: `create-${index + 1}`,
      questions: [],
    };

    const updatedCategoryOnFocus = [...this.state.categoryOnFocus, category];
    const updatedCategories = [...this.state.categories, category];

    this.handleChange('categories', updatedCategories);
    this.handleChange('categoryOnFocus', updatedCategoryOnFocus);
  };

  back = () => {
    const { location, params, navigate } = this.props;

    if (location.state) {
      navigate(location.state.previous);
    } else {
      this.goToRoute(`/organizations/${params.id}/tests`);
    }
  };

  cancelCategory = (categoryId) => {
    const {
      categories,
      categoryOnFocus
    } = this.state;

    const i = categories.findIndex((c) => c.id === categoryId);

    const updatedCategoryOnFocus = categoryOnFocus.filter((category) => { return category.id !== categoryId })
    const updatedCategories = categories.filter((category) => { return category.id !== categoryId })

    if (categoryId.includes('create')) {
      this.handleChange('categories', updatedCategories)
    }

    this.handleChange('categoryOnFocus', updatedCategoryOnFocus);
  };

  createTest = () => {
    const { location, testSummaryCreateMutation } = this.props;
    const { color, desc, durationMins, kind, passingScore, testType, title } = this.state;

    this.setState(
      {
        loading: true,
      },
      async () => {
        testSummaryCreateMutation({
          variables: {
            input: {
              title,
              desc,
              durationMins: moment.duration(durationMins, 'days').asMinutes(),
              passingScore,
              testType,
              color,
              kind,
              orgId: location.state.org,
            },
          },
        })
          .then((response) => {
            const {
              data: {
                testSummaryCreate: { errors, result },
              },
            } = response;

            if (errors) {
              this.handleChange('loading', false);

              window.alert(errors[0].message);
            } else {
              this.updateStatus(result);
            }
          })
          .catch((error) => {
            this.handleChange('loading', false);

            window.alert(error);
          });
      },
    );
  };

  deleteCategory = (category) => {
    const { testCategoryRemoveMutation } = this.props;

    document.body.click();

    this.setState(
      {
        loading: true,
      },
      () => {
        testCategoryRemoveMutation({
          variables: {
            input: {
              id: category.id,
            },
          },
        }).then((response) => {
          const {
            data: {
              testCategoryRemove: { errors },
            },
          } = response;

          const arr = this.state.categories;
          const i = arr.findIndex((c) => c.id === category.id);

          if (errors) {
            window.alert(errors[0].message);
          } else {
            this.setState({
              loading: false,
              categories: [...arr.slice(0, i), ...arr.slice(i + 1, arr.length)],
            });
          }
        });
      },
    );
  };

  duplicateCategory = (category) => {
    const { testCategoryCreateMutation } = this.props;
    const { id } = this.state;

    document.body.click();

    this.setState(
      {
        loading: true,
      },
      () => {
        testCategoryCreateMutation({
          variables: {
            input: {
              summaryId: id,
              title: `${category.title} Copy`,
              rank: this.state.categories.length + 1,
            },
          },
        }).then((response) => {
          const {
            data: {
              testCategoryCreate: { errors, result },
            },
          } = response;

          if (errors) {
            window.alert(errors[0].message);
          } else {
            this.duplicateQuestions(category.questions, 0, result);
          }
        });
      },
    );
  };

  duplicateQuestions = (arr, i, category) => {
    if (i === arr.length) {
      this.setState({
        loading: false,
        categories: [...this.state.categories, category],
      });
    } else {
      const { testQuestionCreateMutation } = this.props;
      const { answers, correctAnswerIndex, isRandomized, rank, title } = arr[i];

      testQuestionCreateMutation({
        variables: {
          input: {
            categoryId: category.id,
            correctAnswerIndex,
            title,
            answers,
            rank,
            isRandomized,
          },
        },
      }).then((response) => {
        category.questions = [
          ...category.questions,
          response.data.testQuestionCreate.result,
        ];

        this.duplicateQuestions(arr, i + 1, category);
      });
    }
  };

  goToRoute = (route) => {
    this.props.navigate(route);
  };

  goToCategoryRoute = (id) => {
    const { location, navigate, params } = this.props;

    navigate(`/tests/${params.id}/categories/${id}`, {
      state: {
        previous: location.pathname,
        org: location.state.org,
      },
    });
  };

  handleChange = (key, value) => {
    this.setState(_.set(this.state, key, value));
  };

  save = () => {
    if (this.state.id) {
      this.updateTest();
    } else {
      this.createTest();
    }
  };

  saveCategory = (rank, categoryId) => {
    const {
      testCategoryCreateMutation,
      testCategoryUpdateMutation
    } = this.props;
    const {
      categories,
      categoryOnFocus,
      id,
    } = this.state;
    const { title } = categoryOnFocus.find((category) => category.id === categoryId);

    if (title) {
      const isCreate = categoryId.includes('create');
      const mutation = isCreate
        ? testCategoryCreateMutation
        : testCategoryUpdateMutation;
      const data = {
        title,
        rank: rank + 1,
      };

      if (isCreate) data.summaryId = id;
      else data.id = categoryId;

      mutation({
        variables: {
          input: {
            ...data,
          },
        },
      }).then((res) => {
        const key = Object.keys(res.data)[0];
        const response = res.data[key];

        if (response.errors) {
          window.alert(response.errors[0].message);
        } else {
          this.setState({
            categoryOnFocus: categoryOnFocus.filter((c) => c.id !== categoryId),
            categories: [
              ...categories.slice(0, rank),
              response.result,
              ...categories.slice(rank + 1, categories.length),
            ],
          });
        }
      });
    } else {
      window.alert('Category requires a title.');
    }
  };

  updateCategory = (arr, index) => {
    const { testCategoryUpdateMutation } = this.props;

    if (index === arr.length) {
      this.updateStatus();
    } else {
      testCategoryUpdateMutation({
        variables: {
          input: {
            id: arr[index].id,
            rank: index + 1,
          },
        },
      }).then((response) => {
        const {
          data: {
            testCategoryUpdate: { errors },
          },
        } = response;

        if (errors) {
          window.alert(errors[0].message);
        } else {
          this.updateCategory(arr, index + 1);
        }
      });
    }
  };

  updateStatus = (result) => {
    const { testSummaryActivateMutation, testSummaryDeactivateMutation } =
      this.props;
    const { active, id, status } = this.state;

    let mutation;

    if (active && (!status || status === 'inactive')) {
      mutation = testSummaryActivateMutation;
    } else if (!active && status === 'active') {
      mutation = testSummaryDeactivateMutation;
    }

    if (mutation) {
      mutation({
        variables: {
          input: {
            id: id || result.id,
          },
        },
      }).then((response) => {
        const key = Object.keys(response.data)[0];
        const errors = response.data[key].errors;

        if (errors) {
          window.alert(errors[0].message);
        } else {
          this.back();
        }
      });
    } else {
      this.back();
    }
  };

  updateTest = () => {
    const { location, testSummaryUpdateMutation } = this.props;
    const {
      categories,
      color,
      desc,
      durationMins,
      kind,
      id,
      testType,
      passingScore,
      title,
    } = this.state;

    this.setState(
      {
        loading: true,
      },
      () => {
        testSummaryUpdateMutation({
          variables: {
            input: {
              id,
              title,
              desc,
              durationMins: moment.duration(durationMins, 'days').asMinutes(),
              passingScore,
              testType,
              color,
              kind,
              orgId: location.state.org,
            },
          },
        }).then((response) => {
          const {
            data: {
              testSummaryUpdate: { errors },
            },
          } = response;

          if (errors) {
            window.alert(errors[0].message);
          } else {
            this.updateCategory(categories, 0);
          }
        });
      },
    );
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <Test
        {...this.props}
        back={this.back}
        addCategory={this.addCategory}
        cancelCategory={this.cancelCategory}
        deleteCategory={this.deleteCategory}
        duplicateCategory={this.duplicateCategory}
        goToCategoryRoute={this.goToCategoryRoute}
        saveCategory={this.saveCategory}
        goToRoute={this.goToRoute}
        handleChange={this.handleChange}
        handleSwap={this.handleSwap}
        save={this.save}
        state={this.state}
      />
    );
  }
}

export default compose(
  withRouter,
  graphql(TestQuery, {
    name: 'testQuery',
    options: (props) => ({
      variables: {
        id: props.params.id,
      },
    }),
  }),
  graphql(TestCategoryCreateMutation, { name: 'testCategoryCreateMutation' }),
  graphql(TestCategoryRemoveMutation, { name: 'testCategoryRemoveMutation' }),
  graphql(TestCategoryUpdateMutation, { name: 'testCategoryUpdateMutation' }),
  graphql(TestQuestionCreateMutation, { name: 'testQuestionCreateMutation' }),
  graphql(TestSummaryActivateMutation, { name: 'testSummaryActivateMutation' }),
  graphql(TestSummaryDeactivateMutation, {
    name: 'testSummaryDeactivateMutation',
  }),
  graphql(TestSummaryCreateMutation, { name: 'testSummaryCreateMutation' }),
  graphql(TestSummaryUpdateMutation, { name: 'testSummaryUpdateMutation' }),
)(TestContainer);
