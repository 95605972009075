import { gql } from 'apollo-boost';

const UserChecklistCreateMutation = gql`
  mutation UserChecklistCreateMutation(
    $input: ChecklistUserChecklistCreateCreateInput
  ) {
    userChecklistCreate(input: $input) {
      errors {
        code
        message
      }
      success
    }
  }
`;

export default UserChecklistCreateMutation;
