import React from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import TestQuery from '../../graphql/queries/Question';
import TestQuestionCreateMutation from '../../graphql/mutations/Tests/Question/Create';
import TestQuestionUpdateMutation from '../../graphql/mutations/Tests/Question/Update';
import Question from '../../components/Question/Category';
import LoadingPane from '../../components/Shared/LoadingPane';
import { withRouter } from '../withRouter';

class QuestionCategoryContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      correctAnswerIndex: 0,
      isHTML: false,
      isRandomized: true,
      loading: false,
      onload: true,
      options: [],
      title: null,
    };
  }

  componentDidMount() {
    const {
      testQuery: { loading, refetch },
    } = this.props;

    if (this.state.onload && !loading) {
      refetch().then(() => this.setup());
    } else {
      this.setup();
    }

    window.addEventListener('keydown', (e) => {
      // shift + enter
      if (e.shiftKey && e.keyCode === 13) {
        this.setState(
          {
            options: this.state.options
              .filter((o) => o.title)
              .map((o) => ({ ...o, edit: false })),
          },
          () => this.addOption(),
        );
      }
    });
  }

  componentDidUpdate() {
    this.setup();
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', (e) => {
      // shift + enter
      if (e.shiftKey && e.keyCode === 13) {
        this.setState(
          {
            options: this.state.options
              .filter((o) => o.title)
              .map((o) => ({ ...o, edit: false })),
          },
          () => this.addOption(),
        );
      }
    });
  }

  setup = () => {
    const {
      location,
      navigate,
      params: { category, question },
      testQuery: { loading, test },
      context,
    } = this.props;
    const { user } = context;
    const { onload } = this.state;
        
    if (onload && !loading) {
      const c = test.categories.find((c) => c.id === category);
      const q = c.questions.find((q) => q.id === question);
      const org = location.state ? location.state.org : null;
      const role = user.roles.find((r) => r.org.id === org);
      const admin = role ? role.name === 'corporate' : false;
  
      if (test && test.kind === 'global' && !admin) {
        navigate(`/error${org ? `?org=${org}` : ''}`);
      } else if (c && q) {
        const regex = /<\/?[a-z][\s\S]*>/i;

        this.setState({
          ...q,
          answer: q.answers.length > 0 ? q.answers[0] : null,
          category: c,
          content: regex.test(q.title) ? q.title : null,
          id: q.id,
          isHTML: regex.test(q.title),
          options: q.answers.map((a) => ({ title: a, edit: false })),
          onload: false,
          test,
        });
      } else if (c && !q) {
        this.setState({
          category: c,
          onload: false,
          test,
        });
      } else {
        this.goToTestRoute();
      }
    }
  };

  addOption = () => {
    const arr = this.state.options;

    this.handleChange('options', [...arr, { title: '', edit: true }]);
  };

  deleteOption = (index) => {
    const arr = this.state.options.filter((o, i) => index !== i);

    document.body.click();

    this.handleChange('options', arr);
  };

  editOption = (index) => {
    const options = this.state.options.map((o, i) => {
      const isOption = index === i;

      return {
        ...o,
        edit: !!isOption,
      };
    });

    this.handleChange('edit', options[index].title);
    this.handleChange('options', options);
  };

  goToCategoryRoute = () => {
    const {
      navigate,
      location,
      params: { id, category },
    } = this.props;

    navigate(`/tests/${id}/categories/${category}`, {
      state: {
        org: location.state.org,
      },
    });
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleOption = (key, value, i) => {
    const arr = this.state.options;
    const option = arr[i];

    option[key] = value;

    this.handleChange('options', [
      ...arr.slice(0, i),
      option,
      ...arr.slice(i + 1, arr.length),
    ]);
  };

  handleOptionAction = (save, i) => {
    const options = this.state.options.map((o, index) => {
      const isOption = index === i;

      return {
        ...o,
        edit: false,
        title: !save && isOption ? this.state.edit : o.title,
      };
    });

    this.handleChange('options', options);
  };

  save = () => {
    const { testQuestionCreateMutation, testQuestionUpdateMutation } =
      this.props;
    const {
      category,
      content,
      correctAnswerIndex,
      id,
      isHTML,
      isRandomized,
      options,
      title,
    } = this.state;

    const mutation = id
      ? testQuestionUpdateMutation
      : testQuestionCreateMutation;
    const answers = options.filter((o) => o.title).map((o) => o.title);
    const questionTitle = isHTML ? content : title;
    const data = {
      answers,
      correctAnswerIndex,
      isRandomized,
      rank: category.questions.length + 1,
      title: questionTitle,
    };

    if (id) {
      // existing
      data.id = id;
    } else {
      // new
      data.categoryId = category.id;
    }

    if (!questionTitle) {
      window.alert('Question title is required.');
    } else if (answers.length < 2) {
      window.alert('More than one answer option is required.');
    } else if (correctAnswerIndex < 0) {
      window.alert('A correct answer is required.');
    } else {
      this.setState(
        {
          loading: true,
        },
        () => {
          mutation({
            variables: {
              input: data,
            },
          }).then((response) => {
            const key = Object.keys(response.data)[0];
            const errors = response.data[key].errors;

            if (errors) {
              this.handleChange('loading', false);
              window.alert(errors[0].message);
            } else {
              this.goToCategoryRoute();
            }
          });
        },
      );
    }
  };

  uploadFile = async (success, fail, blobInfo) => {
    const xhr = new XMLHttpRequest();
    const fileName = blobInfo.filename();
    const config = {
      type: `image/${fileName.split('.')[1]}`,
    };
    const file = new File([blobInfo.blob()], fileName, config);
    const formData = new FormData();

    formData.append('file', file);

    xhr.open('POST', process.env.REACT_APP_ATTACHMENT_URL);

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        const response = JSON.parse(xhr.response);
        const url = response.metadata.cloudinary.secure_url;

        success(url);
      }
    };

    xhr.send(formData);
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <Question
        {...this.props}
        addOption={this.addOption}
        deleteOption={this.deleteOption}
        editOption={this.editOption}
        goToCategoryRoute={this.goToCategoryRoute}
        handleChange={this.handleChange}
        handleOption={this.handleOption}
        handleOptionAction={this.handleOptionAction}
        save={this.save}
        state={this.state}
        uploadFile={this.uploadFile}
      />
    );
  }
}

export default compose(
  withRouter,
  graphql(TestQuery, {
    name: 'testQuery',
    options: (props) => ({
      variables: {
        id: props.params.id,
      },
    }),
  }),
  graphql(TestQuestionCreateMutation, { name: 'testQuestionCreateMutation' }),
  graphql(TestQuestionUpdateMutation, { name: 'testQuestionUpdateMutation' }),
)(QuestionCategoryContainer);
