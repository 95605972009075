import React, { useState, useEffect, useRef } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import moment from 'moment';
import { useParams, useNavigate } from 'react-router';
import { useQuery, useMutation } from 'react-apollo';
import DocumentSummaryQuery from '../../graphql/queries/Org/Documents/Summary';
import OrgContentRemoveMutation from '../../graphql/mutations/Org/Content/Remove';
import SignatureDocumentUpdateMutation from '../../graphql/mutations/Signature/SignatureDocumentUpdate';
import LoadingPane from '../Shared/LoadingPane';
import DeletePane from './Panes/Delete';

const DashboardDocuments = (props) => {
  const { user } = props;
  const params = useParams();
  const navigate = useNavigate();
  const deleteModalDoc = useRef({ title: 'This document' });
  const variable = { id: params.id };
  const [isModal, setIsModal] = useState(false);
  const [loadedData, setloadedData] = useState();
  const userRole = user.roles?.find((r) => r.org.id === params.id).name;
  const allowedRoles = ['corporate', 'super_admin', 'admin'];
  const canActivate = allowedRoles.includes(userRole);
  const [orgContentRemoveMutation] = useMutation(OrgContentRemoveMutation, {
    refetchQueries: [DocumentSummaryQuery],
  });
  const [signatureDocumentUpdateMutation] = useMutation(
    SignatureDocumentUpdateMutation,
    {
      refetchQueries: [DocumentSummaryQuery],
    },
  );

  const contentRemove = () => {
    orgContentRemoveMutation({
      variables: {
        input: {
          org: params.id,
          contentType: 'signatureDocument',
          contentId: deleteModalDoc.current.id,
        },
      },
    }).then((response) => {
      const {
        data: {
          orgContentRemove: { errors, success },
        },
      } = response;

      if (success) {
        setIsModal(false);
      } else {
        window.alert(errors[0].message);
      }
    });
  };

  const toggleModal = (doc, showModal) => {
    setIsModal(showModal);
    deleteModalDoc.current = doc;
  };

  let documents = [];
  const { loading, data } = useQuery(DocumentSummaryQuery, {
    variables: variable,
    fetchPolicy: 'cache-and-network',
  });
  const brandColor = '#CCCCCC';
  const secondaryColor = '#DDDDDD';
  const goToChecklistRoute = (docID) => {
    const routeID = docID ?? 'create';
    navigate(`${routeID}`);
  };

  useEffect(() => {
    setloadedData(data);
  }, [loading, data]);

  const setupDocuments = (inputDocuments) => {
    return inputDocuments.map((u) => {
      const newDoc = { ...u.node };

      return newDoc;
    });
  };

  const updateDocumentStatus = (id) => {
    const documentToUpdate = documents.find((doc) => doc.id === id);
    const inputData = {
      id,
      orgId: params.id,
      status: documentToUpdate.status === 'active' ? 'inactive' : 'active',
    };

    signatureDocumentUpdateMutation({
      variables: {
        input: inputData,
      },
    }).then((response) => {
      const key = Object.keys(response.data)[0];
      const { errors } = response.data[key];

      if (errors) {
        window.alert(errors[0].message);
      }
    });
  };

  if (loadedData) {
    documents = setupDocuments(loadedData?.org?.signatureDocuments.edges);
  }

  return (
    <div className="dashboard-documents-view full-screen-view">
      {loading && !loadedData ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}
      <>
        <div className="stats-frame">
          <div className="centered-frame">
            <div className="stat-frame">
              <div className="header">TOTAL</div>
              <div className="stat-label black">{documents.length}</div>
            </div>

            <div className="stat-frame middle">
              <div className="header">ACTIVE</div>
              <div
                className="stat-label"
                style={{
                  color:
                    documents.length > 0
                      ? secondaryColor
                        ? secondaryColor
                        : '#008489'
                      : '#919191',
                }}
              >
                {documents.filter((c) => c.status === 'active').length}
              </div>
            </div>

            <div className="stat-frame">
              <div className="header">INACTIVE</div>
              <div className="stat-label">
                {documents.filter((c) => c.status !== 'active').length}
              </div>
            </div>
          </div>
        </div>

        <div className="filter-frame">
          <div className="centered-frame">
            <button
              className="create-test-button"
              style={{ color: brandColor || '#FF5A5F' }}
              onClick={() => goToChecklistRoute()}
            >
              + Create New Document
            </button>
          </div>
        </div>

        <div className="tests-table-view">
          <div className="table-headers">
            <div className="centered-frame">
              <div className="table-header title">Title</div>
              {/* <div className='table-header duration'>Duration</div> */}
              <div className="table-header date">Date Modified</div>
              <div className="table-header expiration">Annual Expirtation</div>
              <div className="table-header status">Status</div>
            </div>
          </div>

          {documents.length > 0 ? (
            <ul className="tests-list-view scroll">
              {documents.map((document) => {
                const { id, annualExpiration, title, updatedAt, status } =
                  document;
                //const isOrg = true; //orgId === org?.id;
                const hasPermission = true; //admin || isOrg;

                return (
                  <li
                    className={
                      hasPermission ? 'test-cell' : 'test-cell disabled'
                    }
                    key={id}
                  >
                    <div className="centered-frame">
                      <div
                        className="cell-content-frame"
                        onClick={() =>
                          hasPermission ? goToChecklistRoute(id) : null
                        }
                      >
                        <div className="cell-label title">{title}</div>

                        <div className="cell-label date">
                          {moment(new Date(updatedAt * 1000)).format(
                            'MMM D, YYYY',
                          )}
                        </div>
                        <div className="cell-label expiration">
                          {annualExpiration.toString()}
                        </div>
                        <div className="cell-label status">{status}</div>
                      </div>

                      <OverlayTrigger
                        trigger="click"
                        placement="bottom"
                        rootClose
                        overlay={
                          <Popover id="menu-pane" className="menu-pane">
                            <div
                              className="menu-content-view"
                              style={{ padding: '5px 0px' }}
                            >
                              <button
                                className="menu-button teal"
                                onClick={() => goToChecklistRoute(id)}
                              >
                                View Details
                              </button>
                              {canActivate ? (
                                <button
                                  className="menu-button teal"
                                  onClick={() => updateDocumentStatus(id)}
                                >
                                  {status === 'active'
                                    ? 'Deactivate'
                                    : 'Activate'}
                                </button>
                              ) : null}
                              <button
                                className="menu-button red"
                                onClick={() => toggleModal(document, true)}
                              >
                                Delete
                              </button>
                            </div>
                          </Popover>
                        }
                      >
                        <div
                          className={hasPermission ? 'expander-icon' : 'hide'}
                        >
                          <img
                            src={require('../../theme/images/expander-icon-grey-2x.png')}
                            alt="expander-icon"
                          />
                        </div>
                      </OverlayTrigger>
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : (
            <div className="layout-center hint empty-label">
              There are no documents to display.
            </div>
          )}
        </div>

        <DeletePane
          message={`Are you sure you want to remove "${deleteModalDoc.current.title}" from your organization?`}
          onHide={() => toggleModal({}, false)}
          save={contentRemove}
          show={isModal}
        />
      </>
    </div>
  );
};

export default DashboardDocuments;
