import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import moment from 'moment';

import LoadingPane from '../Shared/LoadingPane';
import DeletePane from './Panes/Delete';

const DashboardBundles = (props) => {
  const { contentRemove, goToBundleRoute, state, toggleModal } = props;
  const { brandColor, bundles, bundle, isModal, loading } = state;
  const typeMappingOptions = [
    { value: 'ecp_job', label: 'Ecp Job' },
    { value: 'normal', label: 'Normal' }
  ]

  return (
    <div className="dashboard-bundles-view full-screen-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      <div className="filter-frame">
        <div className="centered-frame">
          <button
            className="create-test-button"
            style={{ color: brandColor || '#FF5A5F' }}
            onClick={() => goToBundleRoute()}
          >
            + Create New Bundle
          </button>
        </div>
      </div>

      <div className="content-frame">
        {bundles.length > 0 ? (
          <div className="bundles-table-view">
            <div className="table-headers">
              <div className="centered-frame">
                <div className="table-header title">Title</div>
                <div className="table-header items">Type</div>
                <div className="table-header items">Tests</div>
                <div className="table-header items">Checklists</div>
                <div className="table-header items">Mandatories</div>
                <div className="table-header date">Date Modified</div>
              </div>
            </div>

            <div className="bundles-table-view">
              <ul className="bundles-list-view">
                {bundles.map((bundle) => {
                  const {
                    bundleType,
                    checklists,
                    courses,
                    id,
                    tests,
                    title,
                    updatedAtIso,
                  } = bundle;

                  return (
                    <li className="bundle-cell" key={id}>
                      <div className="centered-frame">
                        <div
                          className="cell-content-frame"
                          onClick={() => goToBundleRoute(id)}
                        >
                          <div className="cell-label title bold">{title}</div>
                          <div className="cell-label items">{typeMappingOptions.find((val) => val.value === bundleType).label}</div>
                          <div className="cell-label items">{tests.length}</div>
                          <div className="cell-label items">
                            {checklists.length}
                          </div>
                          <div className="cell-label items">
                            {courses.length}
                          </div>
                          <div className="cell-label date">
                            {moment(updatedAtIso).format('MMM D, YYYY')}
                          </div>
                        </div>

                        <OverlayTrigger
                          className="menu-pane bundle"
                          trigger="click"
                          placement="bottom"
                          rootClose
                          overlay={
                            <Popover
                              id="menu-pane"
                              className="menu-pane bundle"
                            >
                              <div
                                className="menu-content-view"
                                style={{ padding: '5px 0px' }}
                              >
                                <button
                                  className="menu-button red"
                                  onClick={() => toggleModal(bundle)}
                                >
                                  Delete
                                </button>
                              </div>
                            </Popover>
                          }
                        >
                          <div className="expander-icon">
                            <img
                              src={require('../../theme/images/expander-icon-grey-2x.png')}
                              alt="expander-icon"
                            />
                          </div>
                        </OverlayTrigger>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        ) : (
          <div className="layout-center">
            <div className="empty-label">There are no bundles to display.</div>
          </div>
        )}
      </div>

      <DeletePane
        message={`Are you sure you want to remove the "${bundle ? bundle.title : ''
          }" bundle from your organization?`}
        onHide={() => toggleModal()}
        save={contentRemove}
        show={isModal}
      />
    </div>
  );
};

export default DashboardBundles;
