import { gql } from 'apollo-boost';

const FeedbackOnSave= gql `
mutation ManagerDetailsCreate($input: ManagerFeedbackCreateInput) {
    managerFeedbackCreate(input: $input) {
      success
      result {
        id
        feedbackResponse
      }
      errors {
        code
        message
        backtrace
      }
    }
  }`
  export default  FeedbackOnSave



// const FeedbackRequestCreateMutation = gql`
// mutation FeedbackRequestMutation($input: FeedbackRequestCreateCreateInput) {
//   feedbackrequestCreate(input: $input) {
//     success
//     result {
//       ulid
//       email
//     }
//     errors {
//         message
//     }
//   }
// }`;


// export default FeedbackRequestCreateMutation;