import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { Editor } from '@tinymce/tinymce-react';
import LoadingPane from '../Shared/LoadingPane';
import Toolbar from '../Shared/Toolbar';

import DragList from '../../containers/Shared/DragList';

const ChapterDetails = (props) => {
  const {
    deleteQuestion,
    goToCourseRoute,
    goToQuestionRoute,
    handleChange,
    save,
    state,
    uploadFile,
  } = props;
  const { course, content, loading, questions, title } = state;

  return (
    <div className="chapter-view full-screen-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      <Toolbar
        cancel={goToCourseRoute}
        save={() => save()}
        title={title}
        subTitle={`Course - ${course.title}`}
      />

      <div className="content-frame">
        <div className="centered-frame">
          <div className="section-frame">
            <div className="section-header-frame">
              <div className="section-header">Chapter Details</div>
            </div>

            <div className="editor-frame">
              <div className="editor-header">Description</div>

              <Editor
                apiKey="h7bm46vfm1xprr1ijahtihbudut860sz70a8ltrwc4dm1x5r"
                className="editor-input"
                initialValue={
                  content && content.length > 0
                    ? content
                    : '<p>This is the initial content of the editor</p>'
                }
                init={{
                  width: '100%',
                  height: 300,
                  images_upload_url: 'postAcceptor.php',
                  images_upload_handler: (blob, success, fail) =>
                    uploadFile(success, fail, blob),
                  menubar: false,
                  statusbar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code wordcount',
                  ],
                  content_style: '.mce-content-body {font-size:12px}',
                  toolbar:
                    'undo redo | formatselect forecolor fontsizeselect | bold italic underline backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image',
                }}
                onEditorChange={(html) => handleChange('content', html)}
                value={content}
              />
            </div>
          </div>

          <div className="section-frame">
            <div className="section-header-frame">
              <div className="section-header">{`Questions (${questions.length})`}</div>

              <button
                className="section-button"
                onClick={() => save(() => goToQuestionRoute())}
              >
                + Add Question
              </button>
            </div>

            {questions.length === 0 ? (
              <div className="empty-hint hint">
                No questions have been added.
              </div>
            ) : (
              <div className="questions-table-view">
                <div className="table-headers">
                  <div className="table-header title">Title</div>
                  <div className="table-header questions">Answer Options</div>
                </div>

                <DragList
                  onChange={(arr) => handleChange('questions', arr)}
                  data={questions.map((obj, i) => {
                    const { answers, id: questionId, title } = obj;
                    const last = i === questions.length - 1;

                    return {
                      ...obj,
                      content: (
                        <div
                          className="question-cell"
                          style={{ borderWidth: last ? 0 : 1 }}
                        >
                          <div className="cell-content-frame">
                            <div className="drag-icon">
                              <img
                                draggable={false}
                                src={require('../../theme/images/hashes.png')}
                                alt="hashes-icon"
                              />
                            </div>

                            <div className="cell-label question-title">
                              {title}
                            </div>
                            <div className="cell-label questions">
                              {answers.length}
                            </div>
                          </div>

                          <OverlayTrigger
                            trigger="click"
                            placement="bottom"
                            rootClose
                            overlay={
                              <Popover id="menu-pane" className="menu-pane">
                                <div
                                  className="menu-content-view"
                                  style={{ padding: '5px 0px' }}
                                >
                                  <button
                                    className="menu-button teal"
                                    onClick={() =>
                                      goToQuestionRoute(questionId)
                                    }
                                  >
                                    View Question
                                  </button>
                                  <button
                                    className="menu-button red"
                                    onClick={() => deleteQuestion(obj, i)}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </Popover>
                            }
                          >
                            <div className="expander-icon">
                              <img
                                src={require('../../theme/images/expander-icon-grey-2x.png')}
                                alt="expander-icon"
                              />
                            </div>
                          </OverlayTrigger>
                        </div>
                      ),
                    };
                  })}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChapterDetails;
