import { gql } from 'apollo-boost';

const ChecklistSummaryRemoveMutation = gql`
  mutation ChecklistSummaryRemoveMutation($input: IDInput) {
    checklistSummaryRemove(input: $input) {
      errors {
        message
      }
      result {
        id
        categories {
          id
          sections {
            id
            questions {
              id
              rank
              title
            }
            rank
            title
          }
        }
        durationMins
        status
        title
        updatedAt
      }
    }
  }
`;

export default ChecklistSummaryRemoveMutation;
