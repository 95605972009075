import { gql } from 'apollo-boost';

const CourseCreateMutation = gql`
  mutation CourseCreateMutation($input: CourseSummaryCreateCreateInput) {
    courseCreate(input: $input) {
      errors {
        code
        message
      }
      result {
        id
        chapters {
          id
          questions {
            id
          }
        }
        durationMins
        status
        title
        updatedAt
        
      }
    }
  }
`;

export default CourseCreateMutation;
