import { gql } from 'apollo-boost';

const ChecklistQuestionUpdateMutation = gql`
  mutation ChecklistQuestionUpdateMutation(
    $input: ChecklistQuestionUpdateInput
  ) {
    checklistQuestionUpdate(input: $input) {
      errors {
        message
      }
      result {
        id
        rank
        title
        updatedAt
      }
    }
  }
`;

export default ChecklistQuestionUpdateMutation;
