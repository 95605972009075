import { gql } from 'apollo-boost';

const ChecklistSectionUpdateMutation = gql`
  mutation ChecklistSectionUpdateMutation($input: ChecklistSectionUpdateInput) {
    checklistSectionUpdate(input: $input) {
      errors {
        message
      }
      result {
        id
        questions {
          id
          rank
          title
        }
        rank
        title
      }
    }
  }
`;

export default ChecklistSectionUpdateMutation;
