import React, { useEffect, useState } from 'react';

const Switch = (props) => {
  const [enabled, setEnabled] = useState(props.defaultValue);

  useEffect(() => {
    setEnabled(props.defaultValue);
  }, [props.defaultValue]);

  const toggle = () => {
    const newValue = !enabled;
    props.onChange(newValue);
    setEnabled(newValue);
  };
  const enabledClassName = enabled
    ? 'toggle-switch slide-right'
    : 'toggle-switch slide-left';

  return (
    <div className={enabledClassName} onClick={toggle}>
      <div className="ellipse" />
    </div>
  );
};

export default Switch;
