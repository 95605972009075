import React from 'react';
import renderHTML from 'react-render-html';

import moment from 'moment';
import { orderBy } from 'lodash';

import evpBadge from '../../theme/images/evp-badge-version-3.png';
import colorVisionBadge from '../../theme/images/color-vision-logo.png';
import logo from '../../theme/images/hire-logo-2x.png'

const statuses = {
  finished: 'Completed',
};

const RosterTest = (props) => {
  const { back, handleAnimation, state, user, orgId } = props;

  const org = orgId;
  const role = user.roles.find((r) => r.org.id === org);
  const isCorporate = role ? role.name === 'corporate' : false;
  const {
    animations,
    attachment,
    firstName,
    lastName,
    specialties,
    testTaken,
  } = state;
  const {
    answers,
    endedAt,
    startedAt,
    timeSpentSecs,
    passed,
    score,
    status,
    test,
  } = testTaken;

  const questions = test.categories.map((c) => c.questions).flat();
  const answersArray = answers.filter((a) => !a.isCorrect);
  if (questions > answers) {
    const answeredQuestions = answers.map((a) => a.question.id);
    const unanswered = questions.filter(
      (q) => !answeredQuestions.includes(q.id),
    );

    unanswered.forEach((q) => {
      const answer = {
        question: q,
        value: 'Not Answered',
      };
      answersArray.push(answer);
    });
  }

  const specialty = specialties.find((s) => s.isPrimary);

  const isRule1 = test.desc ? test.desc.includes('__rule-1__') : false;
  const invalid = [];
  console.log(testTaken.test.categories.length);
  console.log(testTaken.test.categories.length * 61);

  return (
    <div className="roster-test-view full-screen-view">
      <div className="toolbar">
        <button className={user ? 'cancel-button' : 'hide'} onClick={back}>
          Back
        </button>

        <div className="print-button" onClick={() => window.print()}>
          <img
            src={require('../../theme/images/print-icon-2x.png')}
            alt="print-icon"
          />
        </div>
      </div>

      <div className="content-frame scroll">
        <div className="centered-frame">
          <div className="logo-frame">
            <div className="company-logo">
              {attachment ? (
                <img src={attachment.url} alt="company-logo" />
              ) : null}
            </div>

            {isRule1 ? (
              <div className="color-vision-logo">
                <img
                  src={logo}
                  alt="color-vision-logo"
                />
              </div>
            ) : (
              <div
                className={
                  test.kind !== 'global' || invalid.includes(test.id)
                    ? 'hide'
                    : 'flex-frame'
                }
              >
                <div className="evp-badge-logo">
                  <img
                    src={evpBadge}
                    alt="evp-badge-logo"
                  />
                </div>

                <div className="una-logo">
                  <img
                    src={logo}
                    alt="una-logo"
                  />
                </div>
              </div>
            )}
          </div>

          <div className="profile-frame">
            <div className="section-frame">
              <div className="section-header">PROFESSIONAL</div>

              <div className="section-value">{`${firstName} ${lastName}`}</div>

              <div className={specialty ? 'section-subvalue' : 'hide'}>
                {specialty ? specialty.name : ''}
              </div>
            </div>

            <div className="section-frame">
              <div className="section-header">TEST</div>

              <div className="section-value">{test.title}</div>

              {!isCorporate && (
                <div className={endedAt ? 'section-subvalue' : 'hide'}>{`${statuses[status]
                  } - ${moment(endedAt).format('MMM DD, YYYY')}`}</div>
              )}
              {isCorporate && (
                <>
                  <div className={startedAt ? 'section-subvalue' : 'hide'}>{`
                  Started
                 - ${moment.utc(startedAt).local().format('MMM DD, YYYY HH:mm:ss')}`}</div>
                  <div className={endedAt ? 'section-subvalue' : 'hide'}>{`
                  Completed
                 - ${moment.utc(endedAt).local().format('MMM DD, YYYY HH:mm:ss')}`}</div>
                  <div className={timeSpentSecs ? 'section-subvalue' : 'hide'}>{`
                  Duration
                 - ${moment
                      .utc(Math.round(timeSpentSecs) * 1000)
                      .format('H:mm:ss')}`}</div>
                </>
              )}
            </div>

            <div className="section-frame">
              <div className="section-header">OVERALL SCORE</div>

              <div className="section-value">{`${score}%`}</div>

              <div className="flex-frame">
                <div
                  className={`section-subvalue ${passed ? 'green' : 'orange'}`}
                >
                  {passed ? 'Pass' : 'Did Not Pass'}
                </div>

                <div className="score-label">{`- Angoff Score: ${test.passingScore}%`}</div>
              </div>
            </div>
          </div>

          <div
            className={`categories-frame ${animations.categories ? 'collapse no-print' : ''
              }`}
            style={{
              height: animations.categories
                ? 73
                : testTaken.test.categories.length * 61 + 75,
            }}
          >
            <div className="header-frame">
              <div className="header">Category Breakdown</div>

              <button
                className="collapse-button"
                onClick={() => handleAnimation('categories')}
              >
                {animations.categories ? 'Expand' : 'Collapse'}
              </button>
            </div>

            <ul className="categories-list-view">
              {orderBy(testTaken.test.categories, 'rank').map((category) => {
                const { id, title } = category;

                const matchingQs = category.questions.map((q) =>
                  answers.find((a) => a.question.id === q.id),
                );
                const correct = matchingQs.filter((o) => o && o.isCorrect);
                const pct =
                  correct.length > 0
                    ? Math.round((correct.length / matchingQs.length) * 100)
                    : 0;

                return (
                  <li className="category-cell" key={id}>
                    <div className="cell-label bold">{title}</div>
                    <div className="cell-label">{`${correct.length} of ${matchingQs.length} Correct - ${pct}%`}</div>
                  </li>
                );
              })}
            </ul>
          </div>

          {isRule1 ? (
            <div className="empty-frame">
              <div className="layout-center">
                <div className="empty-label">
                  A <b>failing</b> score indicates the need for follow up with
                  an optometrist for a more comprehensive test to diagnose color
                  vision deficiency.
                </div>
              </div>
            </div>
          ) : score < 60 && !isCorporate ? (
            <div className="empty-frame">
              <div className="layout-center">
                <div className="empty-label hint">
                  {
                    'We are unable to share the incorrectly answered questions for any test results under 60% in an effort to preserve the security and integrity of our tests.'
                  }
                </div>
              </div>
            </div>
          ) : answersArray.length > 0 ? (
            <div className="questions-frame">
              <div className="header">{`incorrectly answered questions (${answersArray.length})`}</div>

              <ul className="questions-list-view">
                {answersArray.map((answer, i) => {
                  const { id, isCorrect, question, value } = answer;

                  const regex = /<\/?[a-z][\s\S]*>/i;
                  const isHTML = regex.test(question.title);

                  return isCorrect ? (
                    <li className="question-cell correct" key={id}>
                      <div className="flex-frame">
                        <div className="question-label bold">{`Q${i + 1}`}</div>
                        <div className="question-label green">CORRECT</div>
                      </div>
                    </li>
                  ) : (
                    <li className="question-cell" key={id}>
                      <div className="question-label bold">{`Q${i + 1}.`}</div>

                      <div className="flex-frame horizontal">
                        <div className="question-label title">
                          {isHTML ? renderHTML(question.title) : question.title}
                        </div>
                        <div className="question-label answer red">{value}</div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default RosterTest;
