import React from 'react';

import PDF from './PDF';
import Select from './Select';

const RosterPacket = (props) => {
  const {
    state: { pdf },
  } = props;

  return pdf ? <PDF {...props} /> : <Select {...props} />;
};

export default RosterPacket;
