import React, { useEffect, useState } from "react";
import { graphql, useQuery } from "react-apollo";
import JobPostQuery from "../../graphql/queries/JobPost";
import { useNavigate, useParams } from "react-router";
import Toolbar from "../Shared/Toolbar";
import './job-post.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faSort } from "@fortawesome/free-solid-svg-icons";
import 'bootstrap/dist/css/bootstrap.min.css';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import JobCandidatesQuery from "../../graphql/queries/JobCandidate";
import compose from 'lodash.flowright';
import { withRouter } from "../../containers/withRouter";

const JobPost = () => {
  const [jobPost, setJobPost] = useState();
  const { id, job } = useParams();
  const navigate = useNavigate();

  const [candidatesTableData, setCandidatesTableData] = useState([]);

  const [sortSkillsAsc, setSortSkillsAsc] = useState(false);
  const [sortTestsAsc, setSortTestsAsc] = useState(false);
  const [sortMandatAsc, setSortMandatAsc] = useState(false);
  let [isArrayChanged, setIsArrayChaged] = useState({});

  const { loading, data } = useQuery(JobPostQuery, {
    variables: { id: job }
  });

  const candidatesTableDataResponse = useQuery(JobCandidatesQuery, {
    variables: { id: job }
  });

  useEffect(() => {
    setCandidatesTableData(candidatesTableDataResponse?.data?.jobPost);
  }, [candidatesTableDataResponse, candidatesTableData]);

  useEffect(() => {
    setCandidatesTableData(candidatesTableData);
  }, [isArrayChanged]);

  useEffect(() => {
    if (!loading) {
      setJobPost(data?.jobPost);
    }
  }, [loading, data]);


  const goToJobPostsRoute = () => {
    navigate(`/organizations/${id}/jobposts`);
  };

  const goToCandidateProfile = (canId) => {
    navigate(`/organizations/${id}/roster/${canId}`);
  }

  const sortBy = (colName) => {
    let sortedApplicants = [];
    switch (colName) {
      case 'skills':
        if (sortSkillsAsc)  // if Asc is true and the sort is clicked means it should go descending
        {
          sortedApplicants = [...candidatesTableData.jobPostApplicants].sort((a, b) => {
            return (a.candidateJobAnalysis?.compulsory_checklist || 0) - (b.candidateJobAnalysis?.compulsory_checklist || 0);
          });
          setSortSkillsAsc(false);
        }
        else {
          sortedApplicants = [...candidatesTableData.jobPostApplicants].sort((a, b) => {
            return (b.candidateJobAnalysis?.compulsory_checklist || 0) - (a.candidateJobAnalysis?.compulsory_checklist || 0);
          });
          setSortSkillsAsc(true);
        }
        break;

      case 'tests':
        if (sortTestsAsc)  // if Asc is true and the sort is clicked means it should go descending
        {
          sortedApplicants = [...candidatesTableData.jobPostApplicants].sort((a, b) => b.candidateJobAnalysis?.compulsory_test - a.candidateJobAnalysis?.compulsory_test);
          setSortTestsAsc(false);
        }
        else {
          sortedApplicants = [...candidatesTableData.jobPostApplicants].sort((a, b) => a.candidateJobAnalysis?.compulsory_test - b.candidateJobAnalysis?.compulsory_test);
          setSortTestsAsc(true);
        }
        break;

      case 'mandat':
        if (sortMandatAsc)  // if Asc is true and the sort is clicked means it should go descending
        {
          sortedApplicants = [...candidatesTableData.jobPostApplicants].sort((a, b) => a.candidateJobAnalysis?.compulsory_course - b.candidateJobAnalysis?.compulsory_course);

          setSortMandatAsc(false);
        }
        else {

          sortedApplicants = [...candidatesTableData.jobPostApplicants].sort((a, b) => b.candidateJobAnalysis?.compulsory_course - a.candidateJobAnalysis?.compulsory_course);

          setSortMandatAsc(true);
        }
        break;
    }
    setCandidatesTableData({
      ...candidatesTableData,
      jobPostApplicants: sortedApplicants
    });
    setIsArrayChaged({});
  }

  return (
    <>
      <div className="job-post-view">
        {jobPost && (
          <>
            <Toolbar
              back={goToJobPostsRoute}
              isToggle={false}
              title={jobPost.title}
              subTitle={'Ecp Job'}
            />
            <div className="content-frame scroll">
              <div className="centered-frame">
                <div className="job-details-container">
                  <div className="job-post-heading-container">
                    <div className="job-post-title-container">
                      <div className="job-post-title">{jobPost.title}</div>
                      <div>{jobPost.hiringManagerName}</div>
                    </div>
                    <div>
                      <div>Job ID</div>
                      <div className="job-id-value">{jobPost.ecpJobId}</div>
                    </div>
                  </div>

                  <div className="job-labels-container">
                    <div className="label-cell-left">
                      <div className="label-heading">Address</div>
                      <div className="label-value">{jobPost.locationAddress ? jobPost.locationAddress + ', ' : ''}
                        {jobPost.locationCity ? jobPost.locationCity + ', ' : ''}
                        {jobPost.locationState ? jobPost.locationState + ', ' : ''}
                        {jobPost.locationCountry} - {jobPost.locationPostalCode}
                      </div>
                    </div>

                    <div className="label-cell-subcontainer">
                      <div>
                        <div className="label-cell">
                          <div className="label-heading">Post Expiry Date</div>
                          <div className="label-value">{jobPost.jobExpire}</div>
                        </div>
                        <div className="label-cell">
                          <div className="label-heading">Location</div>
                          <div className="label-value">{jobPost.locationType}</div>
                        </div>
                      </div>
                    </div>

                    <div className="label-cell-subcontainer">
                      <div>
                        <div className="label-cell">
                          <div className="label-heading">Salary Type</div>
                          <div className="label-value">{jobPost.salaryType}</div>
                        </div>
                        <div className="label-cell">
                          <div className="label-heading">Salary Range</div>
                          <div className="label-value">{jobPost.salaryTypeMinimum} - {jobPost.salaryTypeMaximum}</div>
                        </div>
                      </div>
                    </div>

                    <div className="label-cell-subcontainer">
                      <div>
                        <div className="label-cell">
                          <div className="label-heading">Specialty</div>
                          <div className="label-value">{jobPost.specialty || '-'}</div>
                        </div>
                        <div className="label-cell">
                          <div className="label-heading">Type</div>
                          <div className="label-value">{jobPost.jobType}</div>
                        </div>
                      </div>
                    </div>

                    <div className="label-cell-subcontainer">
                      <div>
                        <div className="label-cell">
                          <div className="label-heading">Experience</div>
                          <div className="label-value">{jobPost.experience}</div>
                        </div>
                        <div className="label-cell">
                          <div className="label-heading">Applied</div>
                          <div className="label-value">{jobPost.applicantsCount}</div>
                        </div>
                      </div>
                    </div>

                    <div className="label-cell-right" title={jobPost.benefits}>
                      <div className="label-heading">Benefits</div>
                      <div className="label-value">{jobPost.benefits}</div>
                    </div>
                  </div>
                </div>

                <div className="data-table-container">
                  <div className="note-container">
                    <span>Note: </span> The test section has both
                    <span className="jobpost-text-danger"> required</span> and total tests.
                    <span className="jobpost-text-danger"> 3</span>-(7) =
                    <span className="jobpost-text-danger"> 3 required</span>, 7 total. Hover to see optional tests taken.</div>
                  <div className="data-table-heading-container"></div> {/* blue background behind table heading */}
                  <table className="data-table">
                    <thead>
                      <tr className="table-headings">
                        <td>Sr. No.</td>
                        <td>Name</td>
                        <td>Email</td>
                        <td className="sortable">Skills Checklist&nbsp;
                          <span className="jobpost-text-danger">{candidatesTableData?.skillChecklistCounts?.optional_checklist}</span>
                          -({candidatesTableData?.skillChecklistCounts?.compulsory_checklists + candidatesTableData?.skillChecklistCounts?.optional_checklist})&nbsp;
                          <span className="text-small hover-grey" onClick={() => sortBy('skills')}>
                            <FontAwesomeIcon icon={faSort} />
                          </span>

                        </td>
                        <td>Tests&nbsp;
                          <span className="jobpost-text-danger">{candidatesTableData?.testCounts?.compulsory_tests}</span>
                          -({candidatesTableData?.testCounts?.compulsory_tests + candidatesTableData?.testCounts?.optional_tests})
                          &nbsp;
                          <span className="text-small hover-grey" onClick={() => sortBy('tests')}>
                            <FontAwesomeIcon icon={faSort} />
                          </span>
                        </td>
                        <td>Mandatories&nbsp;
                          <span className="jobpost-text-danger">{candidatesTableData?.courseCounts?.compulsory_courses}</span>
                          -({candidatesTableData?.courseCounts?.optional_courses + candidatesTableData?.courseCounts?.compulsory_courses})
                          &nbsp;
                          <span className="text-small hover-grey" onClick={() => sortBy('mandat')}>
                            <FontAwesomeIcon icon={faSort} />
                          </span>
                        </td>
                        <td>Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {(candidatesTableData?.jobPostApplicants || []).map((can, index) => (
                        <tr className="data-row" key={index}>
                          <td>{index + 1}</td>
                          <td>{can.fullName}</td>
                          <td>{can.email}</td>
                          <td>
                            <OverlayTrigger
                              overlay={(props) => (
                                <Tooltip {...props}>Required Checklist</Tooltip>
                              )}
                              placement="top"
                            >
                              <span className="jobpost-text-danger">{can.candidateJobAnalysis?.compulsory_checklist}</span>
                            </OverlayTrigger>

                            <OverlayTrigger
                              overlay={(props) => (
                                <Tooltip {...props}> Optional: {can?.candidateJobAnalysis?.optional_checklist} , Total: <span className="text-bold"></span> {can?.candidateJobAnalysis?.optional_checklist + can?.candidateJobAnalysis?.compulsory_checklist}</Tooltip>
                              )}
                              placement="top"
                            >
                              <span>-({can?.candidateJobAnalysis?.optional_checklist + can?.candidateJobAnalysis?.compulsory_checklist})</span>

                            </OverlayTrigger>
                          </td>
                          <td>
                            <OverlayTrigger
                              overlay={(props) => (
                                <Tooltip {...props}>Required Tests</Tooltip>
                              )}
                              placement="top"
                            >
                              <span className="jobpost-text-danger">{can?.candidateJobAnalysis?.compulsory_test}</span>
                            </OverlayTrigger>

                            <OverlayTrigger
                              overlay={(props) => (
                                <Tooltip {...props}> Optional: {can?.candidateJobAnalysis?.optional_test} , Total: <span className="text-bold"></span> {can?.candidateJobAnalysis?.optional_test + can?.candidateJobAnalysis?.compulsory_test}</Tooltip>
                              )}
                              placement="top"
                            >
                              <span>-({can?.candidateJobAnalysis?.optional_test + can?.candidateJobAnalysis?.compulsory_test})</span>
                            </OverlayTrigger>

                          </td>

                          <td>
                            <OverlayTrigger
                              overlay={(props) => (
                                <Tooltip {...props}>Required Courses</Tooltip>
                              )}
                              placement="top"
                            >
                              <span className="jobpost-text-danger">{can?.candidateJobAnalysis?.compulsory_course}</span>
                            </OverlayTrigger>

                            <OverlayTrigger
                              overlay={(props) => (
                                <Tooltip {...props}>Optional: {can?.candidateJobAnalysis?.optional_course}, Total: {can?.candidateJobAnalysis?.compulsory_course + can?.candidateJobAnalysis?.optional_course} </Tooltip>
                              )}
                              placement="top"
                            >
                              <span>-({can?.candidateJobAnalysis?.compulsory_course + can?.candidateJobAnalysis?.optional_course})</span>
                            </OverlayTrigger>
                          </td>
                          <td>
                            <a className="action-button" onClick={() => goToCandidateProfile(can?.id)} title={'Go to ' + can.fullName + '\'s profile'}>
                              {/* <FontAwesomeIcon icon={faEye} /> */}
                              View
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {candidatesTableData?.jobPostApplicants?.length === 0 ? <p className="no-applicants">No candidates found</p> : ''}
                </div>
              </div>
            </div>
          </>
        )}
      </div >
    </>
  )
}

export default compose(
  withRouter,
  graphql(JobCandidatesQuery, {
    name: 'jobCandidatesQuery',
    options: (props) => {
      return {
        variables: {
          id: props.params.job
        }
      }
    }
  }))(JobPost);