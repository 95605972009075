import React from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose, set } from 'lodash';

import Cookie from 'js-cookie';

import UserQuery from '../../../graphql/queries/Document';

import EducationDocument from '../../../components/Documents/Education';

import LoadingPane from '../../../components/Shared/LoadingPane';

class EducationDocumentContainer extends React.Component {
  constructor(props) {
    super(props);

    const {
      params: { document },
      mobile,
    } = props;

    this.state = {
      edit: document === 'create',
      kind: 'education',
      isModal: false,
      issuingLocation: '',
      issuingState: '',
      loading: false,
      mobile,
      onload: document !== 'create',
      user: props.context.user,
    };
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(prevProps) {
    const { mobile } = this.props;

    this.setup(prevProps.mobile !== mobile);
  }

  setup = (mobileDidChange) => {
    const {
      mobile,
      userQuery: { loading, user },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      this.setState({
        ...user.document,
        mobile: mobileDidChange ? mobile : this.state.mobile,
        onload: false,
      });
    } else if (mobileDidChange) {
      this.handleChange('mobile', mobile);
    }
  };

  back = () => {
    const { params, navigate, location } = this.props;

    if (!location.state || location.state?.previous === '/login') {
      navigate(`/organizations/${params.id}/roster/${params.roster}`);
      return;
    }
    navigate(-1);
  };

  documentRemove = (hide) => {
    const { documentRemoveMutation } = this.props;

    this.setState(
      {
        loading: true,
      },
      () => {
        documentRemoveMutation({
          variables: {
            input: {
              id: this.state.id,
            },
          },
        }).then((response) => {
          const { errors, success } = response.data.documentRemove;

          if (success) {
            setTimeout(() => {
              hide();

              setTimeout(() => this.back(), 450);
            }, 200);
          } else {
            this.handleChange('loading', false);

            window.alert(errors[0].message);
          }
        });
      },
    );
  };

  handleChange = (key, value) => {
    this.setState(set(this.state, key, value));
  };

  handlePlace = (name, city, state) => {
    this.setState({
      issuerName: name,
      city,
      state,
    });
  };

  save = () => {
    const { documentCreateMutation, documentUpdateMutation } = this.props;
    const {
      file,
      kind,
      id,
      issuerLocation,
      issuerName,
      issuedOn,
      issuingState,
      title,
    } = this.state;

    const missingCity = !issuerLocation && issuingState;
    const missingState = issuerLocation && !issuingState;

    if (missingCity || missingState) {
      window.alert('Location requires issuing city AND state.');
    } else if (issuerName && title && issuedOn) {
      this.setState(
        {
          loading: true,
        },
        async () => {
          const mutation = id ? documentUpdateMutation : documentCreateMutation;
          const data = {
            documentNumber: title,
            kind,
            title,
            issuedOn,
            issuerName,
            issuerLocation,
            issuingState,
          };

          if (file) data.image = await this.saveAttachment();

          // document exists
          if (id) {
            data.id = id;
          } else {
            data.userId = Cookie.get(process.env.REACT_APP_COOKIE_NAME);
          }

          mutation({
            variables: {
              input: data,
            },
          }).then((response) => {
            const key = Object.keys(response.data)[0];
            const { errors, success } = response.data[key];

            if (success) {
              this.back();
            } else {
              this.handleChange('loading', false);

              window.alert(errors[0].message);
            }
          });
        },
      );
    }
  };

  saveAttachment = () => {
    const { file } = this.state;

    return new Promise((success) => {
      const xhr = new XMLHttpRequest();
      const fileName = file.name;
      const config = {
        type: `image/${fileName.split('.')[1]}`,
      };
      const f = new File([file.base64], fileName, config);
      const formData = new FormData();

      formData.append('file', f);

      xhr.open('POST', process.env.REACT_APP_ATTACHMENT_URL);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          success(JSON.parse(xhr.response));
        }
      };

      xhr.send(formData);
    });
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <EducationDocument
        {...this.props}
        back={this.back}
        handleChange={this.handleChange}
        state={this.state}
      />
    );
  }
}

export default compose(
  graphql(UserQuery, {
    name: 'userQuery',
    options: ({ params: { roster, item } }) => ({
      variables: {
        id: roster,
        document: item,
      },
    }),
  }),
)(EducationDocumentContainer);
