import React, { useState } from 'react';

function FeedbackCheckbox({ id, checked, onChange }) {
    const [showMessage, setShowMessage] = useState(false);

    return (
        <div
            style={{ position: 'relative', display: 'inline-block' }}
            onMouseEnter={() => setShowMessage(true)}
            onMouseLeave={() => setShowMessage(false)}
        >
            <input
                title="You cannot give feedback while the checkbox is checked"
                type="checkbox"
                id={`feedbackCheckbox_${id}`}
                name={`feedbackCheckbox_${id}`}
                checked={checked}
                onChange={onChange}
                onClick={() => setShowMessage(true)} // Show message on click
                style={{ marginRight: '5px', WebkitAppearance: 'auto' }} // Adjust spacing between checkbox and hover text
            />
            {showMessage && checked && (
                <div
                    style={{
                        position: 'absolute',
                        top: '100%', // Adjust position to display below the checkbox
                        left: '50%',
                        transform: 'translateX(-50%)',
                        marginTop: '5px', // Add margin to separate from the checkbox
                        padding: '5px',
                        backgroundColor: '#FF5A5F',
                        color: 'white', // Set text color to white
                        borderRadius: '4px',
                        zIndex: '1',
                    }}
                >
                    You cannot give feedback while the checkbox is checked
                </div>
            )}
        </div>
    );
}

export default FeedbackCheckbox;
