import { gql } from "apollo-boost";

const AttendanceQuery = gql`
  query attendanceQuery {
    referenceAttendancePunctualities {
        optionText
        optionValue
    }
  }
`;

export default AttendanceQuery;