import { gql } from 'apollo-boost';

const AttachmentUpdateMutation = gql`
  mutation AttachmentUpdateMutation($input: AttachmentUpdateUpdateInput) {
    attachmentUpdate(input: $input) {
      errors {
        code
        message
      }
      success
    }
  }
`;

export default AttachmentUpdateMutation;
