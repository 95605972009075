import gql from 'graphql-tag';

const OrgSignatureDocumentQuery = gql`
  query OrgSignatureDocumentQuery($id: ID!) {
    org(id: $id) {
      id
      brandColor
      secondaryColor
      signatureDocuments(order: "title", status: ["active", "inactive"]) {
        edges {
          node {
            id
            status
            title
            annualExpiration
            description
            updatedAt
            url
            __typename
          }
        }
      }
    }
  }
`;

// const OrgSignatureDocumentQuery = gql`
//   query signatureDocQuery {
//     signatureDocuments {
//       edges {
//         node {
//           id
//           status
//           title
//           annualExpiration
//           description
//           updatedAt
//           url
//           __typename
//         }
//       }
//       pageInfo {
//         hasNextPage
//         endCursor
//       }
//     }
//   }
// `;

export default OrgSignatureDocumentQuery;
