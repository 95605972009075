import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { useParams, useNavigate } from 'react-router-dom';
import JobPostsQuery from '../../graphql/queries/Org/Jobposts';
import JobPostRemoveMutation from '../../graphql/mutations/Jobposts/Delete';
import LoadingPane from '../Shared/LoadingPane';
import SearchField from '../Shared/SearchField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

const defaultColor = '#FF5A5F';
const refs = {};

const DashboardJobPosts = (props) => {
  let brandColor = null;

  const { id: orgId } = useParams();
  const navigate = useNavigate();
  const { user } = props;
  const [Alert, setAlert] = useState('');
  const [jobPostArray, setJobPostArray] = useState([]);
  // const viewerRoles = user.roles;
  // const viewerRole = viewerRoles?.find((r) => r.org.id === orgId);
  const [jobPostRemoveMutation] = useMutation(JobPostRemoveMutation);

  const [variable, setVariable] = useState({
    status: null,
    search: null,
    first: 10
  });

  const { loading, data, refetch } = useQuery(JobPostsQuery, {
    variables: { ...variable, id: orgId, },
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (!loading) {
      setJobPostArray(data?.org?.jobPosts?.nodes);
    }
  }, [loading, data]);

  const goToJobPostRoute = (id) => {
    navigate(`/organizations/${orgId}/jobposts/${id}`, {
      state: {
        previous: props.location.pathname,
        brandColor: props.state.role.org.brandColor,
      },
    })
  }

  const onCloseAlert = () => {
    setAlert('');
  };

  const handleSearch = (e) => {
    const search = e.target.value.toLowerCase() || null;
    const newVar = { ...variable };
    newVar.search = search;
    setVariable(newVar);
  };

  const deleteRow = (index, id) => {
    jobPostRemoveMutation({
      variables: {
        input: {
          id: id,
        },
      },
    }).then((res) => {
      setAlert('Job Post Deleted Successfully!');
    });
    jobPostArray.splice(index, 1);
    setJobPostArray([...jobPostArray]);
  };

  const hasPermission = true;

  return (
    <div className="dashboard-roster-view full-screen-view">
      {loading && !data ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      {Alert && (
        <div className="modaloverlay">
          <div className="modal" style={{ width: '20%' }}>
            <h2>Alert</h2>
            <div
              className="model-body"
              dangerouslySetInnerHTML={{ __html: Alert }}
              style={{ display: 'inline' }}
            ></div>
            <div>
              <button
                type="submit"
                style={{ width: '20%' }}
                className="model-submit-button"
                onClick={onCloseAlert}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}

      <>
        <div className="toolbar-frame">
          <div className="centered-frame">
            <div className="left-section">
              <SearchField onChange={handleSearch} placeholder="Search Job" />
            </div>

            {/* <div className="button-frame">
              <button
                className="create-button"
                style={{ color: brandColor || defaultColor }}
                onClick={() => goToUserRoute()}
              >
                + Create New Post
              </button>
            </div> */}
          </div>
        </div>

        <div className="table-headers">
          <div className="centered-frame">
            <div className="table-header title">Title</div>
            <div className="table-header type">Speciality</div>
            <div className="table-header type">Salary Type</div>
            <div className="table-header type">Salary Range</div>
            <div className="table-header type">Experience</div>
            <div className="table-header type">locationType</div>
            <div className="table-header status sortable">Action</div>
          </div>
        </div>

        <ul className="roster-list-view scroll">
          {jobPostArray.length > 0 ? (
            jobPostArray.map((jobPostObj, index) => {
              const { title, id, experience, specialty, salaryType, locationType, salaryTypeMinimum, salaryTypeMaximum } =
                jobPostObj;

              let salaryRange = 'NA'

              if (salaryTypeMinimum && salaryTypeMaximum) {
                salaryRange = salaryTypeMinimum + ' - ' + salaryTypeMaximum
              } else if (salaryTypeMinimum && !salaryTypeMaximum) {
                salaryRange = 'Above ' + salaryTypeMinimum
              } else if (!salaryTypeMinimum && salaryTypeMaximum) {
                salaryRange = 'Below ' + salaryTypeMaximum
              } else {
                salaryRange = 'Not provided'
              }

              return (
                <li
                  className="roster-cell"
                  key={`${index}-${id}`}
                  ref={(ref) => (refs[id] = ref)}
                >
                  <div className="centered-frame">
                    <div className="cell-label title bold">{title}</div>
                    <div className="cell-label type">{specialty}</div>
                    <div className="cell-label type">{salaryType}</div>
                    <div className="cell-label type">{salaryRange}</div>
                    <div className="cell-label type">{experience}</div>
                    <div className="cell-label type">{locationType}</div>
                    <div className="cell-label" style={{ display: 'flex' }}>
                      <button onClick={() => goToJobPostRoute(id)}
                        style={{
                          background: 'transparent',
                          marginRight: '20px',
                          color: 'blue'
                        }}>
                        {/* <FontAwesomeIcon icon={faEye} size={'sm'} /> */}
                        View
                      </button>
                      {/* <button
                        style={{
                          background: 'transparent',
                          marginRight: '20px',
                        }}
                        onClick={() => goToUserRoute(id)}
                      >
                        <FontAwesomeIcon icon={faEdit} size={'sm'} />
                      </button> 
                      <button
                        style={{ background: 'transparent' }}
                        onClick={(e) => {
                          deleteRow(index, id);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} size={'sm'} />
                      </button> */}
                    </div>
                  </div>
                </li>
              );
            })
          ) : (
            <div className="layout-center hint">
              There are no job posts to display.
            </div>
          )}
        </ul>
      </>
    </div >
  );
};

export default DashboardJobPosts;
