import { gql } from 'apollo-boost';

const SignatureDocumentUpdateMutation = gql`
  mutation SignatureDocumentUpdateMutation($input: SignatureDocumentUpdateUpdateInput) {
    signatureDocumentUpdate(input: $input) {
      errors {
        message
      }
      result {
        id
      }
      success
    }
  }
`;

export default SignatureDocumentUpdateMutation;
