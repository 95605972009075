import { gql } from 'apollo-boost';

const OrgsQuery = gql`
  query OrgsQuery(
    $id: ID!
    $search: String
    $order: String
    $first: Int
    $after: String
  ) {
    org(id: $id) {
      id
      brandColor
      secondaryColor
    }
    orgs(first: $first, after: $after, search: $search, order: $order) {
      nodes {
        id
        attachment(label: "pdf_logo") {
          id
          url
        }
        checklists(status: ["active"]) {
          nodes {
            id
          }
        }
        courses(status: ["active"]) {
          nodes {
            id
          }
        }
        kind
        parentId
        status
        tests(status: ["active"]) {
          nodes {
            id
          }
        }
        title
        updatedAtIso
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export default OrgsQuery;
