import { gql } from 'apollo-boost';

const CourseQuestionUpdateMutation = gql`
  mutation CourseQuestionUpdateMutation(
    $input: CourseQuestionUpdateUpdateInput
  ) {
    courseQuestionUpdate(input: $input) {
      errors {
        code
        message
      }
      result {
        id
        answers
        isRandomized
        rank
        title
      
      }
    }
  }
`;

export default CourseQuestionUpdateMutation;
