import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { useParams, useNavigate } from 'react-router-dom';
import SubscriptionsList from '../../graphql/queries/Org/Subscription';
import LoadingPane from '../Shared/LoadingPane';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-regular-svg-icons';

const DashboardPackages = (props) => {
  const defaultColor = '#FF5A5F';

  const params = useParams();
  const navigate = useNavigate();
  const [Alert, setAlert] = useState('');
  const [userArray, setUserArray] = useState([]);

  const { loading, data, refetch } = useQuery(SubscriptionsList, {
    variables: { status: ['active', 'inactive'] },
  });

  useEffect(() => {
    refetch();
  }, [params.id, refetch]); // Refetch when organization ID changes

  useEffect(() => {
    if (!loading) {
      setUserArray(data?.subscriptions?.edges.map((edge) => edge.node));
    }
  }, [loading, data]);

  const goToUserRoute = (id) => {
    navigate(`/organizations/${params.id}/packages/${id || 'create'}`, {
      state: {
        previous: props.location.pathname,
      },
    });
  };

  const onCloseAlert = () => {
    setAlert('');
  };

  return (
    <div className="dashboard-roster-view full-screen-view">
      {!data ? <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} /> : null}

      {Alert && (
        <div className="modaloverlay">
          <div className="modal" style={{ width: '20%' }}>
            <h2>Alert</h2>
            <div
              className="model-body"
              dangerouslySetInnerHTML={{ __html: Alert }}
              style={{ display: 'inline' }}
            >
            </div>
            <div>
              <button
                type="submit"
                style={{ width: '20%' }}
                className="model-submit-button"
                onClick={onCloseAlert}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}

      <>
        <div className="toolbar-frame">
          <div className="centered-frame">
            <div className="left-section"></div>

            <button
              className="create-button"
              style={{ color: defaultColor }}
              onClick={() => goToUserRoute()}
            >
              + Create New Package
            </button>
          </div>
        </div>

        <div className="table-headers">
          <div className="centered-frame">
          <div className="table-header type ">Package Name</div>
            <div className="table-header type">Credit point</div>
            <div className="table-header type">Amount</div>
            <div className="table-header type">Validity</div>
            <div className="table-header type">Category</div>
            <div className="table-header status sortable">Action</div>
          </div>
        </div>

        <ul className="roster-list-view scroll">
          {userArray.length > 0 ? (
            userArray.map((userObj, index) => {
              const { id, amount, creditEarn, title, validity, category } =
                userObj;

              return (
                <li className="roster-cell" key={`${index}-${id}`}>
                  <div className="centered-frame">
                    <div className="cell-label bold type">{title}</div>
                    <div className="cell-label type">{creditEarn}</div>
                    <div className="cell-label type">{amount}</div>
                    <div className="cell-label type">{validity}</div>
                    <div className="cell-label type">{category}</div>
                    <div className="cell-label" style={{ display: 'flex' }}>
                      <button
                        style={{
                          background: 'transparent',
                          marginRight: '20px',
                        }}
                        onClick={() => goToUserRoute(id)}
                      >
                        <FontAwesomeIcon icon={faEdit} size={'sm'} />
                      </button>
                      {/* <button
                        style={{ background: 'transparent' }}
                      onClick={(e) => {
                        deleteRow(index, id);
                      }}
                      >
                        <FontAwesomeIcon icon={faTrash} size={'sm'} />
                      </button> */}
                    </div>
                  </div>
                </li>
              );
            })
          ) : (
            <div className="layout-center hint">
              There are no packages to display.
            </div>
          )}
        </ul>
      </>
    </div>
  );
};

export default DashboardPackages;
