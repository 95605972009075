import React, { useEffect, useState } from 'react';
import FeedbackRequestOrgQuery from '../../graphql/queries/Org/Feedback/feedbackrequest';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import LoadingPane from '../Shared/LoadingPane';
import { withRouter } from '../../containers/withRouter';
import Toolbar from '../Shared/Toolbar';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

const ViewFeedback = (props) => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [Alert, setAlert] = useState('');
  const [Factors, setFactors] = useState([]);
  const onCloseAlert = () => {
    setAlert('');
  };

  useEffect(() => {
    setLoading(true);
    const {
      FeedbackRequestOrgQuery: { refetch },
    } = props;
    refetch().then((res) => {
      const factors = res.data.org.feedbackRequests.nodes;
      if (factors) {
        setFactors(factors);
        setLoading(false);
      }
    });
  }, []);

  const onBack = () => {
    const { navigate } = props;
    navigate(`/organizations/1/360Feedback`, {
      state: {
        org: state.org,
      },
    });
  };

  const goToFeedbackDetailRoute = (id) => {
    const { location, navigate } = props;
    navigate(`/viewfeedback/${id}`, {
      state: {
        previous: location.pathname,
        org: state.org,
      },
    });
  };

  const loadTablebody = () => {
    return Factors.map((row, index) => (
      <li className={'test-cell'} style={{ marginBottom: 3 }} key={index}>
        <div className="centered-frame">
          <div className="cell-content-frame">
            <div
              className="cell-label title extralong"
              onClick={(e) => goToFeedbackDetailRoute(row.id)}
            >
              {row.fname} {row.lname}
            </div>
            <div
              className="cell-label list extralong"
              onClick={(e) => goToFeedbackDetailRoute(row.id)}
            >
              {row.email ? row.email : '--'}
            </div>
            <div
              className="cell-label list extralong"
              onClick={(e) => goToFeedbackDetailRoute(row.id)}
            >
              {row.company ? row.company : '--'}
            </div>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              rootClose
              overlay={
                <Popover id="menu-pane" className="menu-pane">
                  <div
                    className="menu-content-view"
                    style={{ padding: '5px 0px' }}
                  >
                    <button
                      className="menu-button teal"
                      onClick={(e) => goToFeedbackDetailRoute(row.id)}
                    >
                      View Details
                    </button>
                  </div>
                </Popover>
              }
            >
              <div className={'expander-icon'}>
                <img
                  src={require('../../theme/images/expander-icon-grey-2x.png')}
                  alt="expander-icon"
                />
              </div>
            </OverlayTrigger>
          </div>
        </div>
      </li>
    ));
  };

  const loadTableHeader = (
    <div className="table-headers">
      <div className="centered-frame">
        <div className="table-header list extralong">{'Full Name'}</div>
        <div className="table-header list extralong">{'Email'}</div>
        <div className="table-header list extralong">{'Company'}</div>
      </div>
    </div>
  );

  return (
    <div className="dashboard-feedback-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      {Alert && (
        <div className="modaloverlay">
          <div className="modal" style={{ width: '20%' }}>
            <h2>Alert</h2>
            <div
              className="model-body"
              dangerouslySetInnerHTML={{ __html: Alert }}
              style={{ display: 'inline' }}
            >
              {/* {Alert} */}
            </div>
            <div>
              <button
                type="submit"
                style={{ width: '20%' }}
                className="model-submit-button"
                onClick={onCloseAlert}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}

      <Toolbar cancel={onBack} title={'Feedback List'} />

      <div style={{ padding: 10, marginTop: 80 }}>
        <div>
          <div className="feedback-table-viewthree" style={{ height: 610 }}>
            <>{loadTableHeader}</>
            <ul className="tests-list-view scroll">
              <>{loadTablebody()}</>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default compose(
  withRouter,
  graphql(FeedbackRequestOrgQuery, {
    name: 'FeedbackRequestOrgQuery',
    options: (props) => ({
      variables: {
        id: props.location.state.org,
      },
    }),
  }),
)(ViewFeedback);
