export const createNumericOptions = (min, max, reverse) => {
  const arr = [];

  while (min <= max) {
    arr.push(min);

    min++;
  }

  return reverse ? arr.reverse() : arr;
};
