import { gql } from 'apollo-boost';

const TestQuestionRemoveMutation = gql`
  mutation TestQuestionRemoveMutation($input: IDInput) {
    testQuestionRemove(input: $input) {
      errors {
        code
        message
      }
    }
  }
`;

export default TestQuestionRemoveMutation;
