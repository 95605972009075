import { gql } from 'apollo-boost';

const JobPostQuery = gql`
  query JobPost($id: ID) {
    jobPost(id: $id) {
      id
      ecpJobId
      title
      position
      status
      benefits
      experience
      jobExpire
      jobStatus
      jobType
      jobDescription
      officeDescription
      candidateDescription
      candidateRequirements
      locationType
      locationName
      locationCity
      locationState
      locationCountry
      locationPostalCode
      locationAddress
      salaryType
      salaryTypeMinimum
      salaryTypeMaximum
      hiringManagerName
      hiringManagerTitle
      applicantsCount
      specialty
    }
  }
`;

export default JobPostQuery;
