import React from 'react';
import { CSVLink } from 'react-csv';

import _ from 'lodash';
import moment from 'moment';

import DatePicker from '../../Shared/DatePicker';
import LoadingPane from '../../Shared/LoadingPane';
import ModalPane from '../../Shared/ModalPane';

import STRINGS from '../../../localization';

const OrgReporting = (props) => {
  const { handleBreakdown, handleChange, runReport, setupBreakdown, state } =
    props;
  const {
    brandColor,
    breakdown,
    breakdownAmt,
    csv,
    cvData,
    data,
    endDate,
    isModal,
    loading,
    startDate,
    title,
    users,
  } = state;
  console.log(endDate);
  return (
    <div className="org-reporting-view">
      {loading ? <LoadingPane /> : null}

      <div className="reporting-toolbar">
        <div className="button-frame" style={{ left: 10, right: 'auto' }}>
          <CSVLink
            className={data.length > 0 ? 'export-button capitalize' : 'hide'}
            data={cvData}
            filename={`Color Vision Report ${moment(
              startDate,
              'YYYY-MM-DD',
            ).format('MMM D, YYYY')} - ${moment(endDate, 'YYYY-MM-DD').format(
              'MMM D, YYYY',
            )}`}
            style={{ borderColor: brandColor, color: brandColor }}
          >
            {STRINGS.exportColorVision}
          </CSVLink>
        </div>

        <div className="dates-frame">
          <div className="date-frame">
            <DatePicker
              defaultValue={startDate}
              onChange={(date) =>
                handleChange(
                  '_startDate',
                  moment(date).startOf('day').format('YYYY-MM-DD'),
                )
              }
            />
          </div>

          <div className="seperator">-</div>

          <div className="date-frame">
            <DatePicker
              defaultValue={endDate}
              onChange={(date) =>
                handleChange(
                  '_endDate',
                  moment(date).endOf('day').format('YYYY-MM-DD'),
                )
              }
            />
          </div>
        </div>

        <div className="button-frame">
          <CSVLink
            className={data.length > 0 ? 'export-button capitalize' : 'hide'}
            data={csv}
            filename={`${title} Report ${moment(startDate, 'YYYY-MM-DD').format(
              'MMM D, YYYY',
            )} - ${moment(endDate, 'YYYY-MM-DD').format('MMM D, YYYY')}`}
            style={{ borderColor: brandColor, color: brandColor }}
          >
            {STRINGS.export}
          </CSVLink>
          <button className="run-report-button" onClick={runReport}>
            {STRINGS.runReport}
          </button>
        </div>
      </div>

      <div className="dashboard-frame">
        <div className="stats-frame">
          <div className="centered-frame">
            <div className="stat-frame">
              <div className="stat-header capitalize">{STRINGS.totalUsers}</div>

              <div className="stat-value bold">{users.nodes.length}</div>
            </div>

            <div className="stat-frame">
              <div className="stat-header">{STRINGS.usersWithResult}</div>

              <div className="stat-value bold">{data.length}</div>
            </div>

            <div className="stat-frame">
              <div className="stat-header">{STRINGS.newUsers}</div>

              <div className="stat-value bold">
                {
                  data.filter((obj) => {
                    const date = moment(new Date(obj.createdAt * 1000));

                    return (
                      moment(date).isSameOrAfter(moment(startDate)) &&
                      moment(date).isSameOrBefore(moment(endDate))
                    );
                  }).length
                }
              </div>
            </div>

            <div className="stat-frame">
              <div className="stat-header capitalize">
                {STRINGS.avgExamTime}
              </div>

              <div className="stat-value bold">
                {setupBreakdown(data, 'testsTaken')}
              </div>

              <button
                className="view-breakdown-button"
                onClick={() => handleBreakdown('testsTaken')}
              >
                {STRINGS.viewBreakdown}
              </button>
            </div>

            <div className="stat-frame">
              <div className="stat-header capitalize">
                {STRINGS.avgMandatoryTime}
              </div>

              <div className="stat-value bold">
                {setupBreakdown(data, 'coursesTaken')}
              </div>

              <button
                className="view-breakdown-button"
                onClick={() => handleBreakdown('coursesTaken')}
              >
                {STRINGS.viewBreakdown}
              </button>
            </div>

            <div className="stat-frame">
              <div className="stat-header capitalize">
                {STRINGS.avgChecklistTime}
              </div>

              <div className="stat-value bold">
                {setupBreakdown(data, 'checklistsTaken')}
              </div>

              <button
                className="view-breakdown-button"
                onClick={() => handleBreakdown('checklistsTaken')}
              >
                {STRINGS.viewBreakdown}
              </button>
            </div>
          </div>
        </div>

        <div className="breakdown-frame">
          <div className="table-headers">
            <div className="centered-frame">
              <div className="table-header name">{STRINGS.name}</div>
              <div className="table-header expirations">
                {STRINGS.expirations}
              </div>
              <div className="table-header content">{STRINGS.tests}</div>
              <div className="table-header content">{STRINGS.checklists}</div>
              <div className="table-header content">{STRINGS.courses}</div>
              <div className="table-header date">{STRINGS.date}</div>
            </div>
          </div>

          {data.length > 0 ? (
            <ul className="users-list-view">
              {_.orderBy(data, ['firstName', 'lastName']).map((user) => {
                const {
                  checklistsTaken,
                  coursesTaken,
                  documents,
                  id,
                  firstName,
                  lastName,
                  testsTaken,
                } = user;
                const types = {
                  certification: STRINGS.certification,
                  license: STRINGS.license,
                };

                const amount = (arr) =>
                  arr.filter(
                    (o) =>
                      moment(o.endedAt).isSameOrAfter(moment(startDate)) &&
                      moment(o.endedAt).isSameOrBefore(moment(endDate)),
                  );
                const arr = [
                  amount(testsTaken),
                  amount(checklistsTaken),
                  amount(coursesTaken),
                ].flat();
                const expirations = documents.nodes.filter((d) => {
                  const date = moment(d.expiresOn).endOf('day');
                  const today = moment().startOf('day');

                  if (d.expiresOn) {
                    const expiresAfter = date.isAfter(today);
                    const numOfDays = expiresAfter
                      ? date.diff(today, 'days')
                      : null;

                    return expiresAfter && numOfDays <= 60;
                  } else {
                    return null;
                  }
                });

                return (
                  <li className="user-cell" key={id}>
                    <div className="centered-frame">
                      <div className="cell-label name bold">{`${firstName} ${lastName}`}</div>

                      <div className="cell-label expirations">
                        <div className="expirations-frame">
                          <div className="expiration-label">
                            {expirations.length}
                          </div>

                          <ul className="expirations-list-view">
                            {expirations.map((document) => {
                              const { expiresOn, kind, id, title } = document;

                              const type = types[kind];

                              return (
                                <li className="document-cell" key={id}>
                                  <div className="cell-label">
                                    {type || STRINGS.document}: <b>{title}</b>
                                  </div>

                                  <div className="flex-frame">
                                    <div className="cell-label">{`${
                                      STRINGS.expires
                                    }: ${moment(expiresOn).format(
                                      'MMM D, YYYY',
                                    )}`}</div>
                                    <div className="cell-label una">{`(${moment(
                                      expiresOn,
                                    ).diff(moment(), 'days')} ${
                                      STRINGS.days
                                    })`}</div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>

                      <div className="cell-label content">
                        {amount(testsTaken).length}
                      </div>
                      <div className="cell-label content">
                        {amount(checklistsTaken).length}
                      </div>
                      <div className="cell-label content">
                        {amount(coursesTaken).length}
                      </div>

                      <div className="cell-label date">
                        {moment(
                          arr.length > 0
                            ? _.orderBy(arr, ['endedAt'])[0].endedAt
                            : new Date(),
                        ).format('MMM D, YYYY')}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : (
            <div className="empty-label">{STRINGS.emptyReportingMessage}</div>
          )}
        </div>
      </div>

      <ModalPane
        className="timer-breakdown-pane"
        content={(hide) => {
          return (
            <div className="modal-content-frame">
              <div className="modal-toolbar-frame">
                <button className="cancel-button" onClick={hide}>
                  {STRINGS.cancel}
                </button>

                <div className="modal-toolbar">{STRINGS.viewBreakdown}</div>
              </div>

              <div className="stat-frame">
                <div className="centered-frame">
                  <div className="stat-header">{STRINGS.numberOfEmployees}</div>

                  <div className="stat-value">{breakdownAmt}</div>
                </div>
              </div>

              <div className="breakdown-table-view">
                <div className="table-headers">
                  <div className="centered-frame">
                    <div className="table-header">{STRINGS.title}</div>
                    <div className="table-header time">
                      {STRINGS.shortestTime}
                    </div>
                    <div className="table-header time">
                      {STRINGS.longestTime}
                    </div>
                    <div className="table-header time">{STRINGS.avgTime}</div>
                  </div>
                </div>

                <ul className="breakdown-list-view scroll">
                  {Object.keys(breakdown)
                    .sort()
                    .map((key, i) => {
                      const arr = _.sortBy(breakdown[key], 'timeSpentSecs');
                      const avg =
                        arr.reduce(
                          (total, t) => total + parseInt(t.timeSpentSecs),
                          0,
                        ) / arr.length;
                      const formattedTime = (t) => {
                        const timeFormat = t < 3600 ? 'mm:ss' : 'h:mm:ss';
                        return moment
                          .utc(Math.round(t) * 1000)
                          .format(timeFormat);
                      };
                      return (
                        <li className="breakdown-cell" key={i}>
                          <div className="centered-frame">
                            <div className="cell-label bold">{key}</div>

                            <div className="cell-label time">
                              {formattedTime(arr[0].timeSpentSecs)}
                            </div>
                            <div className="cell-label time">
                              {formattedTime(arr[arr.length - 1].timeSpentSecs)}
                            </div>
                            <div className="cell-label time">
                              {formattedTime(avg)}
                            </div>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          );
        }}
        hide={() => handleChange('isModal', false)}
        show={isModal}
      />
    </div>
  );
};

export default OrgReporting;
