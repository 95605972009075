import React, { useState, useEffect } from 'react';
import Toolbar from '../Shared/Toolbar';
import JobPostChangeVerify from '../../graphql/mutations/Jobposts/Verify';
import JobPostChangeUpdate from '../../graphql/mutations/Jobposts/Update';
import { useMutation } from 'react-apollo';
import { useLocation } from 'react-router-dom';

const CreatejobsEmailPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const Token = searchParams.get('Token');
  const ID = searchParams.get('ID');

  const [Alert, setAlert] = useState('');
  const [JobPostDetails, setJobPostDetails] = useState({
    designation: '',
    positionsAvailable: '',
    endDate: '',
    qualification: '',
    description: '',
  });
  const [requestAccepted, setRequestAccepted] = useState(false);


  const [changesRequested, setChangesRequested] = useState({
    designation: '',
    positions_available: '',
    end_date: '',
    qualification: '',
    description: '',
  });

  const [jobPostChangeVerify] = useMutation(JobPostChangeVerify);
  const [jobPostChangeUpdate] = useMutation(JobPostChangeUpdate);

  const onCloseAlert = () => {
    window.location.href = 'https://www.google.com';
  };

  useEffect(() => {
    jobPostChangeVerify({
      variables: {
        input: {
          id: ID,
          token: Token,
        },
      },
    })
      .then((res) => {
        const { data } = res;
        if (
          data &&
          data.jobPostChangeVerify &&
          data.jobPostChangeVerify.result
        ) {
          const { jobPost, changesRequested } = data.jobPostChangeVerify.result;
          setJobPostDetails(jobPost);
          setChangesRequested(changesRequested);
          if (data.jobPostChangeVerify.success) {
            setRequestAccepted(true);
          }
        }
      })
      .catch((error) => {
        console.error('Error creating job post:', error);
      });
  }, []);

  const onBack = () => {
    jobPostChangeUpdate({
      variables: {
        input: {
          id: ID,
          token: Token,
          status: 'rejected',
        },
      },
    })
      .then((res) => {
        setAlert('Job Post Updated');
        setTimeout(() => {
          setAlert('');
          window.location.href = 'https://www.google.com';
        }, 3000);
      })
      .catch((error) => {
        console.error('Error creating job post:', error);
      });
  };

  const onSave = () => {
    jobPostChangeUpdate({
      variables: {
        input: {
          id: ID,
          token: Token,
          status: 'accepted',
        },
      },
    })
      .then((res) => {
        setAlert('Job Post Updated');
        setTimeout(() => {
          setAlert('');
          window.location.href = 'https://www.google.com';
        }, 3000);
      })
      .catch((error) => {
        console.error('Error creating job post:', error);
      });
  };

  if (!requestAccepted) {
    return <div>Sorry ! This Session is Expired.</div>;
  }

  return (
    <div className="test-view full-screen-view">
      <Toolbar cancel={onBack} save={onSave} title={'Update Job Post'} />

      {Alert && (
        <div className="modaloverlay">
          <div className="modal" style={{ width: '20%' }}>
            <h2>Alert</h2>
            <div
              className="model-body"
              dangerouslySetInnerHTML={{ __html: Alert }}
              style={{ display: 'inline' }}
            >
              {/* {Alert} */}
            </div>
            <div>
              <button
                type="submit"
                style={{ width: '20%' }}
                className="model-submit-button"
                onClick={onCloseAlert}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="content-frame">
        <div className="centered-frame">
          <div className="section-frame">
            <div className="section-header">Existing Details</div>

            <div className="form-frame" style={{ height: 84 * 2 + 150 }}>
              <div className="form-input-frame">
                <div className="input-title">Designation</div>

                <input
                  className="form-input"
                  defaultValue={JobPostDetails.designation}
                  placeholder="Enter Designation"
                />
              </div>

              <div className="form-input-frame middle">
                <div className="input-title">Number of Position</div>

                <input
                  className="form-input"
                  type="number"
                  defaultValue={JobPostDetails.positionsAvailable}
                  placeholder="Enter Number of Position"
                />
              </div>

              <div className="form-input-frame">
                <div className="input-title">End Date</div>

                <input
                  className="form-input"
                  type="date"
                  defaultValue={JobPostDetails.endDate}
                  placeholder="Enter End Date"
                />
              </div>

              <div className="form-input-frame">
                <div className="input-title">Qualification</div>

                <input
                  className="form-input"
                  defaultValue={JobPostDetails.qualification}
                  placeholder="Enter Qualification"
                />
              </div>

              <div className="form-input-frame" style={{ width: '100%' }}>
                <div className="input-title">Description</div>

                <textarea
                  className="form-area"
                  placeholder="Enter description"
                  defaultValue={JobPostDetails.description}
                />
              </div>
            </div>
          </div>

          <hr
            style={{
              width: '1200px',
              border: 'none',
              height: '1px',
              marginBottom: '40px',
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
            }}
          />

          <div className="section-frame">
            <div className="section-header">Updated Details</div>

            <div className="form-frame" style={{ height: 84 * 2 + 150 }}>
              <div className="form-input-frame">
                <div className="input-title">Designation</div>

                <input
                  className="form-input"
                  defaultValue={changesRequested.designation}
                  placeholder="Enter Designation"
                />
              </div>

              <div className="form-input-frame middle">
                <div className="input-title">Number of Position</div>

                <input
                  className="form-input"
                  type="number"
                  defaultValue={changesRequested.positions_available}
                  placeholder="Enter Number of Position"
                />
              </div>

              <div className="form-input-frame">
                <div className="input-title">End Date</div>

                <input
                  className="form-input"
                  type="date"
                  defaultValue={changesRequested.end_date}
                  placeholder="Enter End Date"
                />
              </div>

              <div className="form-input-frame">
                <div className="input-title">Qualification</div>

                <input
                  className="form-input"
                  defaultValue={changesRequested.qualification}
                  placeholder="Enter Qualification"
                />
              </div>

              <div className="form-input-frame" style={{ width: '100%' }}>
                <div className="input-title">Description</div>

                <textarea
                  className="form-area"
                  placeholder="Enter description"
                  defaultValue={changesRequested.description}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatejobsEmailPage;
