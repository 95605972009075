import { gql } from 'apollo-boost';

const JobPostRemoveMutation = gql`
mutation JobPostRemove($input: IDInput){
    jobPostRemove(input:$input){
      success
      result{
        id
        status
        designation
      }
      errors{
        backtrace
        message
      }
    }
  }
`;

export default JobPostRemoveMutation;
