import { gql } from 'apollo-boost';

const OrgDeactivateMutation = gql`
  mutation OrgDeactivateMutation($input: IDInput) {
    orgDeactivate(input: $input) {
      errors {
        code
        message
      }
      success
    }
  }
`;

export default OrgDeactivateMutation;
