import React from 'react';
import { graphql } from 'react-apollo';

import _ from 'lodash';
import compose from 'lodash.flowright';
import Cookie from 'js-cookie';

import UserQuery from '../../graphql/queries/User/Min';
import OrgsQuery from '../../graphql/queries/Orgs';
import SessionLoginMutation from '../../graphql/mutations/Session/Login';
import UserUpdateMutation from '../../graphql/mutations/User/Update';

import Profile from '../../components/Profile';

import LoadingPane from '../../components/Shared/LoadingPane';
import { withRouter } from '../withRouter';

class ProfileContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      create: false,
      isEdit: false,
      isModal: false,
      onload: true,
    };
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate() {
    this.setup();
  }

  setup = () => {
    const {
      userQuery: { loading, user },
      orgsQuery: { loading: loading2, orgs },
    } = this.props;

    if (this.state.onload && !loading && !loading2) {
      this.setState({
        ...user,
        onload: false,
        orgs,
        phoneNumber: user ? this.formatPhoneNumber(user.phoneNumber) : null,
        unchangedUser: user,
      });
    }
  };

  back = () => {
    const { location, navigate } = this.props;

    if (location.state) {
      navigate(location.state.previous);
    } else {
      navigate('/');
    }
  };

  deleteRole = (role) => {
    const { userUpdateMutation } = this.props;
    const { id, roles } = this.state;

    const i = roles.findIndex((r) => r.id === role.id);
    const arr = [...roles.slice(0, i), ...roles.slice(i + 1, roles.length)];

    this.setState(
      {
        loading: true,
      },
      () => {
        userUpdateMutation({
          variables: {
            input: {
              id,
              roles: arr.map(({ name, org }) => ({
                role: name,
                orgId: org.id,
              })),
            },
          },
        }).then((response) => {
          const {
            data: {
              userUpdate: { result, success },
            },
          } = response;

          this.handleChange('create', false);

          if (success) {
            this.setState({
              loading: false,
              roles: result.roles,
            });
          } else {
            window.alert('There was an issue. Try again.');
          }
        });
      },
    );
  };

  handleChange = (key, value) => {
    this.setState(_.set(this.state, key, value));
  };

  resetUser = () => {
    this.setState({ ...this.state.unchangedUser });
  };

  reset = () => {
    this.setState({
      error: null,
      isModal: false,
      password: '',
      password1: '',
      password2: '',
    });
  };

  resetPassword = (hide) => {
    const { sessionLoginMutation, userUpdateMutation } = this.props;
    const { email, id, password, password1, password2 } = this.state;

    if (!password || !password1 || !password2) {
      this.handleChange('error', 'All fields are required.');
    } else if (password1 !== password2) {
      this.handleChange('error', 'Passwords do not match.');
    } else if (password1.length < 6) {
      this.handleChange(
        'error',
        'Password does not meet minimum requirement (6 characters).',
      );
    } else {
      this.setState(
        {
          loading: true,
        },
        () => {
          sessionLoginMutation({
            variables: {
              input: {
                email,
                password,
              },
            },
          }).then((response) => {
            const {
              data: { sessionLogin },
            } = response;

            if (sessionLogin.user) {
              userUpdateMutation({
                variables: {
                  input: {
                    id,
                    password: password1,
                  },
                },
              }).then((response) => {
                const {
                  data: {
                    userUpdate: { errors, success },
                  },
                } = response;

                this.handleChange('loading', false);

                if (success) {
                  hide();
                } else {
                  this.handleChange('error', `${errors[0].message}.`);
                }
              });
            } else {
              this.setState({
                error: 'Current password is incorrect.',
                loading: false,
              });
            }
          });
        },
      );
    }
  };

  formatPhoneNumber = (str) => {
    if (str) {
      let cleaned = ('' + str).replace(/\D/g, ''),
        match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        return ['(', match[2], ') ', match[3], '-', match[4]].join('');
      }
    }
    return null;
  };

  validateForm = () => {
    const { email, phoneNumber } = this.state;

    const emailRegex = new RegExp(
      /^([+\w-]+(?:\.[+\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i,
    );
    const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/i;

    const required = ['first name', 'last name', 'email'];

    const missing = required.filter(
      (key) =>
        !this.state[_.camelCase(key)] ||
        this.state[_.camelCase(key)].length === 0,
    );

    const validEmail = emailRegex.test(email);
    const validPhone = phoneRegex.test(phoneNumber);

    if (missing.length === 0 && validPhone && validEmail) {
      return true;
    } else {
      if (missing.length > 0) {
        window.alert(
          `Missing required ${
            missing.length === 1 ? 'field' : 'fields'
          }: ${_.upperFirst(missing.toString().replace(/,/g, ', '))}.`,
        );
      } else if (!validEmail) {
        window.alert('Email is not valid.');
      } else if (!validPhone) {
        window.alert('Phone number is not valid.');
      }
      return false;
    }
  };

  save = () => {
    const { userUpdateMutation } = this.props;
    const { email, firstName, id, lastName, phoneNumber } = this.state;

    if (this.validateForm()) {
      this.setState(
        {
          loading: true,
        },
        () => {
          userUpdateMutation({
            variables: {
              input: {
                id,
                email,
                firstName,
                lastName,
                phoneNumber,
              },
            },
          }).then((response) => {
            const {
              data: {
                userUpdate: { success },
              },
            } = response;

            if (success) {
              this.setState({
                isEdit: false,
                loading: false,
              });
            } else {
              let errorMessage = 'There was an issue. Try again.';
              if (response.data.userUpdate.errors.length > 0) {
                errorMessage = response.data.userUpdate.errors[0].message;
              }
              window.alert(errorMessage);
              this.setState({
                loading: false,
              });
            }
          });
        },
      );
    }
  };

  saveRole = () => {
    const { userQuery, userUpdateMutation } = this.props;
    const { create, edit, id, orgId, roles, role } = this.state;
    const roleToInput = (r) => ({ role: r.name, orgId: r.org.id });

    const newRole = role && orgId ? { role, orgId } : null;
    const rolesInput = (
      edit ? [...roles.filter((r) => r.id !== edit.id), edit] : roles
    ).map((r) => roleToInput(r));
    const arr = create ? [...rolesInput, newRole] : rolesInput;
    const orgRoleHash = {};

    arr.forEach((o) => {
      if (orgRoleHash[o.orgId]) orgRoleHash[o.orgId] += 1;
      else orgRoleHash[o.orgId] = 1;
    });

    if (
      Object.keys(orgRoleHash)
        .map((key) => orgRoleHash[key])
        .every((c) => c === 1)
    ) {
      this.setState(
        {
          loading: true,
        },
        () => {
          userUpdateMutation({
            variables: {
              input: {
                id,
                roles: arr,
              },
            },
          }).then((response) => {
            const {
              data: {
                userUpdate: { result, success },
              },
            } = response;

            this.setState({ create: false, orgId: null, role: null });

            if (success) {
              userQuery.refetch().then(() => {
                this.setState({
                  edit: null,
                  loading: false,
                  roles: result.roles,
                });
              });
            } else {
              window.alert('There was an issue. Try again.');
            }
          });
        },
      );
    } else {
      window.alert('Only one role per organization.');
    }
  };

  saveRoleEdit = () => {
    const { edit, roles } = this.state;

    if (edit.role && edit.orgId) {
      const i = roles.findIndex((r) => r.id === edit.id);

      this.setState(
        {
          edit: null,
          roles: [...roles.slice(0, i), ...roles.slice(i + 1, roles.length)],
        },
        () => this.saveRole(),
      );
    } else {
      this.saveRole();
    }
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <Profile
        {...this.props}
        cancel={this.back}
        deleteRole={this.deleteRole}
        handleChange={this.handleChange}
        reset={this.reset}
        resetPassword={this.resetPassword}
        save={this.save}
        saveRole={this.saveRole}
        saveRoleEdit={this.saveRoleEdit}
        state={this.state}
        resetUser={this.resetUser}
      />
    );
  }
}

export default compose(
  withRouter,
  graphql(UserQuery, {
    name: 'userQuery',
    options: () => ({
      variables: {
        id: Cookie.get(`una-${global.environment}`),
      },
      fetchPolicy: 'network-only',
    }),
  }),
  graphql(OrgsQuery, {
    name: 'orgsQuery',
    options: () => ({
      variables: {
        id: 'empty',
        order: 'title',
      },
    }),
  }),
  graphql(SessionLoginMutation, { name: 'sessionLoginMutation' }),
  graphql(UserUpdateMutation, { name: 'userUpdateMutation' }),
)(ProfileContainer);
