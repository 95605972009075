import { gql } from 'apollo-boost';

const JobPostChangeUpdate = gql`
  mutation JobPostChangeUpdate($input: JobPostChangeUpdateInput) {
    jobPostChangeUpdate(input: $input) {
      success
      result {
        # remove the unwanted fields from result
        id
        status
        jobPostId
        jobPost {
          designation
          description
          positionsAvailable
          qualification
          status
        }
      }
      errors {
        backtrace
        message
        code
      }
    }
  }
`;

export default JobPostChangeUpdate;
