import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { useParams, useNavigate } from 'react-router-dom';

import SignatureDocumentCreateMutation from '../../graphql/mutations/Signature/SignatureDocumentCreate';
import SignatureDocumentUpdateMutation from '../../graphql/mutations/Signature/SignatureDocumentUpdate';
import SignatureDocumentQuery from '../../graphql/queries/Document/SignatureDocument';
import LoadingPane from '../Shared/LoadingPane';
import Toolbar from '../Shared/Toolbar';
import FormInput from '../Shared/Form/Input';
import Checkbox from '../Shared/Checkbox';

const DocumentDetails = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const [variable, setVariable] = useState({
    id: params.documentId,
  });
  const [signatureDocumentUpdateMutation] = useMutation(
    SignatureDocumentUpdateMutation,
  );
  const [signatureDocumentCreateMutation] = useMutation(
    SignatureDocumentCreateMutation,
  );

  const { loading, data } = useQuery(SignatureDocumentQuery, {
    variables: variable,
  });

  const [id, setId] = useState();
  const [annualExpiration, setAnnualExpiration] = useState('false');
  const [title, setTitle] = useState();
  const [doc, setDoc] = useState();
  const [url, setUrl] = useState();
  const [status, setStatus] = useState('inactive');
  const [description, setDescription] = useState();

  useEffect(() => {
    setId(data?.signatureDocument.id);
    setAnnualExpiration(data?.signatureDocument.annualExpiration || 'false');
    setTitle(data?.signatureDocument.title);
    setDoc(data?.signatureDocument.doc);
    setUrl(data?.signatureDocument.url);
    setStatus(data?.signatureDocument.status || 'inactive');
    setDescription(data?.signatureDocument.description);
  }, [loading, data]);

  const back = () => {
    navigate(`/organizations/${params.id}/documents`);
  };

  const save = () => {
    const isCreate = params.documentId === 'create' ? true : false;
    if (validateForm(isCreate)) {
      let mutation = isCreate
        ? signatureDocumentCreateMutation
        : signatureDocumentUpdateMutation;
      let data = {
        orgId: params.id,
        annualExpiration: annualExpiration === 'true',
        description,
        status,
        title,
      };
      (async () => {
        if (doc) {
          const uploadedDocument = await saveAttachment();
          data.doc = uploadedDocument;
        }
        
        if (id) data.id = id;

        mutation({
          variables: {
            input: data,
          },
        }).then((response) => {
          const key = Object.keys(response.data)[0];
          const { errors, result, success } = response.data[key];

          if (success) {
            back();
          } else {
            window.alert(errors[0].message);
          }
        });
      })();
    }
  };

  const saveAttachment = () => {
    return new Promise((success) => {
      const xhr = new XMLHttpRequest();
      const fileName = doc.name;
      const config = {
        type: doc.type,
      };
      const f = new File([doc.base64], fileName, config);
      const formData = new FormData();
      formData.append('file', f);

      xhr.open('POST', process.env.REACT_APP_ATTACHMENT_URL);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          success(JSON.parse(xhr.response));
        }
      };

      xhr.send(formData);
    });
  };

  const validateForm = (isCreate) => {
    if (!doc && isCreate) {
      window.alert('Document is required.');
      return false;
    } else if (!title) {
      window.alert('Title is required.');
      return false;
    }
    return true;
  };

  const handleChange = (key, value) => {
    if (value) {
      const statusValue = value ? 'active' : 'inactive';
      setStatus(statusValue);
    }
  };

  const handleCheckboxChange = (value) => {
    setAnnualExpiration(value ? 'true' : 'false');
  };

  return (
    <div className="document-view full-screen-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : (
        <>
          <Toolbar
            title={id ? 'Edit Document' : 'Add New Document'}
            active={status.toLowerCase() === 'active'}
            cancel={back}
            handleChange={handleChange}
            isToggle={true}
            save={save}
          />

          <div className="content-frame">
            <div className="centered-frame">
              <div className="section-frame">
                <div className="section-header-frame">
                  <div className="section-header">Documents Details</div>
                </div>

                <div className="form-frame">
                  <FormInput
                    defaultValue={title}
                    header="Title"
                    placeholder="Enter Title of Document"
                    onChange={(e) => setTitle(e.target.value)}
                  />

                  <div className="form-input-frame">
                    <div className="input-title">File Upload</div>

                    <div className="form-file">
                      <div className="file-frame">
                        <div
                          className={`file-name ${
                            url || doc ? 'black' : 'hint'
                          }`}
                        >
                          {doc ? doc.name : 'ex: document.pdf'}
                        </div>

                        <button className="choose-file-button">
                          Choose File
                        </button>
                        <input
                          className="file-input"
                          onChange={(e) => {
                            const doc = e.target.files[0];

                            if (doc.type === 'application/pdf') {
                              setUrl(URL.createObjectURL(doc));
                              let file = e.target.files[0],
                                reader = new FileReader();

                              reader.onload = (response) => {
                                setDoc({
                                  base64: response.target.result,
                                  name: file.name,
                                  type: file.type,
                                });
                              };

                              reader.readAsDataURL(file);
                            } else {
                              window.alert(
                                `${doc.name} is not a supported file type.`,
                              );
                            }
                          }}
                          type="file"
                        />
                      </div>
                    </div>
                    {url ? (
                      <div className="thumbnail">
                        <div href={url}>
                          <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <div className="file-label hint">Preview</div>
                            <embed src={url}></embed>
                          </a>
                        </div>
                      </div>
                    ) : (
                      <div className="file-label hint">No preview</div>
                    )}
                  </div>

                  <div className="checkbox-frame">
                    <div className="checkbox-label">
                      Annual Expiration Reminder
                    </div>
                    <Checkbox
                      checked={annualExpiration.toLowerCase() === 'true'}
                      onChange={handleCheckboxChange}
                    />
                  </div>

                  <div
                    className="form-input-frame"
                    style={{ width: '100%', margin: 0 }}
                  >
                    <div className="input-title">Description</div>

                    <textarea
                      className="form-area"
                      defaultValue={description}
                      placeholder="Enter description for document..."
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className={id ? 'section-frame' : 'hide'}></div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DocumentDetails;
