import { gql } from 'apollo-boost';

const UsersQuery = gql`
  query DashboardUsersQuery(
    $first: Int
    $after: String
    $search: String
    $roleId: String
  ) {
    users(
      first: $first
      after: $after
      order: "first_name"
      search: $search
      roleId: $roleId
    ) {
      edges {
        cursor
        node {
          id
          attachment(label: "avatar") {
            id
            label
            url
          }
          email
          firstName
          lastName
          roles {
            id
            name
            org {
              id
              title
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export default UsersQuery;
