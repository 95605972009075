import { gql } from 'apollo-boost';

const DocumentReferenceQuery = gql`
  query DocumentReferenceQuery($id: ID, $reference: ID) {
    user(id: $id) {
      id
      reference(id: $reference) {
        id
        clinicalCompetancy
        communication
        eligibleForRehire
        email
        endDate
        firstName
        invitationNote
        lastName
        otherDetails
        organization
        phone
        professionalism
        relationship
        startDate
        status
        technologyCompetancy
        attendancePunctuality
        title
      }
    }
  }
`;

export default DocumentReferenceQuery;
