import React from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import moment from 'moment';
import CoursesQuery from '../../graphql/queries/Org/Courses';

import CourseCreateMutation from '../../graphql/mutations/Courses/Create';
import CourseRemoveMutation from '../../graphql/mutations/Courses/Remove';
import CourseUpdateMutation from '../../graphql/mutations/Courses/Update';
import CourseChapterCreateMutation from '../../graphql/mutations/Courses/Chapter/Create';
import CourseChapterUpdateMutation from '../../graphql/mutations/Courses/Chapter/Update';
import CourseQuestionCreateMutation from '../../graphql/mutations/Courses/Question/Create';
import OrgContentRemoveMutation from '../../graphql/mutations/Org/Content/Remove';

import DashboardCourses from '../../components/Dashboard/Courses';

import LoadingPane from '../../components/Shared/LoadingPane';
import { withRouter } from '../withRouter';

class DashboardCoursesContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      course: {},
      isModal: false,
      loading: false,
      onload: true,
    }
  }

  componentDidMount() {
    const {
      coursesQuery: { loading, refetch },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      refetch().then(() => this.setup());
    } else {
      this.setup();
    }
  }

  componentDidUpdate() {
    this.setup();
  }

  setup = () => {
    const {
      coursesQuery: { loading, org },
      params,
      user,
    } = this.props;
    const { onload } = this.state;

    const role = user.roles.find((r) => r.org.id === params.id);

    if (onload && !loading) {
      this.setState({
        ...org,
        admin: role ? role.name === 'corporate' : false,
        onload: false,
        orgId: org.id,
      });
    }
  };

  contentRemove = (hide) => {
    const { coursesQuery, params, orgContentRemoveMutation } = this.props;
    const { course } = this.state;

    this.setState(
      {
        loading: true,
      },
      () => {
        orgContentRemoveMutation({
          variables: {
            input: {
              org: params.id,
              contentType: 'course',
              contentId: course.id,
            },
          },
        })
          .then((response) => {
            const {
              data: {
                orgContentRemove: { errors, success },
              },
            } = response;

            if (success) {
              coursesQuery.refetch().then((response) => {
                this.handleChange('loading', false);

                hide();

                setTimeout(() => {
                  this.setState({
                    course: {},
                    isModal: false,
                    courses: response.data.org.courses,
                  });
                }, 300);
              });
            } else {
              window.alert(errors[0].message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.handleChange('loading', false);
          });
      },
    );
  };

  deleteCourse = (id, i) => {
    const { courseRemoveMutation } = this.props;
    const { courses } = this.state;

    document.body.click();

    this.setState(
      {
        loading: true,
      },
      () => {
        courseRemoveMutation({
          variables: {
            input: {
              id,
            },
          },
        }).then((response) => {
          const {
            data: {
              courseRemove: { errors },
            },
          } = response;

          if (errors) {
            window.alert(errors[0].message);
          } else {
            this.setState({
              loading: false,
              courses: {
                ...courses,
                nodes: [
                  ...courses.nodes.slice(0, i),
                  ...courses.nodes.slice(i + 1, courses.nodes.length),
                ],
              },
            });
          }
        });
      },
    );
  };

  duplicateCourse = (course) => {
    const { courseCreateMutation } = this.props;
    const { kind, orgId } = this.state;
    const { desc, durationMins, passingScore, title ,courseType} = course;

    document.body.click();

    this.setState(
      {
        loading: true,
      },
      () => {
        courseCreateMutation({
          variables: {
            input: {
              title: `${title} Copy (${moment().format(
                'MMM DD, YYYY hh:mma',
              )})`,
              desc,
              courseType,
              durationMins,
              passingScore,
              orgId,
              kind,
            },
          },
        }).then((response) => {
          const {
            data: {
              courseCreate: { errors, result },
            },
          } = response;

          if (errors) {
            window.alert(errors[0].message);
          } else {
            this.duplicateChapters(course.chapters, 0, result);
          }
        });
      },
    );
  };

  duplicateChapters = (arr, i, course) => {
    const {
      coursesQuery,
      courseChapterCreateMutation,
      courseChapterUpdateMutation,
    } = this.props;

    if (i === arr.length) {
      coursesQuery.refetch().then((response) => {
        this.setState({
          loading: false,
          courses: response.data.org.courses,
        });
      });
    } else {
      const { content, questions, rank, title ,courseType} = arr[i];

      courseChapterCreateMutation({
        variables: {
          input: {
            courseId: course.id,
            rank,
            title,
            courseType
          },
        },
      }).then((response) => {
        const {
          data: {
            courseChapterCreate: { result },
          },
        } = response;

        courseChapterUpdateMutation({
          variables: {
            input: {
              id: result.id,
              content,
            },
          },
        }).then(() => {
          if (questions.length > 0) {
            this.duplicateQuestions(questions, 0, result, arr, i + 1, course);
          } else {
            this.duplicateChapters(arr, i + 1, course);
          }
        });
      });
    }
  };

  duplicateQuestions = (arr, i, chapter, cArr, cI, course) => {
    if (i === arr.length) {
      this.duplicateChapters(cArr, cI, course);
    } else {
      const { courseQuestionCreateMutation } = this.props;
      const { answers, isRandomized, rank, title ,courseType} = arr[i];

      courseQuestionCreateMutation({
        variables: {
          input: {
            chapterId: chapter.id,
            answers,
            isRandomized,
            rank,
            title,
            courseType
          },
        },
      }).then(() => {
        this.duplicateQuestions(arr, i + 1, chapter, cArr, cI, course);
      });
    }
  };

  goToCourseRoute = (id) => {
    const { location, navigate, params } = this.props;

    navigate(`/courses/${id || 'create'}`, {
      state: {
        previous: location.pathname,
        org: params.id,
      },
    });
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  toggleModal = (course, isModal) => {
    document.body.click();

    this.setState({ course, isModal });
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <DashboardCourses
        {...this.props}
        contentRemove={this.contentRemove}
        deleteCourse={this.deleteCourse}
        duplicateCourse={this.duplicateCourse}
        goToCourseRoute={this.goToCourseRoute}
        state={this.state}
        toggleModal={this.toggleModal}
      />
    );
  }
}

export default compose(
  withRouter,
  graphql(CoursesQuery, {
    name: 'coursesQuery',
    options: (props) => ({
      variables: {
        id: props.params.id,
      },
    }),
  }),
  graphql(CourseCreateMutation, { name: 'courseCreateMutation' }),
  graphql(CourseRemoveMutation, { name: 'courseRemoveMutation' }),
  graphql(CourseUpdateMutation, { name: 'courseUpdateMutation' }),
  graphql(CourseChapterCreateMutation, { name: 'courseChapterCreateMutation' }),
  graphql(CourseChapterUpdateMutation, { name: 'courseChapterUpdateMutation' }),
  graphql(CourseQuestionCreateMutation, {
    name: 'courseQuestionCreateMutation',
  }),
  graphql(OrgContentRemoveMutation, { name: 'orgContentRemoveMutation' }),
)(DashboardCoursesContainer);
