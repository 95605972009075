import React from 'react';

import Cookie from 'js-cookie';
import { withRouter } from '../withRouter';

import Dashboard from '../../components/Dashboard';

import LoadingPane from '../../components/Shared/LoadingPane';

class DashboardContainer extends React.Component {
  constructor(props) {
    super(props);
    const { params, context } = props;
    const { user } = context;
    const role = user.roles.find((r) => r.org.id === params.id);

    this.state = {
      user,
      admin: role ? role.name === 'corporate' : false,
      isRole: false,
      onload: true,
      role,
    };
  }

  componentDidMount() {
    const { location, navigate } = this.props;
    const { admin, role } = this.state;

    if (location.pathname.includes('clients') && !admin) {
      navigate(`/organizations/${role.org.id}/roster`);
    } else {
      this.setup();
    }
  }

  componentDidUpdate() {
    this.setup();
  }

  setup = () => {
    const { onload, user } = this.state;

    if (onload) {
      this.setState({
        onload: false,
        roles: user.roles,
        firstName: user.firstName,
        lastName: user.lastName,
      });
    }
  };

  goToOrgRoute = (role) => {
    const { navigate, params } = this.props;

    const hasModule = role.org.modules.includes(params.tab);
    const recruiter = role.name === 'recruiter';
    Cookie.set('org', role.org.id);

    this.handleChange('loading', true);

    navigate(
      `/organizations/${role.org.id}/${
        hasModule && !recruiter ? params.tab : 'roster'
      }`,
    );

    window.location.reload();
  };

  goToTabRoute = (tab) => {
    const { navigate, params } = this.props;

    navigate(`/organizations/${params.id}/${tab}`);
  };

  goToProfileRoute = () => {
    const { navigate, location } = this.props;

    navigate('/profile', { state: { previous: location.pathname } });
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  logout = () => {
    const { navigate } = this.props;
    Cookie.remove(`una-${global.environment}`);
    Cookie.remove(`una-${global.environment}-token`);
    Cookie.remove(`una-exp-${global.environment}`);
    Cookie.remove('org');

    navigate('/');
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <Dashboard
        {...this.props}
        goToOrgRoute={this.goToOrgRoute}
        goToTabRoute={this.goToTabRoute}
        goToProfileRoute={this.goToProfileRoute}
        handleChange={this.handleChange}
        logout={this.logout}
        state={this.state}
      />
    );
  }
}

export default withRouter(DashboardContainer);
