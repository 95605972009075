import React from 'react';
import logo from '../../theme/images/hire-logo-2x.png'

const LoginLogo = () => {
  return (
    <div className="login-logo">
      <img
        className="logo"
        src={logo}
        alt="una-logo"
        style={{ height: 36 }}
      />
    </div>
  );
};

export default LoginLogo;
