import React from 'react';

import _ from 'lodash';
import moment from 'moment';

import Titer from '../../../../components/Documents/Document/Titer';

import STRINGS from '../../../../localization';

const titerTypes = [
  { id: 'hepB', value: 'Hep B' },
  { id: 'measles', value: 'Measles (Rubeola)' },
  { id: 'mumps', value: 'Mumps' },
  { id: 'rubella', value: 'Rubella' },
  { id: 'tdap', value: 'TDAP' },
  { id: 'varicella', value: 'Varicella' },
];

export default class TiterContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.state,
      edit: props.params.document === 'create',
      isModal: false,
      loading: false,
      verificationData: {},
      user: props.context.user,
    };
  }

  componentDidMount() {
    const { params, state } = this.props;
    const { title } = state;

    if (params.document !== 'create') {
      this.setState({
        title: titerTypes.find((o) => title.includes(o.value)).id,
      });
    }
  }

  componentDidUpdate() {
    if (!_.isEqual(this.props.mobile, this.state.mobile)) {
      this.handleChange('mobile', this.props.state.mobile);
    }
  }

  documentRemove = (hide) => {
    this.setState(
      {
        loading: true,
      },
      () => this.props.documentRemove(hide),
    );
  };

  handleChange = (key, value) => {
    this.setState(_.set(this.state, key, value));
  };

  handleTitle = (e) => {
    const { documentNumber, issuedOn, isTiter } = this.state;

    const title = e.target.value;

    this.setState({
      documentNumber,
      issuedOn,
      isTiter,
      title,
    });
  };

  save = () => {
    const { save, saveAttachment } = this.props;
    const {
      documentNumber,
      kind,
      image,
      imageUrl,
      imageBack,
      issuedOn,
      isTiter,
      title,
      verificationData,
    } = this.state;

    const valid =
      isTiter !== 'no'
        ? documentNumber && title && issuedOn && (image || imageUrl)
        : verificationData.date1 &&
          verificationData.date2 &&
          (image || imageUrl);
    const data = {
      documentNumber: documentNumber || 'n/a',
      kind,
      issuedOn: issuedOn || moment().format('YYYY-MM-DD'),
      issuingState: 'n/a',
      title: `${titerTypes.find((o) => o.id === title).value} ${STRINGS.titer}`,
    };

    if (valid) {
      this.setState(
        {
          loading: true,
        },
        async () => {
          if (image) data.image = await saveAttachment(image);

          if (imageBack) data.imageBack = await saveAttachment(imageBack);

          save(data);
        },
      );
    }
  };

  render() {
    return (
      <Titer
        {...this.props}
        documentRemove={this.documentRemove}
        handleChange={this.handleChange}
        handleTitle={this.handleTitle}
        save={this.save}
        state={this.state}
        titerTypes={titerTypes}
      />
    );
  }
}
