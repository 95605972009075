import React from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';

import UserQuery from '../../graphql/queries/Roster/Course';

import RosterCourse from '../../components/Roster/Course';

import LoadingPane from '../../components/Shared/LoadingPane';
import { withRouter } from '../withRouter';

class RosterCourseContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      onload: true,
    };
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate() {
    this.setup();
  }

  setup = () => {
    const {
      userQuery: { loading, user, org },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      this.setState({
        ...user,
        attachment: org ? org.attachments[0] : null,
        onload: false,
      });
    }
  };

  back = () => {
    const {
      navigate,
      params: { id, roster },
    } = this.props;

    navigate(`/organizations/${id}/roster/${roster}`);
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <RosterCourse
        back={this.back}
        state={this.state}
        user={this.props.context.user}
        orgId={this.props.params.id}
      />
    );
  }
}

export default compose(
  withRouter,
  graphql(UserQuery, {
    name: 'userQuery',
    options: ({ params: { id, roster, item } }) => ({
      variables: {
        id: roster,
        course: item,
        org: id,
      },
    }),
  }),
)(RosterCourseContainer);
