import React from 'react';

import _ from 'lodash';
import moment from 'moment';

import LoadingPane from '../../Shared/LoadingPane';
import ModalPane from '../../Shared/ModalPane';

import STRINGS from '../../../localization';

import { jobTitles } from '../../../utils/Constants';

const ReferenceDocument = (props) => {
  return <Read {...props} />;
};

const Read = (props) => {
  const { back, documentRemove, handleChange, state } = props;
  const {
    communication,
    clinicalCompetancy,
    eligibleForRehire,
    otherDetails,
    professionalism,
    technologyCompetancy,
    attendancePunctuality,
    attendance,
    email,
    endDate,
    firstName,
    isModal,
    lastName,
    loading,
    mobile,
    organization,
    phone,
    relationship: r,
    startDate,
    title,
  } = state;

  const relationship = jobTitles.find((o) => o.id === r);
  const jobTitle = jobTitles.find((o) => o.id === title);
  const ratings = {
    clinical: {
      0: 'Unable to answer',
      1: 'Still learning',
      2: 'Gets the job done',
      3: 'Can serve as a preceptor',
    },
    comm: {
      0: 'Unable to answer',
      1: 'Painfully quiet',
      2: 'Collaborative',
      3: 'Role-model for others',
    },
    pro: {
      0: 'Unable to answer',
      1: 'Varies from time to time',
      2: 'Consistently positive',
      3: 'Stellar',
    },
    tech: {
      0: 'Unable to answer',
      1: 'Hand holding required',
      2: 'Savvy/catches on easily',
      3: 'Should have been an engineer',
    },
    attendance: attendance
  };

  return (
    <div className="document-read-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      <div className="toolbar">
        {mobile ? (
          <div className="mobile-button bg-grey-2" onClick={back}>
            <div className="mobile-button-icon">
              <img
                src={require('../../../theme/images/buttons/button-x-icon-2x.png')}
                alt="button-x-icon"
              />
            </div>
          </div>
        ) : (
          <button className="back-button bg-grey-2" onClick={back}>
            {STRINGS.back}
          </button>
        )}

        <div className="page-title">{`${STRINGS.view} ${STRINGS.reference}`}</div>
      </div>

      <div className="content-frame scroll" style={{ background: '#F6F8F8' }}>
        <div className={mobile ? 'document-frame mobile' : 'document-frame'}>
          {/* reference */}
          <div className="header">{`${STRINGS.reference} ${STRINGS.information}`}</div>

          <div className="read-value-frame">
            <div className="value-label capitalize">{STRINGS.name}</div>
            <div className="value-label capitalize bold">{`${firstName} ${lastName}`}</div>
          </div>

          <div className="read-value-frame">
            <div className="value-label capitalize">{STRINGS.email}</div>
            <div className="value-label bold">{email}</div>
          </div>

          <div className="read-value-frame">
            <div className="value-label capitalize">{STRINGS.phone}</div>

            {phone ? (
              <div className="value-label capitalize bold">{phone}</div>
            ) : (
              <div className="value-label capitalize italic hint">
                {STRINGS.notProvided}
              </div>
            )}
          </div>

          <div className="read-value-frame">
            <div className="value-label capitalize">{STRINGS.relationship}</div>

            {relationship ? (
              <div className="value-label capitalize bold">
                {relationship.value}
              </div>
            ) : (
              <div className="value-label capitalize italic hint">
                {STRINGS.notProvided}
              </div>
            )}
          </div>

          <div className="read-value-frame" />

          <div className="read-value-frame" />

          {/* employment */}
          <div className="header">{`${STRINGS.employment} ${STRINGS.information}`}</div>

          <div className="read-value-frame">
            <div className="value-label capitalize">{STRINGS.jobTitle}</div>

            {jobTitle ? (
              <div className="value-label capitalize bold">
                {jobTitle.value}
              </div>
            ) : (
              <div className="value-label capitalize italic hint">
                {STRINGS.notProvided}
              </div>
            )}
          </div>

          <div className="read-value-frame">
            <div className="value-label capitalize">{STRINGS.organization}</div>

            {organization ? (
              <div className="value-label capitalize bold">{organization}</div>
            ) : (
              <div className="value-label capitalize italic hint">
                {STRINGS.notProvided}
              </div>
            )}
          </div>

          <div className="read-value-frame">
            <div className="value-label capitalize">
              {STRINGS.eligibleForRehire}
            </div>

            {eligibleForRehire ? (
              <div className="value-label capitalize bold">
                {eligibleForRehire}
              </div>
            ) : (
              <div className="value-label capitalize italic hint">
                {STRINGS.notProvided}
              </div>
            )}
          </div>

          <div className="read-value-frame">
            <div className="value-label">{`${_.startCase(STRINGS.startDate)} ${
              STRINGS.of
            } ${_.capitalize(STRINGS.employment)}`}</div>

            {startDate ? (
              <div className="value-label capitalize bold">
                {moment(startDate).format('MMM DD, YYYY')}
              </div>
            ) : (
              <div className="value-label capitalize italic hint">
                {STRINGS.notProvided}
              </div>
            )}
          </div>

          <div className="read-value-frame">
            <div className="value-label">{`${_.startCase(STRINGS.endDate)} ${
              STRINGS.of
            } ${_.capitalize(STRINGS.employment)}`}</div>

            {endDate ? (
              <div className="value-label capitalize bold">
                {moment(endDate).format('MMM DD, YYYY')}
              </div>
            ) : (
              <div className="value-label capitalize italic hint">
                {STRINGS.notProvided}
              </div>
            )}
          </div>

          <div className="read-value-frame" />

          {/* ratings */}
          <div className="header">{`${STRINGS.reference} ${STRINGS.qualitative} ${STRINGS.ratings}`}</div>

          <div className="read-value-frame">
            <div className="value-label capitalize">
              {STRINGS.professionalism}
            </div>

            {ratings.pro[professionalism] ? (
              <div className="value-label capitalize bold">
                {ratings.pro[professionalism]}
              </div>
            ) : (
              <div className="value-label capitalize italic hint">
                {STRINGS.notProvided}
              </div>
            )}
          </div>

          <div className="read-value-frame">
            <div className="value-label capitalize">
              {STRINGS.communicationWithOthers}
            </div>

            {ratings.comm[communication] ? (
              <div className="value-label capitalize bold">
                {ratings.comm[communication]}
              </div>
            ) : (
              <div className="value-label capitalize italic hint">
                {STRINGS.notProvided}
              </div>
            )}
          </div>

          <div className="read-value-frame">
            <div className="value-label capitalize">
              {STRINGS.clinicalCompetancy}
            </div>

            {ratings.clinical[clinicalCompetancy] ? (
              <div className="value-label capitalize bold">
                {ratings.clinical[clinicalCompetancy]}
              </div>
            ) : (
              <div className="value-label capitalize italic hint">
                {STRINGS.notProvided}
              </div>
            )}
          </div>

          <div className="read-value-frame">
            <div className="value-label capitalize">
              {STRINGS.technologyCompetancy}
            </div>

            {ratings.tech[technologyCompetancy] ? (
              <div className="value-label capitalize bold">
                {ratings.tech[technologyCompetancy]}
              </div>
            ) : (
              <div className="value-label capitalize italic hint">
                {STRINGS.notProvided}
              </div>
            )}
          </div>

          <div className="read-value-frame">
            <div className="value-label capitalize">
              {STRINGS.attendancePunctuality}
            </div>

            {ratings.attendance[attendancePunctuality] ? (
              <div className="value-label capitalize bold">
                {ratings.attendance[attendancePunctuality]}
              </div>
            ) : (
              <div className="value-label capitalize italic hint">
                {STRINGS.notProvided}
              </div>
            )}
          </div>

          <div className="read-value-frame"></div>

          <div
            className={otherDetails ? 'read-value-frame' : 'hide'}
            style={{ width: '100%' }}
          >
            <div className="value-label capitalize">{STRINGS.otherDetails}</div>

            <div className="value-label bold">{otherDetails}</div>
          </div>
        </div>
      </div>

      <ModalPane
        className="document-delete-pane"
        content={(hide) => (
          <div>
            <div className="modal-header">
              {STRINGS.referenceDeletePaneMessage}
            </div>

            <div className="modal-button-frame">
              <button className="modal-button bg-grey-2" onClick={hide}>
                {STRINGS.cancel}
              </button>
              <button
                className="modal-button bg-una"
                onClick={() => documentRemove(hide)}
              >
                {STRINGS.save}
              </button>
            </div>
          </div>
        )}
        show={isModal}
        hide={() => handleChange('isModal', false)}
      />
    </div>
  );
};

export default ReferenceDocument;
