import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-apollo';
import Checkbox from '../../Shared/Checkbox';

import TestsQuery from '../../../graphql/queries/Tests/Summary';
import ChecklistsQuery from '../../../graphql/queries/Checklists';
import CoursesQuery from '../../../graphql/queries/Courses';
import DocumentSummaryQuery from '../../../graphql/queries/Documents/';
import LoadingPane from '../../Shared/LoadingPane';

const OrgContentSection = (props) => {
  const {
    sectionExpanded,
    toggleExpansion,
    items,
    selectedItems,
    selectAll,
    setSelected,
    sectionID,
  } = props;
  const sectionRef = useRef(null);
  const allSelected =
    items && selectedItems ? items.length === selectedItems.length : false;
  return (
    <>
      {items && items.length > 0 && (
        <div
          className="section-frame"
          style={{
            height: sectionExpanded
              ? 44 + 15 + sectionRef.current.getBoundingClientRect().height
              : 44,
          }}
        >
          <div className="header-frame">
            <div className="header">{`${sectionID} (${items.length})`}</div>

            <div className="flex-frame">
              <button
                className="select-all-button orange"
                onClick={() => selectAll(allSelected ? [] : items)}
              >
                {`${allSelected ? 'Deselect' : 'Select'} All`}
              </button>

              <button
                className="expand-button blue"
                onClick={() => toggleExpansion(!sectionExpanded)}
              >
                {sectionExpanded ? 'Collapse' : 'Expand'}
              </button>
            </div>
          </div>

          <ul className="section-list-view" ref={sectionRef}>
            {items.map((item) => {
              const { id, kind, org, title } = item;

              const isGlobal = kind ? kind.toLowerCase() === 'global' : false;
              const checked = selectedItems.includes(id);

              return (
                <li
                  className="section-cell"
                  key={id}
                  onClick={() => setSelected(selectedItems, id, !checked)}
                >
                  <Checkbox checked={checked} readOnly={true} />

                  <div className="section-cell-title">{`${title}${
                    org && !isGlobal ? ` (${org.title})` : ''
                  }`}</div>

                  <div
                    className={`${
                      isGlobal ? 'section-cell-title red' : 'hide'
                    }`}
                  >
                    ({isGlobal ? 'UNA' : ''})
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};

const OrgContent = (props) => {
  const { handleChange, state } = props;
  const {
    checklists: csChecklists,
    courses: csCourses,
    tests: csTests,
    signatureDocuments: csDocuments,
  } = state;

  const [section1Expanded, setSection1Expanded] = useState(false);
  const [tests, setTests] = useState([]);
  const [selectedTests, setSelectedTests] = useState(
    csTests?.nodes.map((t) => t.id) || [],
  );
  const [section2Expanded, setSection2Expanded] = useState(false);
  const [checklists, setChecklists] = useState([]);
  const [selectedChecklists, setSelectedChecklists] = useState(
    csChecklists?.nodes.map((t) => t.id) || [],
  );
  const [section3Expanded, setSection3Expanded] = useState(false);
  const [courses, setCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState(
    csCourses?.nodes.map((t) => t.id) || [],
  );
  const [section4Expanded, setSection4Expanded] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState(
    csDocuments?.nodes.map((t) => t.id) || [],
  );
  const variable = {
    status: ['active'],
  };
  const { loading: testLoading, data: testData } = useQuery(TestsQuery, {
    variables: variable,
  });
  const { loading: checklistLoading, data: checklistData } = useQuery(
    ChecklistsQuery,
    {
      variables: variable,
    },
  );
  const { loading: courseLoading, data: courseData } = useQuery(CoursesQuery, {
    variables: variable,
  });
  const { loading: documentLoading, data: documentData } = useQuery(
    DocumentSummaryQuery,
    {
      variables: variable,
    },
  );

  useEffect(() => {
    setTests(testData?.tests?.nodes);
  }, [testLoading, testData]);

  useEffect(() => {
    setChecklists(checklistData?.checklists?.nodes);
  }, [checklistLoading, checklistData]);

  useEffect(() => {
    setCourses(courseData?.courses?.nodes);
  }, [courseLoading, courseData]);

  useEffect(() => {
    const documentCollection =
      documentData && documentData.signatureDocuments
        ? documentData.signatureDocuments.edges.map((docEdge) => docEdge.node)
        : [];
    setDocuments(documentCollection);
  }, [documentLoading, documentData]);

  const getSetFunction = (type) => {
    if (type === 'Tests') {
      return setSelectedTests;
    }
    if (type === 'Checklists') {
      return setSelectedChecklists;
    }
    if (type === 'Courses') {
      return setSelectedCourses;
    }
    return setSelectedDocuments;
  };

  const selectAllItems = (type, collection) => {
    const setFunction = getSetFunction(type);
    const newCollection = collection.map((item) => item.id);
    setFunction(newCollection);
    handleChange(`selected${type}`, newCollection);
  };

  const setSelected = (type, collection, id, include) => {
    const setFunction = getSetFunction(type);
    let returnCollection = collection;
    const index = collection.indexOf(id);
    if (index > -1) {
      returnCollection = [
        ...collection.slice(0, index),
        ...collection.slice(index + 1, collection.length),
      ];
      collection.splice(index, 1); // 2nd parameter means remove one item only
      console.log(`removed---${include}`);
    } else {
      returnCollection = [...collection, id];
      console.log(`added---${include}`);
    }
    setFunction(returnCollection);
    handleChange(`selected${type}`, returnCollection);
  };

  const stillLoading =
    testLoading || courseLoading || documentLoading || checklistLoading;
  return (
    <div className="org-content-view">
      {stillLoading &&
      (!testData || !courseData || !checklistData || !documentData) ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}
      <div className="centered-frame">
        <OrgContentSection
          sectionExpanded={section1Expanded}
          toggleExpansion={setSection1Expanded}
          items={tests}
          selectedItems={selectedTests}
          selectAll={(collection) => {
            selectAllItems('Tests', collection);
          }}
          setSelected={(collection, id, include) => {
            setSelected('Tests', collection, id, include);
          }}
          sectionID={'Tests'}
        />
        <OrgContentSection
          sectionExpanded={section2Expanded}
          toggleExpansion={setSection2Expanded}
          items={checklists}
          selectedItems={selectedChecklists}
          selectAll={(collection) => {
            selectAllItems('Checklists', collection);
          }}
          setSelected={(collection, id, include) => {
            setSelected('Checklists', collection, id, include);
          }}
          sectionID={'Checklists'}
        />
        <OrgContentSection
          sectionExpanded={section3Expanded}
          toggleExpansion={setSection3Expanded}
          items={courses}
          selectedItems={selectedCourses}
          selectAll={(collection) => {
            selectAllItems('Courses', collection);
          }}
          setSelected={(collection, id, include) => {
            setSelected('Courses', collection, id, include);
          }}
          sectionID={'Courses'}
        />
        <OrgContentSection
          sectionExpanded={section4Expanded}
          toggleExpansion={setSection4Expanded}
          items={documents}
          selectedItems={selectedDocuments}
          selectAll={(collection) => {
            selectAllItems('Documents', collection);
          }}
          setSelected={(collection, id, include) => {
            setSelected('Documents', collection, id, include);
          }}
          sectionID={'Documents'}
        />
      </div>
    </div>
  );
};

export default OrgContent;
