import * as React from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import moment from 'moment';
import qs from 'qs';
import { useMutation, useQuery } from 'react-apollo';
import Cookie from 'js-cookie';
import SessionLoginMutation from '../graphql/mutations/Session/Login';
import UserQuery from '../graphql/queries/User/Min';
import LoadingPane from '../components/Shared/LoadingPane';
import Bundle from '../containers/Bundle';
import Category from '../containers/Category';
import Course from '../containers/Course';
import Dashboard from '../containers/Dashboard';
import Document from '../containers/Documents';
import Login from '../containers/Login';
import NotFound from '../containers/NotFound';
import Org from '../containers/Org';
import PasswordReset from '../containers/PasswordReset';
import Profile from '../containers/Profile';
import Reference from '../containers/Documents/Reference';
import Roster from '../containers/Roster';
import RosterPacket from '../containers/Roster/Packet';
import RosterTest from '../containers/Roster/Test';
import RosterUpload from '../containers/Roster/Upload';
import RosterChecklist from '../containers/Roster/Checklist';
import RosterCourse from '../containers/Roster/Course';
import RosterInvite from '../containers/Roster/Invite';
import Test from '../containers/Test';
import User from '../containers/User';
import Createjobposts from '../containers/CreateJobPosts';
import Createpackage from '../containers/CreatePackages';
import Checklist from './Checklist';
import ChapterQuestion from './Question/Chapter';
import Chapter from './Chapter';
import CategoryQuestion from './Question/Category';
import CategoryChecklist from './Category/Checklist';
import DocumentDetails from '../components/Documents/DocumentDetails';

import { AuthContext } from './AuthContext';
import '../theme';

import { tokenList } from '../utils/Constants';

import FeedbackRequest from '../components/Dashboard/FeedbackRequest';
import FeedbackEmailRequest from '../components/Dashboard/FeedbackEmailRequest';
import FeedbackEmailPage from '../components/Dashboard/FeedbackEmailPage';
import ViewFeedback from '../components/Dashboard/ViewFeedback';
import FeedbackDetails from '../components/Dashboard/FeedbackDetails';
import ViewRosterList from '../components/Dashboard/ViewRosterList'
import CreatejobsEmailPage from '../components/CreateJobPosts/CreateJobPostsEmailPage';
import SubscribePage from "../components/Subscribe";
import PaymentCheckout from "./PaymentCheckout";



const App = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/error" element={<NotFound />} />
        <Route path="/getting_started" element={<PasswordReset />} />
        <Route
          path="/courses/:id"
          element={
            <RequireAuth>
              <Course />
            </RequireAuth>
          }
        />
        <Route
          path="/courses/:id/chapters/:chapter"
          element={
            <RequireAuth>
              <Chapter />
            </RequireAuth>
          }
        />
        <Route
          path="/courses/:id/chapters/:chapter/questions/:question"
          element={
            <RequireAuth>
              <ChapterQuestion />
            </RequireAuth>
          }
        />
        <Route path="/login" element={<Login />} />

        <Route path="/feedback/:ulid" element={<FeedbackEmailPage />} />

        <Route path="/createjobsemailpage" element={<CreatejobsEmailPage />} />

        <Route path="/organizations/:id/roster/:roster/subscribe" element={<SubscribePage />} />

        <Route path="/organizations/:id/rosters/subscribe" element={<SubscribePage />} />

        <Route path="/subscribe/:id/:roster/checkout" element={<PaymentCheckout />} />

        <Route
          path="/viewfeedback/:id/response"
          element={
            <RequireAuth>
              <FeedbackEmailRequest />
            </RequireAuth>
          }
        />

        <Route
          path="/viewfeedbacks"
          element={
            <RequireAuth>
              <ViewFeedback />
            </RequireAuth>
          }
        />
        <Route
          path="/organizations/:id/rosters"
          element={
            <RequireAuth>
              <ViewRosterList />
            </RequireAuth>
          }
        />
        <Route
          path="/viewfeedback/:id"
          element={
            <RequireAuth>
              <FeedbackDetails />
            </RequireAuth>
          }
        />

        <Route
          path="/organizations/:id/bundles/:bundle"
          element={
            <RequireAuth>
              <Bundle />
            </RequireAuth>
          }
        />

        <Route
          path="/organizations/:id/documents/:documentId"
          element={
            <RequireAuth>
              <DocumentDetails />
            </RequireAuth>
          }
        />

        <Route
          path="/organizations/:id/facilities/:facility"
          element={
            <RequireAuth>
              <Org />
            </RequireAuth>
          }
        />
        <Route
          path="/organizations/:id/roster/:roster"
          element={
            <RequireAuth>
              <Roster />
            </RequireAuth>
          }
        />
        <Route
          path="/organizations/:id/roster/:roster/courses/:item"
          element={
            <RequireAuth>
              <RosterCourse />
            </RequireAuth>
          }
        />
        <Route
          path="/organizations/:id/roster/:roster/checklists/:item"
          element={
            <RequireAuth>
              <RosterChecklist />
            </RequireAuth>
          }
        />
        <Route
          path="/organizations/:id/roster/:roster/checklists/:item/pdf"
          element={
            <RequireAuth>
              <RosterChecklist />
            </RequireAuth>
          }
        />
        <Route
          path="/organizations/:id/roster/:roster/tests/:item"
          element={
            <RequireAuth>
              <RosterTest />
            </RequireAuth>
          }
        />
        <Route
          path="/organizations/:id/roster/:roster/documents/:item"
          element={
            <RequireAuth>
              <Document />
            </RequireAuth>
          }
        />
        <Route
          path="/organizations/:id/roster/:roster/documents/:item/:document"
          element={
            <RequireAuth>
              <Document />
            </RequireAuth>
          }
        />
        <Route
          path="/organizations/:id/roster/:roster/references/:item"
          element={
            <RequireAuth>
              <Reference />
            </RequireAuth>
          }
        />
        <Route
          path="/organizations/:id/roster/:roster/packet"
          element={
            <RequireAuth>
              <RosterPacket />
            </RequireAuth>
          }
        />
        <Route
          path="/organizations/:id/roster/:roster/invite"
          element={
            <RequireAuth>
              <RosterInvite />
            </RequireAuth>
          }
        />
        <Route
          path="/organizations/:id/roster/upload"
          element={
            <RequireAuth>
              <RosterUpload />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/organizations/:id/:tab"
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        />
        <Route
          path="/organizations/:id/users/:user"
          element={
            <RequireAuth>
              <User />
            </RequireAuth>
          }
        />

        <Route
          path="/organizations/:id/jobposts/:job"
          element={
            <RequireAuth>
              <Createjobposts />
            </RequireAuth>
          }
        />

        <Route
          path="/organizations/:id/packages/:package_id"
          element={
            <RequireAuth>
              <Createpackage />
            </RequireAuth>
          }
        />

        <Route
          path="/profile"
          element={
            <RequireAuth>
              <Profile />
            </RequireAuth>
          }
        />

        <Route
          path="/tests/:id"
          element={
            <RequireAuth>
              <Test />
            </RequireAuth>
          }
        />
        <Route
          path="/360feedbackRequest"
          element={
            <RequireAuth>
              <FeedbackRequest />
            </RequireAuth>
          }
        />
        <Route
          path="/tests/:id/categories/:category"
          element={
            <RequireAuth>
              <Category />
            </RequireAuth>
          }
        />
        <Route
          path="/tests/:id/categories/:category/questions/:question"
          element={
            <RequireAuth>
              <CategoryQuestion />
            </RequireAuth>
          }
        />

        <Route
          path="/checklists/:id"
          element={
            <RequireAuth>
              <Checklist />
            </RequireAuth>
          }
        />
        <Route
          path="/checklists/:id/categories/:category"
          element={<CategoryChecklist />}
        />

        <Route
          exact
          path="/"
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        />
      </Routes>
    </AuthProvider>
  );
};

function AuthProvider({ children }) {
  const [user, setUser] = React.useState();
  const id = Cookie.get(`una-${global.environment}`);
  const [sessionLoginMutation, client] = useMutation(SessionLoginMutation);
  let value = {};

  const { loading, data } = useQuery(UserQuery, {
    variables: {
      id,
    },
    skip: user || !id,
  });

  const removeCookies = () => {
    Cookie.remove(`una-${global.environment}-token`);
    Cookie.remove(`una-${global.environment}`);
  };

  const getUser = () => {
    if (user) {
      return user;
    }
    if (data?.user) {
      return data.user;
    }
    return null;
  };

  const signin = async (email, password, successCallback, errorCallback) => {
    let loginMessage = '';
    // setLoading(true);

    await sessionLoginMutation({
      variables: {
        input: {
          email,
          password,
        },
      },
    }).then((response) => {
      const {
        data: { sessionLogin },
      } = response;

      if (sessionLogin && sessionLogin.user) {
        const { token, user: returnedUser } = sessionLogin;
        const allowedRoles = ['corporate', 'super_admin', 'admin', 'recruiter'];

        if (sessionLogin.success && returnedUser) {
          const config = {}; // { sameSite: 'none', secure: true }

          if (returnedUser.roles.length === 0) {
            loginMessage = 'User has no roles. Contact support.';
          } else if (
            returnedUser.roles.filter((r) => allowedRoles.includes(r.name))
              .length === 0
          ) {
            loginMessage = 'User does not have admin privileges.';
          } else if (
            returnedUser.roles.filter(
              (r) => r.org.status.toLowerCase() === 'active',
            ).length === 0
          ) {
            loginMessage = 'User does not belong to an active organization.';
          } else {
            //Successful Login
            Cookie.set(`una-${global.environment}-token`, token, config);
            Cookie.set(`una-${global.environment}`, returnedUser.id, config);
            Cookie.set('org', returnedUser.roles[0].org.id);
            setUser(returnedUser);
            loginMessage = 'Successful login';
            //const queryRole = returnedUser.roles[0];
            // preload roster

            // client.client.query({
            //   fetchPolicy: 'network-only',
            //   query: OrgQuery,

            //   variables: {
            //     id: queryRole.org.id,
            //     roles: roles(queryRole.name).map((o) => o.value),
            //     search: null,
            //     first: 300,
            //   },
            // });
            //preload users
            // client.client.query({
            //   fetchPolicy: 'network-only',
            //   query: UsersQuery,
            //   variables: { first: 100 },
            // });

            if (successCallback) {
              successCallback(returnedUser);
            }
            return loginMessage;
          }
        } else {
          loginMessage = 'Email or password is incorrect. Try again.';
        }
      } else {
        loginMessage = 'Invalid credentials. Try again.';
      }
      return true;
    });

    if (errorCallback) {
      errorCallback(loginMessage);
    }
    return loginMessage;
  };

  const signout = (successCallback) => {
    setUser(null);
    removeCookies();
    if (successCallback) {
      successCallback();
    }
  };

  const isAuth = () => {
    //if token
    const token = Cookie.get(`una-${global.environment}-token`);
    const idNow = Cookie.get(`una-${global.environment}`);

    if (token && idNow) {
      const decodedToken = jwt.decode(token);
      const expirationDate = moment(new Date(decodedToken.exp * 1000));

      if (moment().isBefore(expirationDate)) {
        if (user) {
          return true;
        } else {
          if (data?.user) {
            value.user = data?.user;
            return true;
          }
          return false;
        }
      } else {
        removeCookies();
        return false;
      }
    } else {
      removeCookies();
      return false;
    }
  };

  value = { user, signin, signout, isAuth, getUser };

  return loading ? (
    <LoadingPane></LoadingPane>
  ) : (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  );
}

function useAuth() {
  return React.useContext(AuthContext);
}

const verifyToken = (token) => tokenList?.includes(token);

function RequireAuth({ children }) {
  const auth = useAuth();
  const location = useLocation();
  const token = qs.parse(location.search, { ignoreQueryPrefix: true })?.token;
  const isVerified = verifyToken(token) && location.pathname.includes('pdf');

  if (!auth.isAuth() && !isVerified) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function Home({ children }) {
  const auth = useAuth();
  const location = useLocation();

  if (auth.user) {
    Cookie.set('org', auth.user.roles[0].org.id);
    const roster = `/organizations/${auth.user.roles[0].org.id}/roster`;
    return <Navigate to={roster} state={{ from: location }} replace />;
  }

  return children;
}

export default App;
