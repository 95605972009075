import { gql } from 'apollo-boost';

const TestQuestionUpdateMutation = gql`
  mutation TestQuestionUpdateMutation($input: TestQuestionUpdateInput) {
    testQuestionUpdate(input: $input) {
      errors {
        code
        message
      }
      result {
        id
        rank
      }
    }
  }
`;

export default TestQuestionUpdateMutation;
