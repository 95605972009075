import React from 'react';

const Checkbox = (props) => {
  const { checked, className, onChange, readOnly } = props;

  return (
    <div className={className ? `${className} checkbox` : 'checkbox'}>
      <input
        type="checkbox"
        checked={checked}
        onChange={() => {
          if (!readOnly) onChange(!checked);
        }}
        readOnly
      />

      <span className="checkmark" />
    </div>
  );
};

export default Checkbox;
