import { gql } from 'apollo-boost';

const DocumentQuery = gql`
  query DocumentQuery($id: ID, $document: ID) {
    certifications {
      id
      credential
      name
    }
    user(id: $id) {
      id
      document(id: $document) {
        id
        documentNumber
        expiresOn
        kind
        imageUrl
        imageBackUrl
        issuerLocation
        issuerName
        issuedOn
        issuingState
        status
        title
        verificationData
      }
      workExperiences {
        id
      }
    }
  }
`;

export default DocumentQuery;
