import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { uniqBy } from 'lodash';
import moment from 'moment';

import LoadingPane from '../Shared/LoadingPane';
import DeletePane from './Panes/Delete';

const DashboardChecklists = (props) => {
  const {
    contentRemove,
    duplicateChecklist,
    goToChecklistRoute,
    state,
    toggleModal,

  } = props;
  const {
    admin,
    brandColor,
    checklists,
    checklist,
    isModal,
    loading,
    secondaryColor,
    orgId,
    checklistType
  } = state;

  const checklistArray = uniqBy(checklists.nodes, 'id');
  console.log("checklistTypechecklistType", orgId)

  return (
    <div className="dashboard-test-view full-screen-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      <div className="stats-frame">
        <div className="centered-frame">
          <div className="stat-frame">
            <div className="header">TOTAL</div>
            <div className="stat-label black">{checklistArray.length}</div>
          </div>

          <div className="stat-frame middle">
            <div className="header">ACTIVE</div>
            <div
              className="stat-label"
              style={{
                color:
                  checklistArray.length > 0
                    ? secondaryColor
                      ? secondaryColor
                      : '#008489'
                    : '#919191',
              }}
            >
              {checklistArray.filter((c) => c.status === 'active').length}
            </div>
          </div>

          <div className="stat-frame">
            <div className="header">INACTIVE</div>
            <div className="stat-label">
              {checklistArray.filter((c) => c.status !== 'active').length}
            </div>
          </div>
        </div>
      </div>

      <div className="filter-frame">
        <div className="centered-frame">
          <button
            className="create-test-button"
            style={{ color: brandColor || '#FF5A5F' }}
            onClick={() => goToChecklistRoute()}
          >
            + Create New Checklist
          </button>
        </div>
      </div>

      <div className="tests-table-view">
        <div className="table-headers">
          <div className="centered-frame">
            <div className="table-header title small">Title</div>
            <div className="table-header list">Type</div>
            <div className="table-header list">Categories</div>
            <div className="table-header list">Sections</div>
            <div className="table-header list">Questions</div>
            <div className="table-header list">Date Modified</div>
            <div className="table-header status">Status</div>
          </div>
        </div>

        {checklistArray.length > 0 ? (
          <ul className="tests-list-view scroll">
            {checklistArray.map((checklist) => {
              const { categories, id, status, title, updatedAt, org, checklistType } =
                checklist;

              console.log("checklist12345", checklist)

              const isOrg = orgId === org?.id;
              const hasPermission = admin || isOrg;

              return (
                <li
                  className={hasPermission ? 'test-cell' : 'test-cell disabled'}
                  key={id}
                >
                  <div className="centered-frame">
                    <div
                      className="cell-content-frame"
                      onClick={() =>
                        hasPermission ? goToChecklistRoute(id) : null
                      }
                    >

                      <div className="cell-label title small">{title}</div>
                      <div className="cell-label list">
                        {checklistType !== null ? checklistType : '-'}
                      </div>
                      {/* <div className='cell-label duration'>{durationMins ? `${durationMins}m` : '-'}</div> */}
                      <div className="cell-label list">{categories.length}</div>
                      <div className="cell-label list">
                        {categories.map((c) => c.sections).flat().length}
                      </div>
                      <div className="cell-label list">
                        {
                          categories
                            .map((c) =>
                              c.sections.map((s) => s.questions).flat(),
                            )
                            .flat().length
                        }
                      </div>
                      <div className="cell-label list">
                        {moment(new Date(updatedAt * 1000)).format(
                          'MMM D, YYYY',
                        )}
                      </div>
                      <div
                        className="cell-label status"
                        style={{
                          color:
                            status === 'active' ? secondaryColor : '#919191',
                        }}
                      >
                        {status}
                      </div>
                    </div>

                    <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      rootClose
                      overlay={
                        <Popover id="menu-pane" className="menu-pane">
                          <div
                            className="menu-content-view"
                            style={{ padding: '5px 0px' }}
                          >
                            <button
                              className="menu-button teal"
                              onClick={() => goToChecklistRoute(id)}
                            >
                              View Details
                            </button>
                            <button
                              className="menu-button teal"
                              onClick={() => duplicateChecklist(checklist)}
                            >
                              Duplicate
                            </button>
                            <button
                              className="menu-button red"
                              onClick={() => toggleModal(checklist, true)}
                            >
                              Delete
                            </button>
                          </div>
                        </Popover>
                      }
                    >
                      <div className={hasPermission ? 'expander-icon' : 'hide'}>
                        <img
                          src={require('../../theme/images/expander-icon-grey-2x.png')}
                          alt="expander-icon"
                        />
                      </div>
                    </OverlayTrigger>
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <div className="layout-center hint empty-label">
            There are no checklists to display.
          </div>
        )}
      </div>

      <DeletePane
        message={`Are you sure you want to remove "${checklist.title}" from your organization?`}
        onHide={() => toggleModal({}, false)}
        save={contentRemove}
        show={isModal}
      />
    </div>
  );
};

export default DashboardChecklists;
