import React, { useState, useEffect } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';

import LoadingPane from '../Shared/LoadingPane';

const DocumentDisplay = (props) => {
  const { docUrl, title = 'file', link } = props;
  const [numberPages, setNumberPages] = useState(null);
  const [loading, setLoading] = useState(true);
  const [base64, setBase64] = useState('');

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  });

  useEffect(
    function eff() {
      const fetchBase64 = async () => {
        const response = await fetch(docUrl);
        const blob = await response.blob();
        const reader = new FileReader();
        await new Promise((resolve, reject) => {
          reader.onload = resolve;
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
        setBase64(reader.result);
        setLoading(false);
      };

      fetchBase64();
    },
    [docUrl, setLoading, setBase64],
  );

  const Display = ({ url, altText }) => {
    if (url) {
      const urlSplit = url.split('.');
      const ext = urlSplit[urlSplit.length - 1];

      if (ext === 'pdf') {
        return (
          <Document
            file={url}
            onLoadSuccess={({ numPages }) => setNumberPages(numPages)}
          >
            {Array.apply(null, Array(numberPages))
              .map((x, i) => i + 1)
              .map((page) => {
                return <Page key={`page${page}`} pageNumber={page} />;
              })}
          </Document>
        );
      }
      return <img src={url} alt={altText} />;
    } else {
      return 'Unable to load Document';
    }
  };

  if (loading) {
    return <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />;
  }

  if (link) {
    return (
      <a
        className={docUrl ? 'document-image' : 'hidden'}
        href={docUrl}
        rel="noopener noreferrer"
        target="_blank"
        download={title}
      >
        <Display url={docUrl} altText={title} />
      </a>
    );
  }
  return <Display url={docUrl} altText={title} />;
};

export default DocumentDisplay;
