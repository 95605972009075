import { gql } from 'apollo-boost';

const OrgChecklistsQuery = gql`
  query OrgChecklistsQuery($id: ID!, $first: Int, $after: String) {
    org(id: $id) {
      id
      brandColor
      checklists(
        first: $first
        after: $after
        status: ["active", "inactive"]
        order: "title"
      ) {
        nodes {
          id
          categories {
            id
            scoreLabels
            scoreValues
            sections {
              id
              questions {
                id
                rank
                scoreLabels
                scoreValues
                title
              }
              rank
              title
            }
            title
          }
          org {
            id
          }
          color
          durationMins
          kind
          status
          title
          updatedAt
          checklistType
        }
      }
      secondaryColor
    }
  }
`;

export default OrgChecklistsQuery;
