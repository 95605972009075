import { gql } from 'apollo-boost';

const CourseQuery = gql`
  query CourseQuery($id: ID) {
    course(id: $id) {
      id
      artworkUrl
      artworkThumbnailUrl
      chapters {
        id
        questions {
          id
          answers
          isRandomized
          rank
          title
        }
        title
      }
      desc
      durationMins
      kind
      passingScore
      status
      title
      updatedAt
      courseType
    }
  }
`;

export default CourseQuery;
