import { gql } from 'apollo-boost';

const ChapterQuery = gql`
  query ChapterQuery($id: ID) {
    course(id: $id) {
      id
      chapters {
        id
        content
        questions {
          id
          answers
          isRandomized
          rank
          title
        }
        title
      }
      title
    }
  }
`;

export default ChapterQuery;
