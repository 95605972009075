import { gql } from 'apollo-boost';

const ManagerFeedbackQuery = gql`
query ManagerFeedbackQuery($id: ID!) {
  managerFeedback(id: $id) {
    id
    feedbackResponse
    
  }
}`;

export default ManagerFeedbackQuery;
