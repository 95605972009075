import { gql } from 'apollo-boost';

const JobPostChangeVerify = gql`
mutation JobPostChangeVerify($input: JobPostChangeVerifyInput){
    jobPostChangeVerify(input: $input){
      success
      result {
        status
        changesRequested
        requestedBy
        jobPost {
          designation
          description
          positionsAvailable
          qualification
          endDate
        }
      }
      errors{
        code
        message
        backtrace
      }
    }
  }
`;

export default JobPostChangeVerify;
