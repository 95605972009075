import { gql } from 'apollo-boost';

const CourseActivateMutation = gql`
  mutation CourseActivateMutation($input: IDInput) {
    courseActivate(input: $input) {
      errors {
        message
      }
      result {
        id
        courseType
      }
    }
  }
`;

export default CourseActivateMutation;
