import { gql } from 'apollo-boost';

const OrgUsersQuery = gql`
  query OrgUsersQuery(
    $id: ID!
    $roles: [String!]
    $first: Int
    $after: String
    $search: String
  ) {
    org(id: $id) {
      id
      attachment(label: "pdf_logo") {
        id
        url
      }
      brandColor
      secondaryColor
      title
      users(
        first: $first
        after: $after
        order: "first_name"
        roles: $roles
        search: $search
      ) {
        edges {
          node {
            id
            attachment(label: "avatar") {
              id
              label
              url
            }
            checklistsTaken {
              id
              orgId
              status
            }
            coursesTaken {
              id
              orgId
              status
            }
            email
            firstName
            lastName
            roles {
              id
              name
              org {
                id
                title
                status
              }
            }
            testsTaken {
              id
              orgId
              status
            }
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`;

export default OrgUsersQuery;
