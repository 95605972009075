import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const PopoverPane = (props) => {
  const { className, children, node } = props;

  return (
    <div className="popover-pane">
      <div className="background"></div>

      <OverlayTrigger
        trigger="click"
        rootClose
        placement="bottom"
        overlay={
          <Popover className={className} id="popover-pane">
            <div className="content-view" onClick={() => document.body.click()}>
              {children}
            </div>
          </Popover>
        }
      >
        {node}
      </OverlayTrigger>
    </div>
  );
};

export default PopoverPane;
