import React from 'react';
import { graphql } from 'react-apollo';
import _, { flowRight as compose, set } from 'lodash';

import moment from 'moment';
import Cookie from 'js-cookie';

import UserQuery from '../../../graphql/queries/Document';
import DocumentCreateMutation from '../../../graphql/mutations/Document/Create';
import DocumentRemoveMutation from '../../../graphql/mutations/Document/Remove';
import DocumentRenewMutation from '../../../graphql/mutations/Document/Renew';
import DocumentUpdateMutation from '../../../graphql/mutations/Document/Update';

import Certification from '../../../components/Documents/Certification';

import LoadingPane from '../../../components/Shared/LoadingPane';

import STRINGS from '../../../localization';

class CertificationDocumentContainer extends React.Component {
  constructor(props) {
    super(props);

    const {
      params: { document },

      mobile,
    } = props;

    this.state = {
      certifications: [],
      current: false,
      edit: document === 'create',
      kind: 'certification',
      isModal: false,
      isModal2: false,
      loading: false,
      mobile,
      onload: true,
      user: props.context.user,
    };
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(prevProps) {
    const { mobile } = this.props;

    this.setup(prevProps.mobile !== mobile);
  }

  setup = (mobileDidChange) => {
    const {
      mobile,
      userQuery: { loading, certifications, user },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      const document = user.document;

      this.setState({
        ...document,
        certifications: _.uniqBy(certifications, 'name'),
        current: !(document && document.expiresOn),
        mobile: mobileDidChange ? mobile : this.state.mobile,
        onload: false,
      });
    } else if (mobileDidChange) {
      this.handleChange('mobile', mobile);
    }
  };

  back = () => {
    const { params, navigate, location } = this.props;

    if (!location.state || location.state?.previous === '/login') {
      navigate(`/organizations/${params.id}/roster/${params.roster}`);
      return;
    }
    navigate(-1);
  };

  documentRemove = (hide) => {
    const { documentRemoveMutation } = this.props;

    this.setState(
      {
        loading: true,
      },
      () => {
        documentRemoveMutation({
          variables: {
            input: {
              id: this.state.id,
            },
          },
        }).then((response) => {
          const { errors, success } = response.data.documentRemove;

          if (success) {
            setTimeout(() => {
              hide();

              setTimeout(() => this.back(), 450);
            }, 200);
          } else {
            this.handleChange('loading', false);

            window.alert(errors[0].message);
          }
        });
      },
    );
  };

  documentRenew = (hide) => {
    const { documentRenewMutation } = this.props;
    const { id, renewExpiresOn, renewFile, renewIssuedOn } = this.state;

    if (moment(renewIssuedOn).isAfter(moment(renewExpiresOn))) {
      window.alert('Expiration date cannot occur before the issued date.');
    } else if (renewExpiresOn && renewIssuedOn) {
      this.setState(
        {
          loading: true,
        },
        async () => {
          const data = {
            id,
            expiresOn: renewExpiresOn,
            issuedOn: renewIssuedOn,
          };

          if (renewFile) data.image = await this.saveAttachment(renewFile);

          documentRenewMutation({
            variables: {
              input: data,
            },
          }).then((response) => {
            const { errors, success } = response.data.documentRenew;

            if (success) {
              hide();

              setTimeout(() => this.back(), 450);
            } else {
              this.handleChange('loading', false);

              window.alert(errors[0].message);
            }
          });
        },
      );
    }
  };

  handleChange = (key, value) => {
    this.setState(set(this.state, key, value));
  };

  resetDocumentRenewal = () => {
    this.setState({
      isModal2: false,
      renewExpiresOn: null,
      renewFile: null,
      renewIssuedOn: null,
    });
  };

  save = () => {
    const { documentCreateMutation, documentUpdateMutation } = this.props;
    const { documentNumber, expiresOn, file, kind, id, issuedOn, title } =
      this.state;

    if (moment(issuedOn).isAfter(moment(expiresOn))) {
      window.alert('Expiration date cannot occur before the issued date.');
    } else if (title && issuedOn && expiresOn) {
      this.setState(
        {
          loading: true,
        },
        async () => {
          const mutation = id ? documentUpdateMutation : documentCreateMutation;
          const data = {
            documentNumber: documentNumber || STRINGS.notProvided,
            kind,
            expiresOn,
            issuedOn,
            issuingState: 'n/a',
            title,
          };

          if (file) data.image = await this.saveAttachment(file);

          // document exists
          if (id) {
            data.id = id;
          } else {
            data.userId = Cookie.get(process.env.REACT_APP_COOKIE_NAME);
          }

          mutation({
            variables: {
              input: data,
            },
          }).then((response) => {
            const key = Object.keys(response.data)[0];
            const { errors, success } = response.data[key];

            if (success) {
              this.back();
            } else {
              this.handleChange('loading', false);

              window.alert(errors[0].message);
            }
          });
        },
      );
    }
  };

  saveAttachment = (file) => {
    return new Promise((success) => {
      const xhr = new XMLHttpRequest();
      const fileName = file.name;
      const config = {
        type: `image/${fileName.split('.')[1]}`,
      };
      const f = new File([file.base64], fileName, config);
      const formData = new FormData();

      formData.append('file', f);

      xhr.open('POST', process.env.REACT_APP_ATTACHMENT_URL);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          success(JSON.parse(xhr.response));
        }
      };

      xhr.send(formData);
    });
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <Certification
        {...this.props}
        back={this.back}
        documentRemove={this.documentRemove}
        documentRenew={this.documentRenew}
        handleChange={this.handleChange}
        resetDocumentRenewal={this.resetDocumentRenewal}
        save={this.save}
        state={this.state}
      />
    );
  }
}

export default compose(
  graphql(UserQuery, {
    name: 'userQuery',
    options: ({ params: { roster, item } }) => ({
      variables: {
        id: roster,
        document: item,
      },
    }),
  }),
  graphql(DocumentCreateMutation, { name: 'documentCreateMutation' }),
  graphql(DocumentRemoveMutation, { name: 'documentRemoveMutation' }),
  graphql(DocumentRenewMutation, { name: 'documentRenewMutation' }),
  graphql(DocumentUpdateMutation, { name: 'documentUpdateMutation' }),
)(CertificationDocumentContainer);
