import React, { useState, useEffect } from "react";

function AlphabetPagination({ id, onPageChange, currentPage }) {
  const [startIndex, setStartIndex] = useState(0); // Track the start index for visible alphabets
  const alphabets = "ABCDEF".split("");

  useEffect(() => {
    setStartIndex(0); // Reset start index when currentPage changes
  }, [currentPage]);

  const goToPage = (page, id) => {
    onPageChange(page, id);
  };

  const handlePrevClick = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (startIndex + 3 < alphabets.length) {
      setStartIndex(startIndex + 1);
    }
  };

  return (
    <div
      style={{
        marginTop: "-8px",
        display: "flex",
      }}
    >
      {startIndex > 0 && (
        <button
          onClick={handlePrevClick}
          style={{
            marginRight: "1rem",
            cursor: "pointer",
            border: "none",
            background: "none",
            fontSize: "1.5rem", // Example font size
            color: "grey", // Example color
          }}
        >
          &lt; {/* Left arrow symbol */}
        </button>
      )}
      {alphabets.slice(startIndex, startIndex + 3).map((alphabet) => (
        <button
          key={alphabet}
          onClick={() => goToPage(alphabet, id)}
          style={{
            margin: "0.5rem",
            padding: "0.25rem 0.5rem",
            borderRadius: "15px",
            background:
              currentPage === alphabet ? "#f0f0f0" : "transparent",
            color: currentPage === alphabet ? "#000000" : "#a9a9a9",
            border: currentPage === alphabet ? "1px solid #a9a9a9" : "none",
            cursor: "pointer",
          }}
        >
          {alphabet}
        </button>
      ))}
      {startIndex + 3 < alphabets.length && (
        <button
          onClick={handleNextClick}
          style={{
            marginLeft: "1rem",
            cursor: "pointer",
            border: "none",
            background: "none",
            fontSize: "1.5rem", // Example font size
            color: "grey", // Example color
          }}
        >
          &gt; {/* Right arrow symbol */}
        </button>
      )}
    </div>
  );
}

function ExampleApp({ id, onPageChange }) {
  const [currentPage, setCurrentPage] = useState('');

  useEffect(() => {
    // Retrieve the current page from local storage when the component mounts
    const storedPage = localStorage.getItem(`currentPage_${id}`);
    if (storedPage) {
      setCurrentPage(storedPage);
    }
  }, [id]);

  const handlePageChange = (page, id) => {
    setCurrentPage(page); // Update currentPage state when onPageChange is called
    onPageChange(page, id);
    // Store the current page in local storage
    // localStorage.setItem(`currentPage_${id}`, page);
  };

  return (
    <div>
      <AlphabetPagination
        onPageChange={handlePageChange}
        currentPage={currentPage}
        id={id}
      />
    </div>
  );
}

export default ExampleApp;
