import React from 'react';
import CSVReader from 'react-csv-reader';

import _ from 'lodash';

import Checkbox from '../Shared/Checkbox';
import LoadingPane from '../Shared/LoadingPane';

import { roleNames } from '../../utils/Constants';

const headerHeight = 53;

const RosterUpload = (props) => {
  const {
    goToRosterRoute,
    handleChange,
    handleCSVUpload,
    handleSelection,
    handleSelectAll,
    refs,
    save,
    state,
  } = props;
  const {
    expandA,
    expandB,
    expandC,
    expandD,
    file,
    invalid,
    loading,
    org,
    valid,
  } = state;
  const { bundles, checklists, courses, tests } = org;

  const total =
    tests.nodes.length + checklists.nodes.length + courses.nodes.length;

  return (
    <div className="roster-upload-view full-screen-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      <div className="toolbar">
        <button className="cancel-button" onClick={goToRosterRoute}>
          Cancel
        </button>

        <label className="page-header bold">Bulk CSV Upload</label>

        <button
          className={
            invalid.length === 0 && valid.length > 0
              ? 'save-button'
              : 'save-button disabled'
          }
          onClick={save}
        >
          Upload
        </button>
      </div>

      <div className="content-frame scroll">
        <div className="document-frame">
          <div className="csv-icon">
            <img
              src={require('../../theme/images/csv-icon-2x.png')}
              alt="csv-icon"
            />
          </div>

          <div className="csv-header">Add new members with a bulk upload.</div>

          <div className="template-frame">
            <div className="template-label">
              Upload your csv file below using this
            </div>

            <a
              className="template-link teal"
              href={require('../../theme/files/una_csv_template.csv')}
              rel="noopener noreferrer"
              target="_blank"
            >
              formatted csv file.
            </a>
          </div>

          <div className="file-input-frame">
            <CSVReader
              cssClass="file-input"
              onFileLoaded={handleCSVUpload}
              parserOptions={{
                header: true,
                skipEmptyLines: true,
                // transformHeader: header => header.toLowerCase().replace(/ /g, '_')
              }}
            />

            {file ? (
              <div className="file-label">{file.name}</div>
            ) : (
              <div className="file-label hint">No file selected</div>
            )}

            <button className="choose-file-button">Choose File</button>
          </div>

          <div className="hint-label">
            Select items you would like to send to members with a role of
            "healthcare professionals."
          </div>

          {total > 0 ? (
            <div className="centered-frame">
              <div
                className={bundles.length > 0 ? 'section-frame' : 'hide'}
                style={{
                  height: expandD
                    ? headerHeight +
                      7.5 +
                      refs.expandD.getBoundingClientRect().height
                    : headerHeight,
                }}
              >
                <div className="section-header-frame">
                  <div className="section-header">{`Bundles (${bundles.length})`}</div>

                  <div className="flex-frame">
                    <button
                      className="resize-button bold red"
                      onClick={(e) => handleChange('expandD', !expandD)}
                    >
                      {expandD ? 'Collapse' : 'Expand'}
                    </button>
                  </div>
                </div>

                <ul
                  className="section-list-view"
                  ref={(ref) => (refs.expandD = ref)}
                >
                  {bundles.map((o) => {
                    const { id, title } = o;

                    return (
                      <li
                        className="section-cell"
                        key={id}
                        onClick={(e) => handleSelection(e, 'selectedD', id, o)}
                      >
                        <Checkbox
                          checked={state.selectedD.includes(id)}
                          readOnly={true}
                        />

                        <div className="section-label">{title}</div>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div
                className={tests.nodes.length > 0 ? 'section-frame' : 'hide'}
                style={{
                  height: expandA
                    ? headerHeight +
                      7.5 +
                      refs.expandA.getBoundingClientRect().height
                    : headerHeight,
                }}
              >
                <div className="section-header-frame">
                  <div className="section-header">{`Tests (${tests.nodes.length})`}</div>

                  <div className="flex-frame">
                    <button
                      className="select-all-button teal"
                      onClick={() => handleSelectAll(tests.nodes, 'selectedA')}
                    >
                      {`${
                        tests.nodes.length === state.selectedA.length
                          ? 'Deselect'
                          : 'Select'
                      } All`}
                    </button>

                    <button
                      className="resize-button bold red"
                      onClick={(e) => handleChange('expandA', !expandA)}
                    >
                      {expandA ? 'Collapse' : 'Expand'}
                    </button>
                  </div>
                </div>

                <ul
                  className="section-list-view"
                  ref={(ref) => (refs.expandA = ref)}
                >
                  {tests.nodes.map((o) => {
                    const { id, kind, title } = o;
                    const isGlobal = kind ? kind.toLowerCase() === 'global' : false;

                    return (
                      <li
                        className="section-cell"
                        key={id}
                        onClick={(e) => handleSelection(e, 'selectedA', id)}
                      >
                        <Checkbox
                          checked={state.selectedA.includes(id)}
                          readOnly={true}
                        />

                        <div className="section-label">{title}</div>

                        <div
                          className={
                            isGlobal ? 'section-label red' : 'hide'
                          }
                        >
                          (UNA)
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div
                className={
                  checklists.nodes.length > 0 ? 'section-frame' : 'hide'
                }
                style={{
                  height: expandB
                    ? headerHeight +
                      7.5 +
                      refs.expandB.getBoundingClientRect().height
                    : headerHeight,
                }}
              >
                <div className="section-header-frame">
                  <div className="section-header">{`Skills Checklists (${checklists.nodes.length})`}</div>

                  <div className="flex-frame">
                    <button
                      className="select-all-button teal"
                      onClick={() =>
                        handleSelectAll(checklists.nodes, 'selectedB')
                      }
                    >
                      {`${
                        checklists.nodes.length === state.selectedB.length
                          ? 'Deselect'
                          : 'Select'
                      } All`}
                    </button>

                    <button
                      className="resize-button bold red"
                      onClick={(e) => handleChange('expandB', !expandB)}
                    >
                      {expandB ? 'Collapse' : 'Expand'}
                    </button>
                  </div>
                </div>

                <ul
                  className="section-list-view"
                  ref={(ref) => (refs.expandB = ref)}
                >
                  {checklists.nodes.map((o) => {
                    const { id, kind, title } = o;
                    const isGlobal = kind ? kind.toLowerCase() === 'global' : false;

                    return (
                      <li
                        className="section-cell"
                        key={id}
                        onClick={(e) => handleSelection(e, 'selectedB', id)}
                      >
                        <Checkbox
                          checked={state.selectedB.includes(id)}
                          readOnly={true}
                        />

                        <div className="section-label">{title}</div>

                        <div
                          className={
                            isGlobal ? 'section-label red' : 'hide'
                          }
                        >
                          (UNA)
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div
                className={courses.nodes.length > 0 ? 'section-frame' : 'hide'}
                style={{
                  height: expandC
                    ? headerHeight +
                      7.5 +
                      refs.expandC.getBoundingClientRect().height
                    : headerHeight,
                }}
              >
                <div className="section-header-frame">
                  <div className="section-header">{`Mandatories (${courses.nodes.length})`}</div>

                  <div className="flex-frame">
                    <button
                      className="select-all-button teal"
                      onClick={() =>
                        handleSelectAll(courses.nodes, 'selectedC')
                      }
                    >
                      {`${
                        courses.nodes.length === state.selectedC.length
                          ? 'Deselect'
                          : 'Select'
                      } All`}
                    </button>

                    <button
                      className="resize-button bold red"
                      onClick={(e) => handleChange('expandC', !expandC)}
                    >
                      {expandC ? 'Collapse' : 'Expand'}
                    </button>
                  </div>
                </div>

                <ul
                  className="section-list-view"
                  ref={(ref) => (refs.expandC = ref)}
                >
                  {courses.nodes.map((o) => {
                    const { id, kind, title } = o;
                    const isGlobal = kind ? kind.toLowerCase() === 'global' : false;

                    return (
                      <li
                        className="section-cell"
                        key={id}
                        onClick={(e) => handleSelection(e, 'selectedC', id)}
                      >
                        <Checkbox
                          checked={state.selectedC.includes(id)}
                          readOnly={true}
                        />

                        <div className="section-label">{title}</div>

                        <div
                          className={
                            isGlobal ? 'section-label red' : 'hide'
                          }
                        >
                          (UNA)
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          ) : (
            <div className="layout-center">
              <div className="empty-label hint">
                This organization does not have any content to display.
              </div>
            </div>
          )}

          <div
            className={file && invalid.length > 0 ? 'entries-frame' : 'hide'}
          >
            <div className="message red">{`File contains ${invalid.length} invalid entries. All entries must be valid.`}</div>

            <div className="csv-table-view">
              <div className="table-headers">
                <div className="table-header first-name">First Name</div>
                <div className="table-header last-name">Last Name</div>
                <div className="table-header email">Email</div>
                <div className="table-header role">Role</div>
              </div>

              <ul className="csv-list-view">
                {_.orderBy(invalid, ['firstName', 'lastName']).map((obj, i) => {
                  const { email, firstName, isEmail, isRole, lastName, role } =
                    obj;

                  return (
                    <li className="csv-cell" key={i}>
                      <div
                        className={`cell-label first-name ${
                          firstName ? '' : 'red'
                        }`}
                      >
                        {firstName || 'Missing Field'}
                      </div>
                      <div
                        className={`cell-label last-name ${
                          lastName ? '' : 'red'
                        }`}
                      >
                        {lastName || 'Missing Field'}
                      </div>
                      <div
                        className={`cell-label email ${isEmail ? '' : 'red'}`}
                      >
                        {email || 'Missing Field'}
                      </div>
                      <div className={`cell-label role ${isRole ? '' : 'red'}`}>
                        {roleNames[role]}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RosterUpload;
