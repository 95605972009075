import React, { useState } from 'react';
import Benefits from './Benefits';
import PriceCard from './PriceCard';
import './Subscribe.scss';
import Toolbar from '../Shared/Toolbar';
import SubscriptionsList from '../../graphql/queries/Subscriptions';
import { graphql, useQuery } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import PaymentIntentCreateMutation from '../../graphql/mutations/Payments/CreateIntent';
import RosterInviteQuery from '../../graphql/queries/Roster/Invite';
import UsersByUlid from '../../graphql/queries/Users/UsersByUlid';

const SubscribePage = (props) => {
  const [Alert, setAlert] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const { id, roster } = useParams();
  const { pathname } = useLocation();
  const multipleRosterPathPattern = /^\/organizations\/\d+\/rosters\/subscribe/;
  const isMultipleRosterPathMatched = multipleRosterPathPattern.test(pathname);

  const { data: rosterInviteData } = useQuery(RosterInviteQuery, {
    variables: { id: id, user: roster },
  });

  const { data: selectedUsersData } = useQuery(UsersByUlid, {
    variables: { ulid: JSON.parse(localStorage.getItem('selectedUsers')) },
  });

  const { data: subscriptionsData } = useQuery(SubscriptionsList, {
    variables: { status: ['active'] },
  });

  const activeCredit = (rosterInviteData?.user?.userSubscription || [])
    .filter((subscription) => subscription.status === 'active')
    .reduce(
      (total, subscription) => total + parseInt(subscription.availedCredit),
      0,
    );

  const onCloseAlert = () => {
    setAlert('');
  };

  let subscription = null;
  let subscriptions = null;
  if (
    subscriptionsData &&
    subscriptionsData.subscriptions &&
    subscriptionsData.subscriptions.edges.length > 0
  ) {
    subscription = subscriptionsData.subscriptions.edges[0].node;
    subscriptions = subscriptionsData.subscriptions.edges.filter(
      (sub, index) => {
        return sub.node.id != subscription.id;
      },
    );
  }

  const navigate = useNavigate();

  const handlePaymentIntent = (subs_id) => {
    let { paymentIntentCreateMutation } = props;

    let subscriptionsData = {
      productType: 'subscription',
      productId: subs_id,
      userId: roster,
      ulid: JSON.parse(localStorage.getItem('selectedUsers')),
      source: 'admin',
    };

    paymentIntentCreateMutation({
      variables: {
        input: subscriptionsData,
      },
    }).then((res) => {
      const key = Object.keys(res.data)[0];
      const { errors, success, result } = res.data[key];

      if (success) {
        navigate(
          `/subscribe/${id}/${roster}/checkout?payment_intent=${result.orderId}`,
        );
      } else {
        window.alert(errors[0].message);
      }
    });
  };
  const handleBack = () => {
    localStorage.clear('selectedUsers');
    {
      isMultipleRosterPathMatched
        ? navigate(`/organizations/${id}/rosters`)
        : navigate(`/organizations/${id}/roster/${roster}/invite`);
    }
  };

  return (
    <div className="dashboard-feedback-view">
      {/* {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null} */}
      {Alert && (
        <div className="modaloverlay">
          <div className="modal" style={{ width: '20%' }}>
            <h2>Alert</h2>
            <div
              className="model-body"
              dangerouslySetInnerHTML={{ __html: Alert }}
              style={{ display: 'inline' }}
            >
              {/* {Alert} */}
            </div>
            <div>
              <button
                type="submit"
                style={{ width: '20%' }}
                className="model-submit-button"
                onClick={onCloseAlert}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}

      {isMultipleRosterPathMatched ? (
        <Toolbar
          cancel={handleBack}
          title={'Purchase Page'}
        // activeCredit={activeCredit}
        />
      ) : (
        <Toolbar cancel={handleBack} title={'Purchase Page'} />
      )}

      <div className={`content-frame scroll`}>
        <div className="BlockCenter">
          <div className="pricePage">
            <Benefits />
            <div
              className="desc-content"
              style={{ height: '550px', overflowY: 'auto' }}
            >
              {subscription && (
                <>
                  <PriceCard
                    className="card0"
                    bestValue="Best Value"
                    month={subscription.title}
                    discountValue="100$"
                    monthValue={subscription.amount}
                    creditEarn={subscription.creditEarn}
                    ValueMonth={(subscription.amount / subscription.validity).toFixed(2)}
                    permonth={subscription.validity}
                    subscription={subscription}
                    onClick={() => handlePaymentIntent(subscription.id)}
                  />
                </>
              )}
              {subscriptions &&
                subscriptions.map(({ node }, index) => (
                  <PriceCard
                    className={`card${index + 1}`}
                    ValueStyle="NoValue"
                    month={node.title}
                    monthValue={node.amount}
                    creditEarn={node.creditEarn}
                    ValueMonth={(node.amount / node.validity).toFixed(2)}
                    permonth={node.validity}
                    subscription={node}
                    onClick={() => handlePaymentIntent(node.id)}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(
  graphql(PaymentIntentCreateMutation, { name: 'paymentIntentCreateMutation' }),
)(SubscribePage);
