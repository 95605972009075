import React, { useEffect, useState } from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import LoadingPane from '../Shared/LoadingPane';
import { withRouter } from '../../containers/withRouter';
import FeedbackRequestQuery from '../../graphql/queries/FeedbackRequest/FeedbackWithId';
import Toolbar from '../Shared/Toolbar';
import { useParams } from 'react-router-dom';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { faC } from '@fortawesome/free-solid-svg-icons';

const FeedbackDetails = (props) => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [Alert, setAlert] = useState('');
  const [record, setRecord] = useState();

  const onCloseAlert = () => {
    setAlert('');
  };

  useEffect(() => {
    setLoading(true);
    const {
      FeedbackRequestQuery: { refetch },
    } = props;
    refetch().then((res) => {
      const factorsdata2 = res.data.feedbackRequest;
      if (factorsdata2) {
        setRecord(factorsdata2);
        setLoading(false);
      }
    });
  }, []);

  const onBack = () => {
    const { navigate } = props;
    navigate(`/viewfeedbacks`, {
      state: {
        org: state.org,
      },
    });
  };

  const goToTestRoute = (id) => {
    const { location, navigate } = props;
    navigate(`/viewfeedback/${id}/response`, {
      state: {
        previous: location.pathname,
        org: state.org,
      },
    });
  };

  const loadTablebody = (row) => {
    const array = row.childlist;
    return (
      <>
        <li className={'test-cell'} key={row.id} style={{ marginBottom: 3 }}>
          <div className="centered-frame" >
            <div className="cell-content-frame">
              <div
                className="cell-label title extralong"
                onClick={(e) => goToTestRoute(row.id)}
              >
                {row.fname} {row.lname}
              </div>
              <div
                className="cell-label list extralong"
                onClick={(e) => goToTestRoute(row.id)}
              >
                {row.email ? row.email : '--'}
              </div>
              <div
                className="cell-label list extralong"
                onClick={(e) => goToTestRoute(row.id)}
              >
                {row.company ? row.company : '--'}
              </div>
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                rootClose
                overlay={
                  <Popover id="menu-pane" className="menu-pane">
                    <div
                      className="menu-content-view"
                      style={{ padding: '5px 0px' }}
                    >
                      <button
                        className="menu-button teal"
                        onClick={(e) => goToTestRoute(row.id)}
                      >
                        View Feedback
                      </button>
                    </div>
                  </Popover>
                }
              >
                <div className={'expander-icon'}>
                  <img
                    src={require('../../theme/images/expander-icon-grey-2x.png')}
                    alt="expander-icon"
                  />
                </div>
              </OverlayTrigger>
            </div>
          </div>
        </li>
        {array &&
          array.map((person, index) => {
            return (
              <li
                className={'test-cell'}
                style={{ marginBottom: 3 }}
                key={person.id}
              >
                <div className="centered-frame" key={index}>
                  <div className="cell-content-frame">
                    <div
                      className="cell-label title extralong"
                      onClick={(e) => goToTestRoute(person.id)}
                    >
                      {person.fname ? `${person?.fname} ${person?.lname}` : '--'}
                    </div>
                    <div
                      className="cell-label list extralong"
                      onClick={(e) => goToTestRoute(person.id)}
                    >
                      {person.email ? person.email : '--'}
                    </div>
                    <div
                      className="cell-label list extralong"
                      onClick={(e) => goToTestRoute(person.id)}
                    >
                      {person.company ? person.company : '--'}
                    </div>
                    <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      rootClose
                      overlay={
                        <Popover id="menu-pane" className="menu-pane">
                          <div
                            className="menu-content-view"
                            style={{ padding: '5px 0px' }}
                          >
                            <button
                              className="menu-button teal"
                              onClick={(e) => goToTestRoute(person.id)}
                            >
                              View Feedback
                            </button>
                          </div>
                        </Popover>
                      }
                    >
                      <div className={'expander-icon'}>
                        <img
                          src={require('../../theme/images/expander-icon-grey-2x.png')}
                          alt="expander-icon"
                        />
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
              </li>
            );
          })}
      </>
    );
  };

  const loadTableHeader = (
    <div className="table-headers">
      <div className="centered-frame">
        <div className="table-header list extralong">{'Full Name'}</div>
        <div className="table-header list extralong">{'Email'}</div>
        <div className="table-header list extralong">{'Company'}</div>
      </div>
    </div>
  );

  return (
    <div className="dashboard-feedback-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      {Alert && (
        <div className="modaloverlay">
          <div className="modal" style={{ width: '20%' }}>
            <h2>Alert</h2>
            <div
              className="model-body"
              dangerouslySetInnerHTML={{ __html: Alert }}
              style={{ display: 'inline' }}
            >
              {/* {Alert} */}
            </div>
            <div>
              <button
                type="submit"
                style={{ width: '20%' }}
                className="model-submit-button"
                onClick={onCloseAlert}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}

      <Toolbar cancel={onBack} title={'Feedback Details'} />

      <div style={{ padding: 10, marginTop: 80 }}>
        <div>
          <div className="feedback-table-viewthree" style={{ height: 450 }}>
            <>{loadTableHeader}</>
            <ul className="tests-list-view scroll">
              {
                record && loadTablebody(record)
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default compose(
  withRouter,
  graphql(FeedbackRequestQuery, {
    name: 'FeedbackRequestQuery',
    options: (props) => ({
      variables: {
        id: props.params.id,
      },
    }),
  }),
)(FeedbackDetails);
