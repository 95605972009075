import gql from 'graphql-tag';

// import TEST_SUMMARY_FRAGMENT from '../../../fragments/Test/Summary';

const OptionalFactoryQuery = gql`
  query OptionalFactoryQuery($id: ID!) {
    org(id: $id) {
      id
      brandColor
      status
      secondaryColor
       optionalFactors(order: "id") {
        nodes {
          id
          title
          description
          otype
          org {
            id
          }
        }
      }
    }
  }

`;

export default OptionalFactoryQuery;
