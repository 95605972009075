import { gql } from 'apollo-boost';

const SubscriptionUpdateMutation = gql`
mutation SubscriptiponUpdate($input: SubscriptionUpdateInput){
    subscriptionUpdate(input:$input){
      success
      result{
        id
        title
      }
      errors{
        code
        message
        backtrace
      }
    }
  }
`;

export default SubscriptionUpdateMutation;
