import { gql } from 'apollo-boost';

const CourseChapterUpdateMutation = gql`
  mutation CourseChapterUpdateMutation($input: CourseChapterUpdateUpdateInput) {
    courseChapterUpdate(input: $input) {
      errors {
        message
      }
      result {
        id
        questions {
          id
          answers
          isRandomized
          rank
        }
        title
      
      }
    }
  }
`;

export default CourseChapterUpdateMutation;
