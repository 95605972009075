import { gql } from 'apollo-boost';

const ChecklistSummaryUpdateMutation = gql`
  mutation ChecklistSummaryUpdateMutation(
    $input: ChecklistSummaryUpdateUpdateInput
  ) {
    checklistSummaryUpdate(input: $input) {
      errors {
        message
      }
      result {
        id
        categories {
          id
          sections {
            id
            questions {
              id
              rank
              title
            }
            rank
            title
          }
        }
        durationMins
        status
        title
        updatedAt
      }
    }
  }
`;

export default ChecklistSummaryUpdateMutation;
