import { gql } from 'apollo-boost';

const UserFindOrCreateMutation = gql`
  mutation UserFindOrCreateMutation($input: UserFindOrCreateInput) {
    userFindOrCreate(input: $input) {
      errors {
        code
        message
      }
      result {
        id
        firstName
        fullName
        lastName
        roles {
          id
          name
          org {
            id
          }
        }
      }
      success
    }
  }
`;

export default UserFindOrCreateMutation;
