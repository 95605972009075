import { gql } from 'apollo-boost';

const JobCandidatesQuery = gql`
query JobPostCandidateApplications($id: ID) {
  jobPost(id: $id){
    testCounts
    skillChecklistCounts
    courseCounts
    jobPostApplicants{
      id
      fullName
      email
      candidateJobAnalysis(jobId: $id)
    }
  }
}
`;

export default JobCandidatesQuery;
